module.exports = [{
  "adcode": "110000",
  "name": "北京市",
  "districts": [{
    "adcode": "110100",
    "name": "北京市",
    "districts": [{
      "adcode": "110101",
      "name": "东城区"
    }, {
      "adcode": "110102",
      "name": "西城区"
    }, {
      "adcode": "110105",
      "name": "朝阳区"
    }, {
      "adcode": "110106",
      "name": "丰台区"
    }, {
      "adcode": "110107",
      "name": "石景山区"
    }, {
      "adcode": "110108",
      "name": "海淀区"
    }, {
      "adcode": "110109",
      "name": "门头沟区"
    }, {
      "adcode": "110111",
      "name": "房山区"
    }, {
      "adcode": "110112",
      "name": "通州区"
    }, {
      "adcode": "110113",
      "name": "顺义区"
    }, {
      "adcode": "110114",
      "name": "昌平区"
    }, {
      "adcode": "110115",
      "name": "大兴区"
    }, {
      "adcode": "110116",
      "name": "怀柔区"
    }, {
      "adcode": "110117",
      "name": "平谷区"
    }, {
      "adcode": "110118",
      "name": "密云区"
    }, {
      "adcode": "110119",
      "name": "延庆区"
    }]
  }]
}, {
  "adcode": "120000",
  "name": "天津市",
  "districts": [{
    "adcode": "120100",
    "name": "天津市",
    "districts": [{
      "adcode": "120101",
      "name": "和平区"
    }, {
      "adcode": "120102",
      "name": "河东区"
    }, {
      "adcode": "120103",
      "name": "河西区"
    }, {
      "adcode": "120104",
      "name": "南开区"
    }, {
      "adcode": "120105",
      "name": "河北区"
    }, {
      "adcode": "120106",
      "name": "红桥区"
    }, {
      "adcode": "120110",
      "name": "东丽区"
    }, {
      "adcode": "120111",
      "name": "西青区"
    }, {
      "adcode": "120112",
      "name": "津南区"
    }, {
      "adcode": "120113",
      "name": "北辰区"
    }, {
      "adcode": "120114",
      "name": "武清区"
    }, {
      "adcode": "120115",
      "name": "宝坻区"
    }, {
      "adcode": "120116",
      "name": "滨海新区"
    }, {
      "adcode": "120117",
      "name": "宁河区"
    }, {
      "adcode": "120118",
      "name": "静海区"
    }, {
      "adcode": "120119",
      "name": "蓟州区"
    }]
  }]
}, {
  "adcode": "130000",
  "name": "河北省",
  "districts": [{
    "adcode": "130100",
    "name": "石家庄市",
    "districts": [{
      "adcode": "130102",
      "name": "长安区"
    }, {
      "adcode": "130104",
      "name": "桥西区"
    }, {
      "adcode": "130105",
      "name": "新华区"
    }, {
      "adcode": "130107",
      "name": "井陉矿区"
    }, {
      "adcode": "130108",
      "name": "裕华区"
    }, {
      "adcode": "130109",
      "name": "藁城区"
    }, {
      "adcode": "130110",
      "name": "鹿泉区"
    }, {
      "adcode": "130111",
      "name": "栾城区"
    }, {
      "adcode": "130121",
      "name": "井陉县"
    }, {
      "adcode": "130123",
      "name": "正定县"
    }, {
      "adcode": "130125",
      "name": "行唐县"
    }, {
      "adcode": "130126",
      "name": "灵寿县"
    }, {
      "adcode": "130127",
      "name": "高邑县"
    }, {
      "adcode": "130128",
      "name": "深泽县"
    }, {
      "adcode": "130129",
      "name": "赞皇县"
    }, {
      "adcode": "130130",
      "name": "无极县"
    }, {
      "adcode": "130131",
      "name": "平山县"
    }, {
      "adcode": "130132",
      "name": "元氏县"
    }, {
      "adcode": "130133",
      "name": "赵县"
    }, {
      "adcode": "130181",
      "name": "辛集市"
    }, {
      "adcode": "130183",
      "name": "晋州市"
    }, {
      "adcode": "130184",
      "name": "新乐市"
    }]
  }, {
    "adcode": "130200",
    "name": "唐山市",
    "districts": [{
      "adcode": "130202",
      "name": "路南区"
    }, {
      "adcode": "130203",
      "name": "路北区"
    }, {
      "adcode": "130204",
      "name": "古冶区"
    }, {
      "adcode": "130205",
      "name": "开平区"
    }, {
      "adcode": "130207",
      "name": "丰南区"
    }, {
      "adcode": "130208",
      "name": "丰润区"
    }, {
      "adcode": "130209",
      "name": "曹妃甸区"
    }, {
      "adcode": "130224",
      "name": "滦南县"
    }, {
      "adcode": "130225",
      "name": "乐亭县"
    }, {
      "adcode": "130227",
      "name": "迁西县"
    }, {
      "adcode": "130229",
      "name": "玉田县"
    }, {
      "adcode": "130281",
      "name": "遵化市"
    }, {
      "adcode": "130283",
      "name": "迁安市"
    }, {
      "adcode": "130284",
      "name": "滦州市"
    }]
  }, {
    "adcode": "130300",
    "name": "秦皇岛市",
    "districts": [{
      "adcode": "130302",
      "name": "海港区"
    }, {
      "adcode": "130303",
      "name": "山海关区"
    }, {
      "adcode": "130304",
      "name": "北戴河区"
    }, {
      "adcode": "130306",
      "name": "抚宁区"
    }, {
      "adcode": "130321",
      "name": "青龙满族自治县"
    }, {
      "adcode": "130322",
      "name": "昌黎县"
    }, {
      "adcode": "130324",
      "name": "卢龙县"
    }]
  }, {
    "adcode": "130400",
    "name": "邯郸市",
    "districts": [{
      "adcode": "130402",
      "name": "邯山区"
    }, {
      "adcode": "130403",
      "name": "丛台区"
    }, {
      "adcode": "130404",
      "name": "复兴区"
    }, {
      "adcode": "130406",
      "name": "峰峰矿区"
    }, {
      "adcode": "130407",
      "name": "肥乡区"
    }, {
      "adcode": "130408",
      "name": "永年区"
    }, {
      "adcode": "130423",
      "name": "临漳县"
    }, {
      "adcode": "130424",
      "name": "成安县"
    }, {
      "adcode": "130425",
      "name": "大名县"
    }, {
      "adcode": "130426",
      "name": "涉县"
    }, {
      "adcode": "130427",
      "name": "磁县"
    }, {
      "adcode": "130430",
      "name": "邱县"
    }, {
      "adcode": "130431",
      "name": "鸡泽县"
    }, {
      "adcode": "130432",
      "name": "广平县"
    }, {
      "adcode": "130433",
      "name": "馆陶县"
    }, {
      "adcode": "130434",
      "name": "魏县"
    }, {
      "adcode": "130435",
      "name": "曲周县"
    }, {
      "adcode": "130481",
      "name": "武安市"
    }]
  }, {
    "adcode": "130500",
    "name": "邢台市",
    "districts": [{
      "adcode": "130502",
      "name": "桥东区"
    }, {
      "adcode": "130503",
      "name": "桥西区"
    }, {
      "adcode": "130521",
      "name": "邢台县"
    }, {
      "adcode": "130522",
      "name": "临城县"
    }, {
      "adcode": "130523",
      "name": "内丘县"
    }, {
      "adcode": "130524",
      "name": "柏乡县"
    }, {
      "adcode": "130525",
      "name": "隆尧县"
    }, {
      "adcode": "130526",
      "name": "任县"
    }, {
      "adcode": "130527",
      "name": "南和县"
    }, {
      "adcode": "130528",
      "name": "宁晋县"
    }, {
      "adcode": "130529",
      "name": "巨鹿县"
    }, {
      "adcode": "130530",
      "name": "新河县"
    }, {
      "adcode": "130531",
      "name": "广宗县"
    }, {
      "adcode": "130532",
      "name": "平乡县"
    }, {
      "adcode": "130533",
      "name": "威县"
    }, {
      "adcode": "130534",
      "name": "清河县"
    }, {
      "adcode": "130535",
      "name": "临西县"
    }, {
      "adcode": "130581",
      "name": "南宫市"
    }, {
      "adcode": "130582",
      "name": "沙河市"
    }]
  }, {
    "adcode": "130600",
    "name": "保定市",
    "districts": [{
      "adcode": "130602",
      "name": "竞秀区"
    }, {
      "adcode": "130606",
      "name": "莲池区"
    }, {
      "adcode": "130607",
      "name": "满城区"
    }, {
      "adcode": "130608",
      "name": "清苑区"
    }, {
      "adcode": "130609",
      "name": "徐水区"
    }, {
      "adcode": "130623",
      "name": "涞水县"
    }, {
      "adcode": "130624",
      "name": "阜平县"
    }, {
      "adcode": "130626",
      "name": "定兴县"
    }, {
      "adcode": "130627",
      "name": "唐县"
    }, {
      "adcode": "130628",
      "name": "高阳县"
    }, {
      "adcode": "130629",
      "name": "容城县"
    }, {
      "adcode": "130630",
      "name": "涞源县"
    }, {
      "adcode": "130631",
      "name": "望都县"
    }, {
      "adcode": "130632",
      "name": "安新县"
    }, {
      "adcode": "130633",
      "name": "易县"
    }, {
      "adcode": "130634",
      "name": "曲阳县"
    }, {
      "adcode": "130635",
      "name": "蠡县"
    }, {
      "adcode": "130636",
      "name": "顺平县"
    }, {
      "adcode": "130637",
      "name": "博野县"
    }, {
      "adcode": "130638",
      "name": "雄县"
    }, {
      "adcode": "130681",
      "name": "涿州市"
    }, {
      "adcode": "130682",
      "name": "定州市"
    }, {
      "adcode": "130683",
      "name": "安国市"
    }, {
      "adcode": "130684",
      "name": "高碑店市"
    }]
  }, {
    "adcode": "130700",
    "name": "张家口市",
    "districts": [{
      "adcode": "130702",
      "name": "桥东区"
    }, {
      "adcode": "130703",
      "name": "桥西区"
    }, {
      "adcode": "130705",
      "name": "宣化区"
    }, {
      "adcode": "130706",
      "name": "下花园区"
    }, {
      "adcode": "130708",
      "name": "万全区"
    }, {
      "adcode": "130709",
      "name": "崇礼区"
    }, {
      "adcode": "130722",
      "name": "张北县"
    }, {
      "adcode": "130723",
      "name": "康保县"
    }, {
      "adcode": "130724",
      "name": "沽源县"
    }, {
      "adcode": "130725",
      "name": "尚义县"
    }, {
      "adcode": "130726",
      "name": "蔚县"
    }, {
      "adcode": "130727",
      "name": "阳原县"
    }, {
      "adcode": "130728",
      "name": "怀安县"
    }, {
      "adcode": "130730",
      "name": "怀来县"
    }, {
      "adcode": "130731",
      "name": "涿鹿县"
    }, {
      "adcode": "130732",
      "name": "赤城县"
    }]
  }, {
    "adcode": "130800",
    "name": "承德市",
    "districts": [{
      "adcode": "130802",
      "name": "双桥区"
    }, {
      "adcode": "130803",
      "name": "双滦区"
    }, {
      "adcode": "130804",
      "name": "鹰手营子矿区"
    }, {
      "adcode": "130821",
      "name": "承德县"
    }, {
      "adcode": "130822",
      "name": "兴隆县"
    }, {
      "adcode": "130824",
      "name": "滦平县"
    }, {
      "adcode": "130825",
      "name": "隆化县"
    }, {
      "adcode": "130826",
      "name": "丰宁满族自治县"
    }, {
      "adcode": "130827",
      "name": "宽城满族自治县"
    }, {
      "adcode": "130828",
      "name": "围场满族蒙古族自治县"
    }, {
      "adcode": "130881",
      "name": "平泉市"
    }]
  }, {
    "adcode": "130900",
    "name": "沧州市",
    "districts": [{
      "adcode": "130902",
      "name": "新华区"
    }, {
      "adcode": "130903",
      "name": "运河区"
    }, {
      "adcode": "130921",
      "name": "沧县"
    }, {
      "adcode": "130922",
      "name": "青县"
    }, {
      "adcode": "130923",
      "name": "东光县"
    }, {
      "adcode": "130924",
      "name": "海兴县"
    }, {
      "adcode": "130925",
      "name": "盐山县"
    }, {
      "adcode": "130926",
      "name": "肃宁县"
    }, {
      "adcode": "130927",
      "name": "南皮县"
    }, {
      "adcode": "130928",
      "name": "吴桥县"
    }, {
      "adcode": "130929",
      "name": "献县"
    }, {
      "adcode": "130930",
      "name": "孟村回族自治县"
    }, {
      "adcode": "130981",
      "name": "泊头市"
    }, {
      "adcode": "130982",
      "name": "任丘市"
    }, {
      "adcode": "130983",
      "name": "黄骅市"
    }, {
      "adcode": "130984",
      "name": "河间市"
    }]
  }, {
    "adcode": "131000",
    "name": "廊坊市",
    "districts": [{
      "adcode": "131002",
      "name": "安次区"
    }, {
      "adcode": "131003",
      "name": "广阳区"
    }, {
      "adcode": "131022",
      "name": "固安县"
    }, {
      "adcode": "131023",
      "name": "永清县"
    }, {
      "adcode": "131024",
      "name": "香河县"
    }, {
      "adcode": "131025",
      "name": "大城县"
    }, {
      "adcode": "131026",
      "name": "文安县"
    }, {
      "adcode": "131028",
      "name": "大厂回族自治县"
    }, {
      "adcode": "131081",
      "name": "霸州市"
    }, {
      "adcode": "131082",
      "name": "三河市"
    }]
  }, {
    "adcode": "131100",
    "name": "衡水市",
    "districts": [{
      "adcode": "131102",
      "name": "桃城区"
    }, {
      "adcode": "131103",
      "name": "冀州区"
    }, {
      "adcode": "131121",
      "name": "枣强县"
    }, {
      "adcode": "131122",
      "name": "武邑县"
    }, {
      "adcode": "131123",
      "name": "武强县"
    }, {
      "adcode": "131124",
      "name": "饶阳县"
    }, {
      "adcode": "131125",
      "name": "安平县"
    }, {
      "adcode": "131126",
      "name": "故城县"
    }, {
      "adcode": "131127",
      "name": "景县"
    }, {
      "adcode": "131128",
      "name": "阜城县"
    }, {
      "adcode": "131182",
      "name": "深州市"
    }]
  }]
}, {
  "adcode": "140000",
  "name": "山西省",
  "districts": [{
    "adcode": "140100",
    "name": "太原市",
    "districts": [{
      "adcode": "140105",
      "name": "小店区"
    }, {
      "adcode": "140106",
      "name": "迎泽区"
    }, {
      "adcode": "140107",
      "name": "杏花岭区"
    }, {
      "adcode": "140108",
      "name": "尖草坪区"
    }, {
      "adcode": "140109",
      "name": "万柏林区"
    }, {
      "adcode": "140110",
      "name": "晋源区"
    }, {
      "adcode": "140121",
      "name": "清徐县"
    }, {
      "adcode": "140122",
      "name": "阳曲县"
    }, {
      "adcode": "140123",
      "name": "娄烦县"
    }, {
      "adcode": "140181",
      "name": "古交市"
    }]
  }, {
    "adcode": "140200",
    "name": "大同市",
    "districts": [{
      "adcode": "140212",
      "name": "新荣区"
    }, {
      "adcode": "140213",
      "name": "平城区"
    }, {
      "adcode": "140214",
      "name": "云冈区"
    }, {
      "adcode": "140215",
      "name": "云州区"
    }, {
      "adcode": "140221",
      "name": "阳高县"
    }, {
      "adcode": "140222",
      "name": "天镇县"
    }, {
      "adcode": "140223",
      "name": "广灵县"
    }, {
      "adcode": "140224",
      "name": "灵丘县"
    }, {
      "adcode": "140225",
      "name": "浑源县"
    }, {
      "adcode": "140226",
      "name": "左云县"
    }]
  }, {
    "adcode": "140300",
    "name": "阳泉市",
    "districts": [{
      "adcode": "140302",
      "name": "城区"
    }, {
      "adcode": "140303",
      "name": "矿区"
    }, {
      "adcode": "140311",
      "name": "郊区"
    }, {
      "adcode": "140321",
      "name": "平定县"
    }, {
      "adcode": "140322",
      "name": "盂县"
    }]
  }, {
    "adcode": "140400",
    "name": "长治市",
    "districts": [{
      "adcode": "140403",
      "name": "潞州区"
    }, {
      "adcode": "140404",
      "name": "上党区"
    }, {
      "adcode": "140405",
      "name": "屯留区"
    }, {
      "adcode": "140406",
      "name": "潞城区"
    }, {
      "adcode": "140423",
      "name": "襄垣县"
    }, {
      "adcode": "140425",
      "name": "平顺县"
    }, {
      "adcode": "140426",
      "name": "黎城县"
    }, {
      "adcode": "140427",
      "name": "壶关县"
    }, {
      "adcode": "140428",
      "name": "长子县"
    }, {
      "adcode": "140429",
      "name": "武乡县"
    }, {
      "adcode": "140430",
      "name": "沁县"
    }, {
      "adcode": "140431",
      "name": "沁源县"
    }]
  }, {
    "adcode": "140500",
    "name": "晋城市",
    "districts": [{
      "adcode": "140502",
      "name": "城区"
    }, {
      "adcode": "140521",
      "name": "沁水县"
    }, {
      "adcode": "140522",
      "name": "阳城县"
    }, {
      "adcode": "140524",
      "name": "陵川县"
    }, {
      "adcode": "140525",
      "name": "泽州县"
    }, {
      "adcode": "140581",
      "name": "高平市"
    }]
  }, {
    "adcode": "140600",
    "name": "朔州市",
    "districts": [{
      "adcode": "140602",
      "name": "朔城区"
    }, {
      "adcode": "140603",
      "name": "平鲁区"
    }, {
      "adcode": "140621",
      "name": "山阴县"
    }, {
      "adcode": "140622",
      "name": "应县"
    }, {
      "adcode": "140623",
      "name": "右玉县"
    }, {
      "adcode": "140681",
      "name": "怀仁市"
    }]
  }, {
    "adcode": "140700",
    "name": "晋中市",
    "districts": [{
      "adcode": "140702",
      "name": "榆次区"
    }, {
      "adcode": "140703",
      "name": "太谷区"
    }, {
      "adcode": "140721",
      "name": "榆社县"
    }, {
      "adcode": "140722",
      "name": "左权县"
    }, {
      "adcode": "140723",
      "name": "和顺县"
    }, {
      "adcode": "140724",
      "name": "昔阳县"
    }, {
      "adcode": "140725",
      "name": "寿阳县"
    }, {
      "adcode": "140727",
      "name": "祁县"
    }, {
      "adcode": "140728",
      "name": "平遥县"
    }, {
      "adcode": "140729",
      "name": "灵石县"
    }, {
      "adcode": "140781",
      "name": "介休市"
    }]
  }, {
    "adcode": "140800",
    "name": "运城市",
    "districts": [{
      "adcode": "140802",
      "name": "盐湖区"
    }, {
      "adcode": "140821",
      "name": "临猗县"
    }, {
      "adcode": "140822",
      "name": "万荣县"
    }, {
      "adcode": "140823",
      "name": "闻喜县"
    }, {
      "adcode": "140824",
      "name": "稷山县"
    }, {
      "adcode": "140825",
      "name": "新绛县"
    }, {
      "adcode": "140826",
      "name": "绛县"
    }, {
      "adcode": "140827",
      "name": "垣曲县"
    }, {
      "adcode": "140828",
      "name": "夏县"
    }, {
      "adcode": "140829",
      "name": "平陆县"
    }, {
      "adcode": "140830",
      "name": "芮城县"
    }, {
      "adcode": "140881",
      "name": "永济市"
    }, {
      "adcode": "140882",
      "name": "河津市"
    }]
  }, {
    "adcode": "140900",
    "name": "忻州市",
    "districts": [{
      "adcode": "140902",
      "name": "忻府区"
    }, {
      "adcode": "140921",
      "name": "定襄县"
    }, {
      "adcode": "140922",
      "name": "五台县"
    }, {
      "adcode": "140923",
      "name": "代县"
    }, {
      "adcode": "140924",
      "name": "繁峙县"
    }, {
      "adcode": "140925",
      "name": "宁武县"
    }, {
      "adcode": "140926",
      "name": "静乐县"
    }, {
      "adcode": "140927",
      "name": "神池县"
    }, {
      "adcode": "140928",
      "name": "五寨县"
    }, {
      "adcode": "140929",
      "name": "岢岚县"
    }, {
      "adcode": "140930",
      "name": "河曲县"
    }, {
      "adcode": "140931",
      "name": "保德县"
    }, {
      "adcode": "140932",
      "name": "偏关县"
    }, {
      "adcode": "140981",
      "name": "原平市"
    }]
  }, {
    "adcode": "141000",
    "name": "临汾市",
    "districts": [{
      "adcode": "141002",
      "name": "尧都区"
    }, {
      "adcode": "141021",
      "name": "曲沃县"
    }, {
      "adcode": "141022",
      "name": "翼城县"
    }, {
      "adcode": "141023",
      "name": "襄汾县"
    }, {
      "adcode": "141024",
      "name": "洪洞县"
    }, {
      "adcode": "141025",
      "name": "古县"
    }, {
      "adcode": "141026",
      "name": "安泽县"
    }, {
      "adcode": "141027",
      "name": "浮山县"
    }, {
      "adcode": "141028",
      "name": "吉县"
    }, {
      "adcode": "141029",
      "name": "乡宁县"
    }, {
      "adcode": "141030",
      "name": "大宁县"
    }, {
      "adcode": "141031",
      "name": "隰县"
    }, {
      "adcode": "141032",
      "name": "永和县"
    }, {
      "adcode": "141033",
      "name": "蒲县"
    }, {
      "adcode": "141034",
      "name": "汾西县"
    }, {
      "adcode": "141081",
      "name": "侯马市"
    }, {
      "adcode": "141082",
      "name": "霍州市"
    }]
  }, {
    "adcode": "141100",
    "name": "吕梁市",
    "districts": [{
      "adcode": "141102",
      "name": "离石区"
    }, {
      "adcode": "141121",
      "name": "文水县"
    }, {
      "adcode": "141122",
      "name": "交城县"
    }, {
      "adcode": "141123",
      "name": "兴县"
    }, {
      "adcode": "141124",
      "name": "临县"
    }, {
      "adcode": "141125",
      "name": "柳林县"
    }, {
      "adcode": "141126",
      "name": "石楼县"
    }, {
      "adcode": "141127",
      "name": "岚县"
    }, {
      "adcode": "141128",
      "name": "方山县"
    }, {
      "adcode": "141129",
      "name": "中阳县"
    }, {
      "adcode": "141130",
      "name": "交口县"
    }, {
      "adcode": "141181",
      "name": "孝义市"
    }, {
      "adcode": "141182",
      "name": "汾阳市"
    }]
  }]
}, {
  "adcode": "150000",
  "name": "内蒙古自治区",
  "districts": [{
    "adcode": "150100",
    "name": "呼和浩特市",
    "districts": [{
      "adcode": "150102",
      "name": "新城区"
    }, {
      "adcode": "150103",
      "name": "回民区"
    }, {
      "adcode": "150104",
      "name": "玉泉区"
    }, {
      "adcode": "150105",
      "name": "赛罕区"
    }, {
      "adcode": "150121",
      "name": "土默特左旗"
    }, {
      "adcode": "150122",
      "name": "托克托县"
    }, {
      "adcode": "150123",
      "name": "和林格尔县"
    }, {
      "adcode": "150124",
      "name": "清水河县"
    }, {
      "adcode": "150125",
      "name": "武川县"
    }]
  }, {
    "adcode": "150200",
    "name": "包头市",
    "districts": [{
      "adcode": "150202",
      "name": "东河区"
    }, {
      "adcode": "150203",
      "name": "昆都仑区"
    }, {
      "adcode": "150204",
      "name": "青山区"
    }, {
      "adcode": "150205",
      "name": "石拐区"
    }, {
      "adcode": "150206",
      "name": "白云鄂博矿区"
    }, {
      "adcode": "150207",
      "name": "九原区"
    }, {
      "adcode": "150221",
      "name": "土默特右旗"
    }, {
      "adcode": "150222",
      "name": "固阳县"
    }, {
      "adcode": "150223",
      "name": "达尔罕茂明安联合旗"
    }]
  }, {
    "adcode": "150300",
    "name": "乌海市",
    "districts": [{
      "adcode": "150302",
      "name": "海勃湾区"
    }, {
      "adcode": "150303",
      "name": "海南区"
    }, {
      "adcode": "150304",
      "name": "乌达区"
    }]
  }, {
    "adcode": "150400",
    "name": "赤峰市",
    "districts": [{
      "adcode": "150402",
      "name": "红山区"
    }, {
      "adcode": "150403",
      "name": "元宝山区"
    }, {
      "adcode": "150404",
      "name": "松山区"
    }, {
      "adcode": "150421",
      "name": "阿鲁科尔沁旗"
    }, {
      "adcode": "150422",
      "name": "巴林左旗"
    }, {
      "adcode": "150423",
      "name": "巴林右旗"
    }, {
      "adcode": "150424",
      "name": "林西县"
    }, {
      "adcode": "150425",
      "name": "克什克腾旗"
    }, {
      "adcode": "150426",
      "name": "翁牛特旗"
    }, {
      "adcode": "150428",
      "name": "喀喇沁旗"
    }, {
      "adcode": "150429",
      "name": "宁城县"
    }, {
      "adcode": "150430",
      "name": "敖汉旗"
    }]
  }, {
    "adcode": "150500",
    "name": "通辽市",
    "districts": [{
      "adcode": "150502",
      "name": "科尔沁区"
    }, {
      "adcode": "150521",
      "name": "科尔沁左翼中旗"
    }, {
      "adcode": "150522",
      "name": "科尔沁左翼后旗"
    }, {
      "adcode": "150523",
      "name": "开鲁县"
    }, {
      "adcode": "150524",
      "name": "库伦旗"
    }, {
      "adcode": "150525",
      "name": "奈曼旗"
    }, {
      "adcode": "150526",
      "name": "扎鲁特旗"
    }, {
      "adcode": "150581",
      "name": "霍林郭勒市"
    }]
  }, {
    "adcode": "150600",
    "name": "鄂尔多斯市",
    "districts": [{
      "adcode": "150602",
      "name": "东胜区"
    }, {
      "adcode": "150603",
      "name": "康巴什区"
    }, {
      "adcode": "150621",
      "name": "达拉特旗"
    }, {
      "adcode": "150622",
      "name": "准格尔旗"
    }, {
      "adcode": "150623",
      "name": "鄂托克前旗"
    }, {
      "adcode": "150624",
      "name": "鄂托克旗"
    }, {
      "adcode": "150625",
      "name": "杭锦旗"
    }, {
      "adcode": "150626",
      "name": "乌审旗"
    }, {
      "adcode": "150627",
      "name": "伊金霍洛旗"
    }]
  }, {
    "adcode": "150700",
    "name": "呼伦贝尔市",
    "districts": [{
      "adcode": "150702",
      "name": "海拉尔区"
    }, {
      "adcode": "150703",
      "name": "扎赉诺尔区"
    }, {
      "adcode": "150721",
      "name": "阿荣旗"
    }, {
      "adcode": "150722",
      "name": "莫力达瓦达斡尔族自治旗"
    }, {
      "adcode": "150723",
      "name": "鄂伦春自治旗"
    }, {
      "adcode": "150724",
      "name": "鄂温克族自治旗"
    }, {
      "adcode": "150725",
      "name": "陈巴尔虎旗"
    }, {
      "adcode": "150726",
      "name": "新巴尔虎左旗"
    }, {
      "adcode": "150727",
      "name": "新巴尔虎右旗"
    }, {
      "adcode": "150781",
      "name": "满洲里市"
    }, {
      "adcode": "150782",
      "name": "牙克石市"
    }, {
      "adcode": "150783",
      "name": "扎兰屯市"
    }, {
      "adcode": "150784",
      "name": "额尔古纳市"
    }, {
      "adcode": "150785",
      "name": "根河市"
    }]
  }, {
    "adcode": "150800",
    "name": "巴彦淖尔市",
    "districts": [{
      "adcode": "150802",
      "name": "临河区"
    }, {
      "adcode": "150821",
      "name": "五原县"
    }, {
      "adcode": "150822",
      "name": "磴口县"
    }, {
      "adcode": "150823",
      "name": "乌拉特前旗"
    }, {
      "adcode": "150824",
      "name": "乌拉特中旗"
    }, {
      "adcode": "150825",
      "name": "乌拉特后旗"
    }, {
      "adcode": "150826",
      "name": "杭锦后旗"
    }]
  }, {
    "adcode": "150900",
    "name": "乌兰察布市",
    "districts": [{
      "adcode": "150902",
      "name": "集宁区"
    }, {
      "adcode": "150921",
      "name": "卓资县"
    }, {
      "adcode": "150922",
      "name": "化德县"
    }, {
      "adcode": "150923",
      "name": "商都县"
    }, {
      "adcode": "150924",
      "name": "兴和县"
    }, {
      "adcode": "150925",
      "name": "凉城县"
    }, {
      "adcode": "150926",
      "name": "察哈尔右翼前旗"
    }, {
      "adcode": "150927",
      "name": "察哈尔右翼中旗"
    }, {
      "adcode": "150928",
      "name": "察哈尔右翼后旗"
    }, {
      "adcode": "150929",
      "name": "四子王旗"
    }, {
      "adcode": "150981",
      "name": "丰镇市"
    }]
  }, {
    "adcode": "152200",
    "name": "兴安盟",
    "districts": [{
      "adcode": "152201",
      "name": "乌兰浩特市"
    }, {
      "adcode": "152202",
      "name": "阿尔山市"
    }, {
      "adcode": "152221",
      "name": "科尔沁右翼前旗"
    }, {
      "adcode": "152222",
      "name": "科尔沁右翼中旗"
    }, {
      "adcode": "152223",
      "name": "扎赉特旗"
    }, {
      "adcode": "152224",
      "name": "突泉县"
    }]
  }, {
    "adcode": "152500",
    "name": "锡林郭勒盟",
    "districts": [{
      "adcode": "152501",
      "name": "二连浩特市"
    }, {
      "adcode": "152502",
      "name": "锡林浩特市"
    }, {
      "adcode": "152522",
      "name": "阿巴嘎旗"
    }, {
      "adcode": "152523",
      "name": "苏尼特左旗"
    }, {
      "adcode": "152524",
      "name": "苏尼特右旗"
    }, {
      "adcode": "152525",
      "name": "东乌珠穆沁旗"
    }, {
      "adcode": "152526",
      "name": "西乌珠穆沁旗"
    }, {
      "adcode": "152527",
      "name": "太仆寺旗"
    }, {
      "adcode": "152528",
      "name": "镶黄旗"
    }, {
      "adcode": "152529",
      "name": "正镶白旗"
    }, {
      "adcode": "152530",
      "name": "正蓝旗"
    }, {
      "adcode": "152531",
      "name": "多伦县"
    }]
  }, {
    "adcode": "152900",
    "name": "阿拉善盟",
    "districts": [{
      "adcode": "152921",
      "name": "阿拉善左旗"
    }, {
      "adcode": "152922",
      "name": "阿拉善右旗"
    }, {
      "adcode": "152923",
      "name": "额济纳旗"
    }]
  }]
}, {
  "adcode": "210000",
  "name": "辽宁省",
  "districts": [{
    "adcode": "210100",
    "name": "沈阳市",
    "districts": [{
      "adcode": "210102",
      "name": "和平区"
    }, {
      "adcode": "210103",
      "name": "沈河区"
    }, {
      "adcode": "210104",
      "name": "大东区"
    }, {
      "adcode": "210105",
      "name": "皇姑区"
    }, {
      "adcode": "210106",
      "name": "铁西区"
    }, {
      "adcode": "210111",
      "name": "苏家屯区"
    }, {
      "adcode": "210112",
      "name": "浑南区"
    }, {
      "adcode": "210113",
      "name": "沈北新区"
    }, {
      "adcode": "210114",
      "name": "于洪区"
    }, {
      "adcode": "210115",
      "name": "辽中区"
    }, {
      "adcode": "210123",
      "name": "康平县"
    }, {
      "adcode": "210124",
      "name": "法库县"
    }, {
      "adcode": "210181",
      "name": "新民市"
    }]
  }, {
    "adcode": "210200",
    "name": "大连市",
    "districts": [{
      "adcode": "210202",
      "name": "中山区"
    }, {
      "adcode": "210203",
      "name": "西岗区"
    }, {
      "adcode": "210204",
      "name": "沙河口区"
    }, {
      "adcode": "210211",
      "name": "甘井子区"
    }, {
      "adcode": "210212",
      "name": "旅顺口区"
    }, {
      "adcode": "210213",
      "name": "金州区"
    }, {
      "adcode": "210214",
      "name": "普兰店区"
    }, {
      "adcode": "210224",
      "name": "长海县"
    }, {
      "adcode": "210281",
      "name": "瓦房店市"
    }, {
      "adcode": "210283",
      "name": "庄河市"
    }]
  }, {
    "adcode": "210300",
    "name": "鞍山市",
    "districts": [{
      "adcode": "210302",
      "name": "铁东区"
    }, {
      "adcode": "210303",
      "name": "铁西区"
    }, {
      "adcode": "210304",
      "name": "立山区"
    }, {
      "adcode": "210311",
      "name": "千山区"
    }, {
      "adcode": "210321",
      "name": "台安县"
    }, {
      "adcode": "210323",
      "name": "岫岩满族自治县"
    }, {
      "adcode": "210381",
      "name": "海城市"
    }]
  }, {
    "adcode": "210400",
    "name": "抚顺市",
    "districts": [{
      "adcode": "210402",
      "name": "新抚区"
    }, {
      "adcode": "210403",
      "name": "东洲区"
    }, {
      "adcode": "210404",
      "name": "望花区"
    }, {
      "adcode": "210411",
      "name": "顺城区"
    }, {
      "adcode": "210421",
      "name": "抚顺县"
    }, {
      "adcode": "210422",
      "name": "新宾满族自治县"
    }, {
      "adcode": "210423",
      "name": "清原满族自治县"
    }]
  }, {
    "adcode": "210500",
    "name": "本溪市",
    "districts": [{
      "adcode": "210502",
      "name": "平山区"
    }, {
      "adcode": "210503",
      "name": "溪湖区"
    }, {
      "adcode": "210504",
      "name": "明山区"
    }, {
      "adcode": "210505",
      "name": "南芬区"
    }, {
      "adcode": "210521",
      "name": "本溪满族自治县"
    }, {
      "adcode": "210522",
      "name": "桓仁满族自治县"
    }]
  }, {
    "adcode": "210600",
    "name": "丹东市",
    "districts": [{
      "adcode": "210602",
      "name": "元宝区"
    }, {
      "adcode": "210603",
      "name": "振兴区"
    }, {
      "adcode": "210604",
      "name": "振安区"
    }, {
      "adcode": "210624",
      "name": "宽甸满族自治县"
    }, {
      "adcode": "210681",
      "name": "东港市"
    }, {
      "adcode": "210682",
      "name": "凤城市"
    }]
  }, {
    "adcode": "210700",
    "name": "锦州市",
    "districts": [{
      "adcode": "210702",
      "name": "古塔区"
    }, {
      "adcode": "210703",
      "name": "凌河区"
    }, {
      "adcode": "210711",
      "name": "太和区"
    }, {
      "adcode": "210726",
      "name": "黑山县"
    }, {
      "adcode": "210727",
      "name": "义县"
    }, {
      "adcode": "210781",
      "name": "凌海市"
    }, {
      "adcode": "210782",
      "name": "北镇市"
    }]
  }, {
    "adcode": "210800",
    "name": "营口市",
    "districts": [{
      "adcode": "210802",
      "name": "站前区"
    }, {
      "adcode": "210803",
      "name": "西市区"
    }, {
      "adcode": "210804",
      "name": "鲅鱼圈区"
    }, {
      "adcode": "210811",
      "name": "老边区"
    }, {
      "adcode": "210881",
      "name": "盖州市"
    }, {
      "adcode": "210882",
      "name": "大石桥市"
    }]
  }, {
    "adcode": "210900",
    "name": "阜新市",
    "districts": [{
      "adcode": "210902",
      "name": "海州区"
    }, {
      "adcode": "210903",
      "name": "新邱区"
    }, {
      "adcode": "210904",
      "name": "太平区"
    }, {
      "adcode": "210905",
      "name": "清河门区"
    }, {
      "adcode": "210911",
      "name": "细河区"
    }, {
      "adcode": "210921",
      "name": "阜新蒙古族自治县"
    }, {
      "adcode": "210922",
      "name": "彰武县"
    }]
  }, {
    "adcode": "211000",
    "name": "辽阳市",
    "districts": [{
      "adcode": "211002",
      "name": "白塔区"
    }, {
      "adcode": "211003",
      "name": "文圣区"
    }, {
      "adcode": "211004",
      "name": "宏伟区"
    }, {
      "adcode": "211005",
      "name": "弓长岭区"
    }, {
      "adcode": "211011",
      "name": "太子河区"
    }, {
      "adcode": "211021",
      "name": "辽阳县"
    }, {
      "adcode": "211081",
      "name": "灯塔市"
    }]
  }, {
    "adcode": "211100",
    "name": "盘锦市",
    "districts": [{
      "adcode": "211102",
      "name": "双台子区"
    }, {
      "adcode": "211103",
      "name": "兴隆台区"
    }, {
      "adcode": "211104",
      "name": "大洼区"
    }, {
      "adcode": "211122",
      "name": "盘山县"
    }]
  }, {
    "adcode": "211200",
    "name": "铁岭市",
    "districts": [{
      "adcode": "211202",
      "name": "银州区"
    }, {
      "adcode": "211204",
      "name": "清河区"
    }, {
      "adcode": "211221",
      "name": "铁岭县"
    }, {
      "adcode": "211223",
      "name": "西丰县"
    }, {
      "adcode": "211224",
      "name": "昌图县"
    }, {
      "adcode": "211281",
      "name": "调兵山市"
    }, {
      "adcode": "211282",
      "name": "开原市"
    }]
  }, {
    "adcode": "211300",
    "name": "朝阳市",
    "districts": [{
      "adcode": "211302",
      "name": "双塔区"
    }, {
      "adcode": "211303",
      "name": "龙城区"
    }, {
      "adcode": "211321",
      "name": "朝阳县"
    }, {
      "adcode": "211322",
      "name": "建平县"
    }, {
      "adcode": "211324",
      "name": "喀喇沁左翼蒙古族自治县"
    }, {
      "adcode": "211381",
      "name": "北票市"
    }, {
      "adcode": "211382",
      "name": "凌源市"
    }]
  }, {
    "adcode": "211400",
    "name": "葫芦岛市",
    "districts": [{
      "adcode": "211402",
      "name": "连山区"
    }, {
      "adcode": "211403",
      "name": "龙港区"
    }, {
      "adcode": "211404",
      "name": "南票区"
    }, {
      "adcode": "211421",
      "name": "绥中县"
    }, {
      "adcode": "211422",
      "name": "建昌县"
    }, {
      "adcode": "211481",
      "name": "兴城市"
    }]
  }]
}, {
  "adcode": "220000",
  "name": "吉林省",
  "districts": [{
    "adcode": "220100",
    "name": "长春市",
    "districts": [{
      "adcode": "220102",
      "name": "南关区"
    }, {
      "adcode": "220103",
      "name": "宽城区"
    }, {
      "adcode": "220104",
      "name": "朝阳区"
    }, {
      "adcode": "220105",
      "name": "二道区"
    }, {
      "adcode": "220106",
      "name": "绿园区"
    }, {
      "adcode": "220112",
      "name": "双阳区"
    }, {
      "adcode": "220113",
      "name": "九台区"
    }, {
      "adcode": "220122",
      "name": "农安县"
    }, {
      "adcode": "220182",
      "name": "榆树市"
    }, {
      "adcode": "220183",
      "name": "德惠市"
    }]
  }, {
    "adcode": "220200",
    "name": "吉林市",
    "districts": [{
      "adcode": "220202",
      "name": "昌邑区"
    }, {
      "adcode": "220203",
      "name": "龙潭区"
    }, {
      "adcode": "220204",
      "name": "船营区"
    }, {
      "adcode": "220211",
      "name": "丰满区"
    }, {
      "adcode": "220221",
      "name": "永吉县"
    }, {
      "adcode": "220281",
      "name": "蛟河市"
    }, {
      "adcode": "220282",
      "name": "桦甸市"
    }, {
      "adcode": "220283",
      "name": "舒兰市"
    }, {
      "adcode": "220284",
      "name": "磐石市"
    }]
  }, {
    "adcode": "220300",
    "name": "四平市",
    "districts": [{
      "adcode": "220302",
      "name": "铁西区"
    }, {
      "adcode": "220303",
      "name": "铁东区"
    }, {
      "adcode": "220322",
      "name": "梨树县"
    }, {
      "adcode": "220323",
      "name": "伊通满族自治县"
    }, {
      "adcode": "220381",
      "name": "公主岭市"
    }, {
      "adcode": "220382",
      "name": "双辽市"
    }]
  }, {
    "adcode": "220400",
    "name": "辽源市",
    "districts": [{
      "adcode": "220402",
      "name": "龙山区"
    }, {
      "adcode": "220403",
      "name": "西安区"
    }, {
      "adcode": "220421",
      "name": "东丰县"
    }, {
      "adcode": "220422",
      "name": "东辽县"
    }]
  }, {
    "adcode": "220500",
    "name": "通化市",
    "districts": [{
      "adcode": "220502",
      "name": "东昌区"
    }, {
      "adcode": "220503",
      "name": "二道江区"
    }, {
      "adcode": "220521",
      "name": "通化县"
    }, {
      "adcode": "220523",
      "name": "辉南县"
    }, {
      "adcode": "220524",
      "name": "柳河县"
    }, {
      "adcode": "220581",
      "name": "梅河口市"
    }, {
      "adcode": "220582",
      "name": "集安市"
    }]
  }, {
    "adcode": "220600",
    "name": "白山市",
    "districts": [{
      "adcode": "220602",
      "name": "浑江区"
    }, {
      "adcode": "220605",
      "name": "江源区"
    }, {
      "adcode": "220621",
      "name": "抚松县"
    }, {
      "adcode": "220622",
      "name": "靖宇县"
    }, {
      "adcode": "220623",
      "name": "长白朝鲜族自治县"
    }, {
      "adcode": "220681",
      "name": "临江市"
    }]
  }, {
    "adcode": "220700",
    "name": "松原市",
    "districts": [{
      "adcode": "220702",
      "name": "宁江区"
    }, {
      "adcode": "220721",
      "name": "前郭尔罗斯蒙古族自治县"
    }, {
      "adcode": "220722",
      "name": "长岭县"
    }, {
      "adcode": "220723",
      "name": "乾安县"
    }, {
      "adcode": "220781",
      "name": "扶余市"
    }]
  }, {
    "adcode": "220800",
    "name": "白城市",
    "districts": [{
      "adcode": "220802",
      "name": "洮北区"
    }, {
      "adcode": "220821",
      "name": "镇赉县"
    }, {
      "adcode": "220822",
      "name": "通榆县"
    }, {
      "adcode": "220881",
      "name": "洮南市"
    }, {
      "adcode": "220882",
      "name": "大安市"
    }]
  }, {
    "adcode": "222400",
    "name": "延边朝鲜族自治州",
    "districts": [{
      "adcode": "222401",
      "name": "延吉市"
    }, {
      "adcode": "222402",
      "name": "图们市"
    }, {
      "adcode": "222403",
      "name": "敦化市"
    }, {
      "adcode": "222404",
      "name": "珲春市"
    }, {
      "adcode": "222405",
      "name": "龙井市"
    }, {
      "adcode": "222406",
      "name": "和龙市"
    }, {
      "adcode": "222424",
      "name": "汪清县"
    }, {
      "adcode": "222426",
      "name": "安图县"
    }]
  }]
}, {
  "adcode": "230000",
  "name": "黑龙江省",
  "districts": [{
    "adcode": "230100",
    "name": "哈尔滨市",
    "districts": [{
      "adcode": "230102",
      "name": "道里区"
    }, {
      "adcode": "230103",
      "name": "南岗区"
    }, {
      "adcode": "230104",
      "name": "道外区"
    }, {
      "adcode": "230108",
      "name": "平房区"
    }, {
      "adcode": "230109",
      "name": "松北区"
    }, {
      "adcode": "230110",
      "name": "香坊区"
    }, {
      "adcode": "230111",
      "name": "呼兰区"
    }, {
      "adcode": "230112",
      "name": "阿城区"
    }, {
      "adcode": "230113",
      "name": "双城区"
    }, {
      "adcode": "230123",
      "name": "依兰县"
    }, {
      "adcode": "230124",
      "name": "方正县"
    }, {
      "adcode": "230125",
      "name": "宾县"
    }, {
      "adcode": "230126",
      "name": "巴彦县"
    }, {
      "adcode": "230127",
      "name": "木兰县"
    }, {
      "adcode": "230128",
      "name": "通河县"
    }, {
      "adcode": "230129",
      "name": "延寿县"
    }, {
      "adcode": "230183",
      "name": "尚志市"
    }, {
      "adcode": "230184",
      "name": "五常市"
    }]
  }, {
    "adcode": "230200",
    "name": "齐齐哈尔市",
    "districts": [{
      "adcode": "230202",
      "name": "龙沙区"
    }, {
      "adcode": "230203",
      "name": "建华区"
    }, {
      "adcode": "230204",
      "name": "铁锋区"
    }, {
      "adcode": "230205",
      "name": "昂昂溪区"
    }, {
      "adcode": "230206",
      "name": "富拉尔基区"
    }, {
      "adcode": "230207",
      "name": "碾子山区"
    }, {
      "adcode": "230208",
      "name": "梅里斯达斡尔族区"
    }, {
      "adcode": "230221",
      "name": "龙江县"
    }, {
      "adcode": "230223",
      "name": "依安县"
    }, {
      "adcode": "230224",
      "name": "泰来县"
    }, {
      "adcode": "230225",
      "name": "甘南县"
    }, {
      "adcode": "230227",
      "name": "富裕县"
    }, {
      "adcode": "230229",
      "name": "克山县"
    }, {
      "adcode": "230230",
      "name": "克东县"
    }, {
      "adcode": "230231",
      "name": "拜泉县"
    }, {
      "adcode": "230281",
      "name": "讷河市"
    }]
  }, {
    "adcode": "230300",
    "name": "鸡西市",
    "districts": [{
      "adcode": "230302",
      "name": "鸡冠区"
    }, {
      "adcode": "230303",
      "name": "恒山区"
    }, {
      "adcode": "230304",
      "name": "滴道区"
    }, {
      "adcode": "230305",
      "name": "梨树区"
    }, {
      "adcode": "230306",
      "name": "城子河区"
    }, {
      "adcode": "230307",
      "name": "麻山区"
    }, {
      "adcode": "230321",
      "name": "鸡东县"
    }, {
      "adcode": "230381",
      "name": "虎林市"
    }, {
      "adcode": "230382",
      "name": "密山市"
    }]
  }, {
    "adcode": "230400",
    "name": "鹤岗市",
    "districts": [{
      "adcode": "230402",
      "name": "向阳区"
    }, {
      "adcode": "230403",
      "name": "工农区"
    }, {
      "adcode": "230404",
      "name": "南山区"
    }, {
      "adcode": "230405",
      "name": "兴安区"
    }, {
      "adcode": "230406",
      "name": "东山区"
    }, {
      "adcode": "230407",
      "name": "兴山区"
    }, {
      "adcode": "230421",
      "name": "萝北县"
    }, {
      "adcode": "230422",
      "name": "绥滨县"
    }]
  }, {
    "adcode": "230500",
    "name": "双鸭山市",
    "districts": [{
      "adcode": "230502",
      "name": "尖山区"
    }, {
      "adcode": "230503",
      "name": "岭东区"
    }, {
      "adcode": "230505",
      "name": "四方台区"
    }, {
      "adcode": "230506",
      "name": "宝山区"
    }, {
      "adcode": "230521",
      "name": "集贤县"
    }, {
      "adcode": "230522",
      "name": "友谊县"
    }, {
      "adcode": "230523",
      "name": "宝清县"
    }, {
      "adcode": "230524",
      "name": "饶河县"
    }]
  }, {
    "adcode": "230600",
    "name": "大庆市",
    "districts": [{
      "adcode": "230602",
      "name": "萨尔图区"
    }, {
      "adcode": "230603",
      "name": "龙凤区"
    }, {
      "adcode": "230604",
      "name": "让胡路区"
    }, {
      "adcode": "230605",
      "name": "红岗区"
    }, {
      "adcode": "230606",
      "name": "大同区"
    }, {
      "adcode": "230621",
      "name": "肇州县"
    }, {
      "adcode": "230622",
      "name": "肇源县"
    }, {
      "adcode": "230623",
      "name": "林甸县"
    }, {
      "adcode": "230624",
      "name": "杜尔伯特蒙古族自治县"
    }]
  }, {
    "adcode": "230700",
    "name": "伊春市",
    "districts": [{
      "adcode": "230717",
      "name": "伊美区"
    }, {
      "adcode": "230718",
      "name": "乌翠区"
    }, {
      "adcode": "230719",
      "name": "友好区"
    }, {
      "adcode": "230722",
      "name": "嘉荫县"
    }, {
      "adcode": "230723",
      "name": "汤旺县"
    }, {
      "adcode": "230724",
      "name": "丰林县"
    }, {
      "adcode": "230725",
      "name": "大箐山县"
    }, {
      "adcode": "230726",
      "name": "南岔县"
    }, {
      "adcode": "230751",
      "name": "金林区"
    }, {
      "adcode": "230781",
      "name": "铁力市"
    }]
  }, {
    "adcode": "230800",
    "name": "佳木斯市",
    "districts": [{
      "adcode": "230803",
      "name": "向阳区"
    }, {
      "adcode": "230804",
      "name": "前进区"
    }, {
      "adcode": "230805",
      "name": "东风区"
    }, {
      "adcode": "230811",
      "name": "郊区"
    }, {
      "adcode": "230822",
      "name": "桦南县"
    }, {
      "adcode": "230826",
      "name": "桦川县"
    }, {
      "adcode": "230828",
      "name": "汤原县"
    }, {
      "adcode": "230881",
      "name": "同江市"
    }, {
      "adcode": "230882",
      "name": "富锦市"
    }, {
      "adcode": "230883",
      "name": "抚远市"
    }]
  }, {
    "adcode": "230900",
    "name": "七台河市",
    "districts": [{
      "adcode": "230902",
      "name": "新兴区"
    }, {
      "adcode": "230903",
      "name": "桃山区"
    }, {
      "adcode": "230904",
      "name": "茄子河区"
    }, {
      "adcode": "230921",
      "name": "勃利县"
    }]
  }, {
    "adcode": "231000",
    "name": "牡丹江市",
    "districts": [{
      "adcode": "231002",
      "name": "东安区"
    }, {
      "adcode": "231003",
      "name": "阳明区"
    }, {
      "adcode": "231004",
      "name": "爱民区"
    }, {
      "adcode": "231005",
      "name": "西安区"
    }, {
      "adcode": "231025",
      "name": "林口县"
    }, {
      "adcode": "231081",
      "name": "绥芬河市"
    }, {
      "adcode": "231083",
      "name": "海林市"
    }, {
      "adcode": "231084",
      "name": "宁安市"
    }, {
      "adcode": "231085",
      "name": "穆棱市"
    }, {
      "adcode": "231086",
      "name": "东宁市"
    }]
  }, {
    "adcode": "231100",
    "name": "黑河市",
    "districts": [{
      "adcode": "231102",
      "name": "爱辉区"
    }, {
      "adcode": "231123",
      "name": "逊克县"
    }, {
      "adcode": "231124",
      "name": "孙吴县"
    }, {
      "adcode": "231181",
      "name": "北安市"
    }, {
      "adcode": "231182",
      "name": "五大连池市"
    }, {
      "adcode": "231183",
      "name": "嫩江市"
    }]
  }, {
    "adcode": "231200",
    "name": "绥化市",
    "districts": [{
      "adcode": "231202",
      "name": "北林区"
    }, {
      "adcode": "231221",
      "name": "望奎县"
    }, {
      "adcode": "231222",
      "name": "兰西县"
    }, {
      "adcode": "231223",
      "name": "青冈县"
    }, {
      "adcode": "231224",
      "name": "庆安县"
    }, {
      "adcode": "231225",
      "name": "明水县"
    }, {
      "adcode": "231226",
      "name": "绥棱县"
    }, {
      "adcode": "231281",
      "name": "安达市"
    }, {
      "adcode": "231282",
      "name": "肇东市"
    }, {
      "adcode": "231283",
      "name": "海伦市"
    }]
  }, {
    "adcode": "232700",
    "name": "大兴安岭地区",
    "districts": [{
      "adcode": "232701",
      "name": "漠河市"
    }, {
      "adcode": "232721",
      "name": "呼玛县"
    }, {
      "adcode": "232722",
      "name": "塔河县"
    }]
  }]
}, {
  "adcode": "310000",
  "name": "上海市",
  "districts": [{
    "adcode": "310100",
    "name": "上海市",
    "districts": [{
      "adcode": "310101",
      "name": "黄浦区"
    }, {
      "adcode": "310104",
      "name": "徐汇区"
    }, {
      "adcode": "310105",
      "name": "长宁区"
    }, {
      "adcode": "310106",
      "name": "静安区"
    }, {
      "adcode": "310107",
      "name": "普陀区"
    }, {
      "adcode": "310109",
      "name": "虹口区"
    }, {
      "adcode": "310110",
      "name": "杨浦区"
    }, {
      "adcode": "310112",
      "name": "闵行区"
    }, {
      "adcode": "310113",
      "name": "宝山区"
    }, {
      "adcode": "310114",
      "name": "嘉定区"
    }, {
      "adcode": "310115",
      "name": "浦东新区"
    }, {
      "adcode": "310116",
      "name": "金山区"
    }, {
      "adcode": "310117",
      "name": "松江区"
    }, {
      "adcode": "310118",
      "name": "青浦区"
    }, {
      "adcode": "310120",
      "name": "奉贤区"
    }, {
      "adcode": "310151",
      "name": "崇明区"
    }]
  }]
}, {
  "adcode": "320000",
  "name": "江苏省",
  "districts": [{
    "adcode": "320100",
    "name": "南京市",
    "districts": [{
      "adcode": "320102",
      "name": "玄武区"
    }, {
      "adcode": "320104",
      "name": "秦淮区"
    }, {
      "adcode": "320105",
      "name": "建邺区"
    }, {
      "adcode": "320106",
      "name": "鼓楼区"
    }, {
      "adcode": "320111",
      "name": "浦口区"
    }, {
      "adcode": "320113",
      "name": "栖霞区"
    }, {
      "adcode": "320114",
      "name": "雨花台区"
    }, {
      "adcode": "320115",
      "name": "江宁区"
    }, {
      "adcode": "320116",
      "name": "六合区"
    }, {
      "adcode": "320117",
      "name": "溧水区"
    }, {
      "adcode": "320118",
      "name": "高淳区"
    }]
  }, {
    "adcode": "320200",
    "name": "无锡市",
    "districts": [{
      "adcode": "320205",
      "name": "锡山区"
    }, {
      "adcode": "320206",
      "name": "惠山区"
    }, {
      "adcode": "320211",
      "name": "滨湖区"
    }, {
      "adcode": "320213",
      "name": "梁溪区"
    }, {
      "adcode": "320214",
      "name": "新吴区"
    }, {
      "adcode": "320281",
      "name": "江阴市"
    }, {
      "adcode": "320282",
      "name": "宜兴市"
    }]
  }, {
    "adcode": "320300",
    "name": "徐州市",
    "districts": [{
      "adcode": "320302",
      "name": "鼓楼区"
    }, {
      "adcode": "320303",
      "name": "云龙区"
    }, {
      "adcode": "320305",
      "name": "贾汪区"
    }, {
      "adcode": "320311",
      "name": "泉山区"
    }, {
      "adcode": "320312",
      "name": "铜山区"
    }, {
      "adcode": "320321",
      "name": "丰县"
    }, {
      "adcode": "320322",
      "name": "沛县"
    }, {
      "adcode": "320324",
      "name": "睢宁县"
    }, {
      "adcode": "320381",
      "name": "新沂市"
    }, {
      "adcode": "320382",
      "name": "邳州市"
    }]
  }, {
    "adcode": "320400",
    "name": "常州市",
    "districts": [{
      "adcode": "320402",
      "name": "天宁区"
    }, {
      "adcode": "320404",
      "name": "钟楼区"
    }, {
      "adcode": "320411",
      "name": "新北区"
    }, {
      "adcode": "320412",
      "name": "武进区"
    }, {
      "adcode": "320413",
      "name": "金坛区"
    }, {
      "adcode": "320481",
      "name": "溧阳市"
    }]
  }, {
    "adcode": "320500",
    "name": "苏州市",
    "districts": [{
      "adcode": "320505",
      "name": "虎丘区"
    }, {
      "adcode": "320506",
      "name": "吴中区"
    }, {
      "adcode": "320507",
      "name": "相城区"
    }, {
      "adcode": "320508",
      "name": "姑苏区"
    }, {
      "adcode": "320509",
      "name": "吴江区"
    }, {
      "adcode": "320581",
      "name": "常熟市"
    }, {
      "adcode": "320582",
      "name": "张家港市"
    }, {
      "adcode": "320583",
      "name": "昆山市"
    }, {
      "adcode": "320585",
      "name": "太仓市"
    }]
  }, {
    "adcode": "320600",
    "name": "南通市",
    "districts": [{
      "adcode": "320602",
      "name": "崇川区"
    }, {
      "adcode": "320611",
      "name": "港闸区"
    }, {
      "adcode": "320612",
      "name": "通州区"
    }, {
      "adcode": "320623",
      "name": "如东县"
    }, {
      "adcode": "320681",
      "name": "启东市"
    }, {
      "adcode": "320682",
      "name": "如皋市"
    }, {
      "adcode": "320684",
      "name": "海门市"
    }, {
      "adcode": "320685",
      "name": "海安市"
    }]
  }, {
    "adcode": "320700",
    "name": "连云港市",
    "districts": [{
      "adcode": "320703",
      "name": "连云区"
    }, {
      "adcode": "320706",
      "name": "海州区"
    }, {
      "adcode": "320707",
      "name": "赣榆区"
    }, {
      "adcode": "320722",
      "name": "东海县"
    }, {
      "adcode": "320723",
      "name": "灌云县"
    }, {
      "adcode": "320724",
      "name": "灌南县"
    }]
  }, {
    "adcode": "320800",
    "name": "淮安市",
    "districts": [{
      "adcode": "320803",
      "name": "淮安区"
    }, {
      "adcode": "320804",
      "name": "淮阴区"
    }, {
      "adcode": "320812",
      "name": "清江浦区"
    }, {
      "adcode": "320813",
      "name": "洪泽区"
    }, {
      "adcode": "320826",
      "name": "涟水县"
    }, {
      "adcode": "320830",
      "name": "盱眙县"
    }, {
      "adcode": "320831",
      "name": "金湖县"
    }]
  }, {
    "adcode": "320900",
    "name": "盐城市",
    "districts": [{
      "adcode": "320902",
      "name": "亭湖区"
    }, {
      "adcode": "320903",
      "name": "盐都区"
    }, {
      "adcode": "320904",
      "name": "大丰区"
    }, {
      "adcode": "320921",
      "name": "响水县"
    }, {
      "adcode": "320922",
      "name": "滨海县"
    }, {
      "adcode": "320923",
      "name": "阜宁县"
    }, {
      "adcode": "320924",
      "name": "射阳县"
    }, {
      "adcode": "320925",
      "name": "建湖县"
    }, {
      "adcode": "320981",
      "name": "东台市"
    }]
  }, {
    "adcode": "321000",
    "name": "扬州市",
    "districts": [{
      "adcode": "321002",
      "name": "广陵区"
    }, {
      "adcode": "321003",
      "name": "邗江区"
    }, {
      "adcode": "321012",
      "name": "江都区"
    }, {
      "adcode": "321023",
      "name": "宝应县"
    }, {
      "adcode": "321081",
      "name": "仪征市"
    }, {
      "adcode": "321084",
      "name": "高邮市"
    }]
  }, {
    "adcode": "321100",
    "name": "镇江市",
    "districts": [{
      "adcode": "321102",
      "name": "京口区"
    }, {
      "adcode": "321111",
      "name": "润州区"
    }, {
      "adcode": "321112",
      "name": "丹徒区"
    }, {
      "adcode": "321181",
      "name": "丹阳市"
    }, {
      "adcode": "321182",
      "name": "扬中市"
    }, {
      "adcode": "321183",
      "name": "句容市"
    }]
  }, {
    "adcode": "321200",
    "name": "泰州市",
    "districts": [{
      "adcode": "321202",
      "name": "海陵区"
    }, {
      "adcode": "321203",
      "name": "高港区"
    }, {
      "adcode": "321204",
      "name": "姜堰区"
    }, {
      "adcode": "321281",
      "name": "兴化市"
    }, {
      "adcode": "321282",
      "name": "靖江市"
    }, {
      "adcode": "321283",
      "name": "泰兴市"
    }]
  }, {
    "adcode": "321300",
    "name": "宿迁市",
    "districts": [{
      "adcode": "321302",
      "name": "宿城区"
    }, {
      "adcode": "321311",
      "name": "宿豫区"
    }, {
      "adcode": "321322",
      "name": "沭阳县"
    }, {
      "adcode": "321323",
      "name": "泗阳县"
    }, {
      "adcode": "321324",
      "name": "泗洪县"
    }]
  }]
},
{
  "adcode": "330000",
  "name": "浙江省",
  "districts": [{
    "adcode": "330100",
    "name": "杭州市",
    "districts": [{
      "adcode": "330102",
      "name": "上城区"
    }, {
      "adcode": "330103",
      "name": "下城区"
    }, {
      "adcode": "330104",
      "name": "江干区"
    }, {
      "adcode": "330105",
      "name": "拱墅区"
    }, {
      "adcode": "330106",
      "name": "西湖区"
    }, {
      "adcode": "330108",
      "name": "滨江区"
    }, {
      "adcode": "330109",
      "name": "萧山区"
    }, {
      "adcode": "330110",
      "name": "余杭区"
    }, {
      "adcode": "330111",
      "name": "富阳区"
    }, {
      "adcode": "330112",
      "name": "临安区"
    }, {
      "adcode": "330122",
      "name": "桐庐县"
    }, {
      "adcode": "330127",
      "name": "淳安县"
    }, {
      "adcode": "330182",
      "name": "建德市"
    }]
  }, {
    "adcode": "330200",
    "name": "宁波市",
    "districts": [{
      "adcode": "330203",
      "name": "海曙区"
    }, {
      "adcode": "330205",
      "name": "江北区"
    }, {
      "adcode": "330206",
      "name": "北仑区"
    }, {
      "adcode": "330211",
      "name": "镇海区"
    }, {
      "adcode": "330212",
      "name": "鄞州区"
    }, {
      "adcode": "330213",
      "name": "奉化区"
    }, {
      "adcode": "330225",
      "name": "象山县"
    }, {
      "adcode": "330226",
      "name": "宁海县"
    }, {
      "adcode": "330281",
      "name": "余姚市"
    }, {
      "adcode": "330282",
      "name": "慈溪市"
    }]
  }, {
    "adcode": "330300",
    "name": "温州市",
    "districts": [{
      "adcode": "330302",
      "name": "鹿城区"
    }, {
      "adcode": "330303",
      "name": "龙湾区"
    }, {
      "adcode": "330304",
      "name": "瓯海区"
    }, {
      "adcode": "330305",
      "name": "洞头区"
    }, {
      "adcode": "330324",
      "name": "永嘉县"
    }, {
      "adcode": "330326",
      "name": "平阳县"
    }, {
      "adcode": "330327",
      "name": "苍南县"
    }, {
      "adcode": "330328",
      "name": "文成县"
    }, {
      "adcode": "330329",
      "name": "泰顺县"
    }, {
      "adcode": "330381",
      "name": "瑞安市"
    }, {
      "adcode": "330382",
      "name": "乐清市"
    }, {
      "adcode": "330383",
      "name": "龙港市"
    }]
  }, {
    "adcode": "330400",
    "name": "嘉兴市",
    "districts": [{
      "adcode": "330402",
      "name": "南湖区"
    }, {
      "adcode": "330411",
      "name": "秀洲区"
    }, {
      "adcode": "330421",
      "name": "嘉善县"
    }, {
      "adcode": "330424",
      "name": "海盐县"
    }, {
      "adcode": "330481",
      "name": "海宁市"
    }, {
      "adcode": "330482",
      "name": "平湖市"
    }, {
      "adcode": "330483",
      "name": "桐乡市"
    }]
  }, {
    "adcode": "330500",
    "name": "湖州市",
    "districts": [{
      "adcode": "330502",
      "name": "吴兴区"
    }, {
      "adcode": "330503",
      "name": "南浔区"
    }, {
      "adcode": "330521",
      "name": "德清县"
    }, {
      "adcode": "330522",
      "name": "长兴县"
    }, {
      "adcode": "330523",
      "name": "安吉县"
    }]
  }, {
    "adcode": "330600",
    "name": "绍兴市",
    "districts": [{
      "adcode": "330602",
      "name": "越城区"
    }, {
      "adcode": "330603",
      "name": "柯桥区"
    }, {
      "adcode": "330604",
      "name": "上虞区"
    }, {
      "adcode": "330624",
      "name": "新昌县"
    }, {
      "adcode": "330681",
      "name": "诸暨市"
    }, {
      "adcode": "330683",
      "name": "嵊州市"
    }]
  }, {
    "adcode": "330700",
    "name": "金华市",
    "districts": [{
      "adcode": "330702",
      "name": "婺城区"
    }, {
      "adcode": "330703",
      "name": "金东区"
    }, {
      "adcode": "330723",
      "name": "武义县"
    }, {
      "adcode": "330726",
      "name": "浦江县"
    }, {
      "adcode": "330727",
      "name": "磐安县"
    }, {
      "adcode": "330781",
      "name": "兰溪市"
    }, {
      "adcode": "330782",
      "name": "义乌市"
    }, {
      "adcode": "330783",
      "name": "东阳市"
    }, {
      "adcode": "330784",
      "name": "永康市"
    }]
  }, {
    "adcode": "330800",
    "name": "衢州市",
    "districts": [{
      "adcode": "330802",
      "name": "柯城区"
    }, {
      "adcode": "330803",
      "name": "衢江区"
    }, {
      "adcode": "330822",
      "name": "常山县"
    }, {
      "adcode": "330824",
      "name": "开化县"
    }, {
      "adcode": "330825",
      "name": "龙游县"
    }, {
      "adcode": "330881",
      "name": "江山市"
    }]
  }, {
    "adcode": "330900",
    "name": "舟山市",
    "districts": [{
      "adcode": "330902",
      "name": "定海区"
    }, {
      "adcode": "330903",
      "name": "普陀区"
    }, {
      "adcode": "330921",
      "name": "岱山县"
    }, {
      "adcode": "330922",
      "name": "嵊泗县"
    }]
  }, {
    "adcode": "331000",
    "name": "台州市",
    "districts": [{
      "adcode": "331002",
      "name": "椒江区"
    }, {
      "adcode": "331003",
      "name": "黄岩区"
    }, {
      "adcode": "331004",
      "name": "路桥区"
    }, {
      "adcode": "331022",
      "name": "三门县"
    }, {
      "adcode": "331023",
      "name": "天台县"
    }, {
      "adcode": "331024",
      "name": "仙居县"
    }, {
      "adcode": "331081",
      "name": "温岭市"
    }, {
      "adcode": "331082",
      "name": "临海市"
    }, {
      "adcode": "331083",
      "name": "玉环市"
    }]
  }, {
    "adcode": "331100",
    "name": "丽水市",
    "districts": [{
      "adcode": "331102",
      "name": "莲都区"
    }, {
      "adcode": "331121",
      "name": "青田县"
    }, {
      "adcode": "331122",
      "name": "缙云县"
    }, {
      "adcode": "331123",
      "name": "遂昌县"
    }, {
      "adcode": "331124",
      "name": "松阳县"
    }, {
      "adcode": "331125",
      "name": "云和县"
    }, {
      "adcode": "331126",
      "name": "庆元县"
    }, {
      "adcode": "331127",
      "name": "景宁畲族自治县"
    }, {
      "adcode": "331181",
      "name": "龙泉市"
    }]
  }]
},
{
  "adcode": "340000",
  "name": "安徽省",
  "districts": [{
    "adcode": "340100",
    "name": "合肥市",
    "districts": [{
      "adcode": "340102",
      "name": "瑶海区"
    }, {
      "adcode": "340103",
      "name": "庐阳区"
    }, {
      "adcode": "340104",
      "name": "蜀山区"
    }, {
      "adcode": "340111",
      "name": "包河区"
    }, {
      "adcode": "340121",
      "name": "长丰县"
    }, {
      "adcode": "340122",
      "name": "肥东县"
    }, {
      "adcode": "340123",
      "name": "肥西县"
    }, {
      "adcode": "340124",
      "name": "庐江县"
    }, {
      "adcode": "340181",
      "name": "巢湖市"
    }]
  }, {
    "adcode": "340200",
    "name": "芜湖市",
    "districts": [{
      "adcode": "340202",
      "name": "镜湖区"
    }, {
      "adcode": "340203",
      "name": "弋江区"
    }, {
      "adcode": "340207",
      "name": "鸠江区"
    }, {
      "adcode": "340208",
      "name": "三山区"
    }, {
      "adcode": "340221",
      "name": "芜湖县"
    }, {
      "adcode": "340222",
      "name": "繁昌县"
    }, {
      "adcode": "340223",
      "name": "南陵县"
    }, {
      "adcode": "340281",
      "name": "无为市"
    }]
  }, {
    "adcode": "340300",
    "name": "蚌埠市",
    "districts": [{
      "adcode": "340302",
      "name": "龙子湖区"
    }, {
      "adcode": "340303",
      "name": "蚌山区"
    }, {
      "adcode": "340304",
      "name": "禹会区"
    }, {
      "adcode": "340311",
      "name": "淮上区"
    }, {
      "adcode": "340321",
      "name": "怀远县"
    }, {
      "adcode": "340322",
      "name": "五河县"
    }, {
      "adcode": "340323",
      "name": "固镇县"
    }]
  }, {
    "adcode": "340400",
    "name": "淮南市",
    "districts": [{
      "adcode": "340402",
      "name": "大通区"
    }, {
      "adcode": "340403",
      "name": "田家庵区"
    }, {
      "adcode": "340404",
      "name": "谢家集区"
    }, {
      "adcode": "340405",
      "name": "八公山区"
    }, {
      "adcode": "340406",
      "name": "潘集区"
    }, {
      "adcode": "340421",
      "name": "凤台县"
    }, {
      "adcode": "340422",
      "name": "寿县"
    }]
  }, {
    "adcode": "340500",
    "name": "马鞍山市",
    "districts": [{
      "adcode": "340503",
      "name": "花山区"
    }, {
      "adcode": "340504",
      "name": "雨山区"
    }, {
      "adcode": "340506",
      "name": "博望区"
    }, {
      "adcode": "340521",
      "name": "当涂县"
    }, {
      "adcode": "340522",
      "name": "含山县"
    }, {
      "adcode": "340523",
      "name": "和县"
    }]
  }, {
    "adcode": "340600",
    "name": "淮北市",
    "districts": [{
      "adcode": "340602",
      "name": "杜集区"
    }, {
      "adcode": "340603",
      "name": "相山区"
    }, {
      "adcode": "340604",
      "name": "烈山区"
    }, {
      "adcode": "340621",
      "name": "濉溪县"
    }]
  }, {
    "adcode": "340700",
    "name": "铜陵市",
    "districts": [{
      "adcode": "340705",
      "name": "铜官区"
    }, {
      "adcode": "340706",
      "name": "义安区"
    }, {
      "adcode": "340711",
      "name": "郊区"
    }, {
      "adcode": "340722",
      "name": "枞阳县"
    }]
  }, {
    "adcode": "340800",
    "name": "安庆市",
    "districts": [{
      "adcode": "340802",
      "name": "迎江区"
    }, {
      "adcode": "340803",
      "name": "大观区"
    }, {
      "adcode": "340811",
      "name": "宜秀区"
    }, {
      "adcode": "340822",
      "name": "怀宁县"
    }, {
      "adcode": "340825",
      "name": "太湖县"
    }, {
      "adcode": "340826",
      "name": "宿松县"
    }, {
      "adcode": "340827",
      "name": "望江县"
    }, {
      "adcode": "340828",
      "name": "岳西县"
    }, {
      "adcode": "340881",
      "name": "桐城市"
    }, {
      "adcode": "340882",
      "name": "潜山市"
    }]
  }, {
    "adcode": "341000",
    "name": "黄山市",
    "districts": [{
      "adcode": "341002",
      "name": "屯溪区"
    }, {
      "adcode": "341003",
      "name": "黄山区"
    }, {
      "adcode": "341004",
      "name": "徽州区"
    }, {
      "adcode": "341021",
      "name": "歙县"
    }, {
      "adcode": "341022",
      "name": "休宁县"
    }, {
      "adcode": "341023",
      "name": "黟县"
    }, {
      "adcode": "341024",
      "name": "祁门县"
    }]
  }, {
    "adcode": "341100",
    "name": "滁州市",
    "districts": [{
      "adcode": "341102",
      "name": "琅琊区"
    }, {
      "adcode": "341103",
      "name": "南谯区"
    }, {
      "adcode": "341122",
      "name": "来安县"
    }, {
      "adcode": "341124",
      "name": "全椒县"
    }, {
      "adcode": "341125",
      "name": "定远县"
    }, {
      "adcode": "341126",
      "name": "凤阳县"
    }, {
      "adcode": "341181",
      "name": "天长市"
    }, {
      "adcode": "341182",
      "name": "明光市"
    }]
  }, {
    "adcode": "341200",
    "name": "阜阳市",
    "districts": [{
      "adcode": "341202",
      "name": "颍州区"
    }, {
      "adcode": "341203",
      "name": "颍东区"
    }, {
      "adcode": "341204",
      "name": "颍泉区"
    }, {
      "adcode": "341221",
      "name": "临泉县"
    }, {
      "adcode": "341222",
      "name": "太和县"
    }, {
      "adcode": "341225",
      "name": "阜南县"
    }, {
      "adcode": "341226",
      "name": "颍上县"
    }, {
      "adcode": "341282",
      "name": "界首市"
    }]
  }, {
    "adcode": "341300",
    "name": "宿州市",
    "districts": [{
      "adcode": "341302",
      "name": "埇桥区"
    }, {
      "adcode": "341321",
      "name": "砀山县"
    }, {
      "adcode": "341322",
      "name": "萧县"
    }, {
      "adcode": "341323",
      "name": "灵璧县"
    }, {
      "adcode": "341324",
      "name": "泗县"
    }]
  }, {
    "adcode": "341500",
    "name": "六安市",
    "districts": [{
      "adcode": "341502",
      "name": "金安区"
    }, {
      "adcode": "341503",
      "name": "裕安区"
    }, {
      "adcode": "341504",
      "name": "叶集区"
    }, {
      "adcode": "341522",
      "name": "霍邱县"
    }, {
      "adcode": "341523",
      "name": "舒城县"
    }, {
      "adcode": "341524",
      "name": "金寨县"
    }, {
      "adcode": "341525",
      "name": "霍山县"
    }]
  }, {
    "adcode": "341600",
    "name": "亳州市",
    "districts": [{
      "adcode": "341602",
      "name": "谯城区"
    }, {
      "adcode": "341621",
      "name": "涡阳县"
    }, {
      "adcode": "341622",
      "name": "蒙城县"
    }, {
      "adcode": "341623",
      "name": "利辛县"
    }]
  }, {
    "adcode": "341700",
    "name": "池州市",
    "districts": [{
      "adcode": "341702",
      "name": "贵池区"
    }, {
      "adcode": "341721",
      "name": "东至县"
    }, {
      "adcode": "341722",
      "name": "石台县"
    }, {
      "adcode": "341723",
      "name": "青阳县"
    }]
  }, {
    "adcode": "341800",
    "name": "宣城市",
    "districts": [{
      "adcode": "341802",
      "name": "宣州区"
    }, {
      "adcode": "341821",
      "name": "郎溪县"
    }, {
      "adcode": "341823",
      "name": "泾县"
    }, {
      "adcode": "341824",
      "name": "绩溪县"
    }, {
      "adcode": "341825",
      "name": "旌德县"
    }, {
      "adcode": "341881",
      "name": "宁国市"
    }, {
      "adcode": "341882",
      "name": "广德市"
    }]
  }]
}, {
  "adcode": "350000",
  "name": "福建省",
  "districts": [{
    "adcode": "350100",
    "name": "福州市",
    "districts": [{
      "adcode": "350102",
      "name": "鼓楼区"
    }, {
      "adcode": "350103",
      "name": "台江区"
    }, {
      "adcode": "350104",
      "name": "仓山区"
    }, {
      "adcode": "350105",
      "name": "马尾区"
    }, {
      "adcode": "350111",
      "name": "晋安区"
    }, {
      "adcode": "350112",
      "name": "长乐区"
    }, {
      "adcode": "350121",
      "name": "闽侯县"
    }, {
      "adcode": "350122",
      "name": "连江县"
    }, {
      "adcode": "350123",
      "name": "罗源县"
    }, {
      "adcode": "350124",
      "name": "闽清县"
    }, {
      "adcode": "350125",
      "name": "永泰县"
    }, {
      "adcode": "350128",
      "name": "平潭县"
    }, {
      "adcode": "350181",
      "name": "福清市"
    }]
  }, {
    "adcode": "350200",
    "name": "厦门市",
    "districts": [{
      "adcode": "350203",
      "name": "思明区"
    }, {
      "adcode": "350205",
      "name": "海沧区"
    }, {
      "adcode": "350206",
      "name": "湖里区"
    }, {
      "adcode": "350211",
      "name": "集美区"
    }, {
      "adcode": "350212",
      "name": "同安区"
    }, {
      "adcode": "350213",
      "name": "翔安区"
    }]
  }, {
    "adcode": "350300",
    "name": "莆田市",
    "districts": [{
      "adcode": "350302",
      "name": "城厢区"
    }, {
      "adcode": "350303",
      "name": "涵江区"
    }, {
      "adcode": "350304",
      "name": "荔城区"
    }, {
      "adcode": "350305",
      "name": "秀屿区"
    }, {
      "adcode": "350322",
      "name": "仙游县"
    }]
  }, {
    "adcode": "350400",
    "name": "三明市",
    "districts": [{
      "adcode": "350402",
      "name": "梅列区"
    }, {
      "adcode": "350403",
      "name": "三元区"
    }, {
      "adcode": "350421",
      "name": "明溪县"
    }, {
      "adcode": "350423",
      "name": "清流县"
    }, {
      "adcode": "350424",
      "name": "宁化县"
    }, {
      "adcode": "350425",
      "name": "大田县"
    }, {
      "adcode": "350426",
      "name": "尤溪县"
    }, {
      "adcode": "350427",
      "name": "沙县"
    }, {
      "adcode": "350428",
      "name": "将乐县"
    }, {
      "adcode": "350429",
      "name": "泰宁县"
    }, {
      "adcode": "350430",
      "name": "建宁县"
    }, {
      "adcode": "350481",
      "name": "永安市"
    }]
  }, {
    "adcode": "350500",
    "name": "泉州市",
    "districts": [{
      "adcode": "350502",
      "name": "鲤城区"
    }, {
      "adcode": "350503",
      "name": "丰泽区"
    }, {
      "adcode": "350504",
      "name": "洛江区"
    }, {
      "adcode": "350505",
      "name": "泉港区"
    }, {
      "adcode": "350521",
      "name": "惠安县"
    }, {
      "adcode": "350524",
      "name": "安溪县"
    }, {
      "adcode": "350525",
      "name": "永春县"
    }, {
      "adcode": "350526",
      "name": "德化县"
    }, {
      "adcode": "350527",
      "name": "金门县"
    }, {
      "adcode": "350581",
      "name": "石狮市"
    }, {
      "adcode": "350582",
      "name": "晋江市"
    }, {
      "adcode": "350583",
      "name": "南安市"
    }]
  }, {
    "adcode": "350600",
    "name": "漳州市",
    "districts": [{
      "adcode": "350602",
      "name": "芗城区"
    }, {
      "adcode": "350603",
      "name": "龙文区"
    }, {
      "adcode": "350622",
      "name": "云霄县"
    }, {
      "adcode": "350623",
      "name": "漳浦县"
    }, {
      "adcode": "350624",
      "name": "诏安县"
    }, {
      "adcode": "350625",
      "name": "长泰县"
    }, {
      "adcode": "350626",
      "name": "东山县"
    }, {
      "adcode": "350627",
      "name": "南靖县"
    }, {
      "adcode": "350628",
      "name": "平和县"
    }, {
      "adcode": "350629",
      "name": "华安县"
    }, {
      "adcode": "350681",
      "name": "龙海市"
    }]
  }, {
    "adcode": "350700",
    "name": "南平市",
    "districts": [{
      "adcode": "350702",
      "name": "延平区"
    }, {
      "adcode": "350703",
      "name": "建阳区"
    }, {
      "adcode": "350721",
      "name": "顺昌县"
    }, {
      "adcode": "350722",
      "name": "浦城县"
    }, {
      "adcode": "350723",
      "name": "光泽县"
    }, {
      "adcode": "350724",
      "name": "松溪县"
    }, {
      "adcode": "350725",
      "name": "政和县"
    }, {
      "adcode": "350781",
      "name": "邵武市"
    }, {
      "adcode": "350782",
      "name": "武夷山市"
    }, {
      "adcode": "350783",
      "name": "建瓯市"
    }]
  }, {
    "adcode": "350800",
    "name": "龙岩市",
    "districts": [{
      "adcode": "350802",
      "name": "新罗区"
    }, {
      "adcode": "350803",
      "name": "永定区"
    }, {
      "adcode": "350821",
      "name": "长汀县"
    }, {
      "adcode": "350823",
      "name": "上杭县"
    }, {
      "adcode": "350824",
      "name": "武平县"
    }, {
      "adcode": "350825",
      "name": "连城县"
    }, {
      "adcode": "350881",
      "name": "漳平市"
    }]
  }, {
    "adcode": "350900",
    "name": "宁德市",
    "districts": [{
      "adcode": "350902",
      "name": "蕉城区"
    }, {
      "adcode": "350921",
      "name": "霞浦县"
    }, {
      "adcode": "350922",
      "name": "古田县"
    }, {
      "adcode": "350923",
      "name": "屏南县"
    }, {
      "adcode": "350924",
      "name": "寿宁县"
    }, {
      "adcode": "350925",
      "name": "周宁县"
    }, {
      "adcode": "350926",
      "name": "柘荣县"
    }, {
      "adcode": "350981",
      "name": "福安市"
    }, {
      "adcode": "350982",
      "name": "福鼎市"
    }]
  }]
}, {
  "adcode": "360000",
  "name": "江西省",
  "districts": [{
    "adcode": "360100",
    "name": "南昌市",
    "districts": [{
      "adcode": "360102",
      "name": "东湖区"
    }, {
      "adcode": "360103",
      "name": "西湖区"
    }, {
      "adcode": "360104",
      "name": "青云谱区"
    }, {
      "adcode": "360111",
      "name": "青山湖区"
    }, {
      "adcode": "360112",
      "name": "新建区"
    }, {
      "adcode": "360113",
      "name": "红谷滩区"
    }, {
      "adcode": "360121",
      "name": "南昌县"
    }, {
      "adcode": "360123",
      "name": "安义县"
    }, {
      "adcode": "360124",
      "name": "进贤县"
    }]
  }, {
    "adcode": "360200",
    "name": "景德镇市",
    "districts": [{
      "adcode": "360202",
      "name": "昌江区"
    }, {
      "adcode": "360203",
      "name": "珠山区"
    }, {
      "adcode": "360222",
      "name": "浮梁县"
    }, {
      "adcode": "360281",
      "name": "乐平市"
    }]
  }, {
    "adcode": "360300",
    "name": "萍乡市",
    "districts": [{
      "adcode": "360302",
      "name": "安源区"
    }, {
      "adcode": "360313",
      "name": "湘东区"
    }, {
      "adcode": "360321",
      "name": "莲花县"
    }, {
      "adcode": "360322",
      "name": "上栗县"
    }, {
      "adcode": "360323",
      "name": "芦溪县"
    }]
  }, {
    "adcode": "360400",
    "name": "九江市",
    "districts": [{
      "adcode": "360402",
      "name": "濂溪区"
    }, {
      "adcode": "360403",
      "name": "浔阳区"
    }, {
      "adcode": "360404",
      "name": "柴桑区"
    }, {
      "adcode": "360423",
      "name": "武宁县"
    }, {
      "adcode": "360424",
      "name": "修水县"
    }, {
      "adcode": "360425",
      "name": "永修县"
    }, {
      "adcode": "360426",
      "name": "德安县"
    }, {
      "adcode": "360428",
      "name": "都昌县"
    }, {
      "adcode": "360429",
      "name": "湖口县"
    }, {
      "adcode": "360430",
      "name": "彭泽县"
    }, {
      "adcode": "360481",
      "name": "瑞昌市"
    }, {
      "adcode": "360482",
      "name": "共青城市"
    }, {
      "adcode": "360483",
      "name": "庐山市"
    }]
  }, {
    "adcode": "360500",
    "name": "新余市",
    "districts": [{
      "adcode": "360502",
      "name": "渝水区"
    }, {
      "adcode": "360521",
      "name": "分宜县"
    }]
  }, {
    "adcode": "360600",
    "name": "鹰潭市",
    "districts": [{
      "adcode": "360602",
      "name": "月湖区"
    }, {
      "adcode": "360603",
      "name": "余江区"
    }, {
      "adcode": "360681",
      "name": "贵溪市"
    }]
  }, {
    "adcode": "360700",
    "name": "赣州市",
    "districts": [{
      "adcode": "360702",
      "name": "章贡区"
    }, {
      "adcode": "360703",
      "name": "南康区"
    }, {
      "adcode": "360704",
      "name": "赣县区"
    }, {
      "adcode": "360722",
      "name": "信丰县"
    }, {
      "adcode": "360723",
      "name": "大余县"
    }, {
      "adcode": "360724",
      "name": "上犹县"
    }, {
      "adcode": "360725",
      "name": "崇义县"
    }, {
      "adcode": "360726",
      "name": "安远县"
    }, {
      "adcode": "360727",
      "name": "龙南县"
    }, {
      "adcode": "360728",
      "name": "定南县"
    }, {
      "adcode": "360729",
      "name": "全南县"
    }, {
      "adcode": "360730",
      "name": "宁都县"
    }, {
      "adcode": "360731",
      "name": "于都县"
    }, {
      "adcode": "360732",
      "name": "兴国县"
    }, {
      "adcode": "360733",
      "name": "会昌县"
    }, {
      "adcode": "360734",
      "name": "寻乌县"
    }, {
      "adcode": "360735",
      "name": "石城县"
    }, {
      "adcode": "360781",
      "name": "瑞金市"
    }]
  }, {
    "adcode": "360800",
    "name": "吉安市",
    "districts": [{
      "adcode": "360802",
      "name": "吉州区"
    }, {
      "adcode": "360803",
      "name": "青原区"
    }, {
      "adcode": "360821",
      "name": "吉安县"
    }, {
      "adcode": "360822",
      "name": "吉水县"
    }, {
      "adcode": "360823",
      "name": "峡江县"
    }, {
      "adcode": "360824",
      "name": "新干县"
    }, {
      "adcode": "360825",
      "name": "永丰县"
    }, {
      "adcode": "360826",
      "name": "泰和县"
    }, {
      "adcode": "360827",
      "name": "遂川县"
    }, {
      "adcode": "360828",
      "name": "万安县"
    }, {
      "adcode": "360829",
      "name": "安福县"
    }, {
      "adcode": "360830",
      "name": "永新县"
    }, {
      "adcode": "360881",
      "name": "井冈山市"
    }]
  }, {
    "adcode": "360900",
    "name": "宜春市",
    "districts": [{
      "adcode": "360902",
      "name": "袁州区"
    }, {
      "adcode": "360921",
      "name": "奉新县"
    }, {
      "adcode": "360922",
      "name": "万载县"
    }, {
      "adcode": "360923",
      "name": "上高县"
    }, {
      "adcode": "360924",
      "name": "宜丰县"
    }, {
      "adcode": "360925",
      "name": "靖安县"
    }, {
      "adcode": "360926",
      "name": "铜鼓县"
    }, {
      "adcode": "360981",
      "name": "丰城市"
    }, {
      "adcode": "360982",
      "name": "樟树市"
    }, {
      "adcode": "360983",
      "name": "高安市"
    }]
  }, {
    "adcode": "361000",
    "name": "抚州市",
    "districts": [{
      "adcode": "361002",
      "name": "临川区"
    }, {
      "adcode": "361003",
      "name": "东乡区"
    }, {
      "adcode": "361021",
      "name": "南城县"
    }, {
      "adcode": "361022",
      "name": "黎川县"
    }, {
      "adcode": "361023",
      "name": "南丰县"
    }, {
      "adcode": "361024",
      "name": "崇仁县"
    }, {
      "adcode": "361025",
      "name": "乐安县"
    }, {
      "adcode": "361026",
      "name": "宜黄县"
    }, {
      "adcode": "361027",
      "name": "金溪县"
    }, {
      "adcode": "361028",
      "name": "资溪县"
    }, {
      "adcode": "361030",
      "name": "广昌县"
    }]
  }, {
    "adcode": "361100",
    "name": "上饶市",
    "districts": [{
      "adcode": "361102",
      "name": "信州区"
    }, {
      "adcode": "361103",
      "name": "广丰区"
    }, {
      "adcode": "361104",
      "name": "广信区"
    }, {
      "adcode": "361123",
      "name": "玉山县"
    }, {
      "adcode": "361124",
      "name": "铅山县"
    }, {
      "adcode": "361125",
      "name": "横峰县"
    }, {
      "adcode": "361126",
      "name": "弋阳县"
    }, {
      "adcode": "361127",
      "name": "余干县"
    }, {
      "adcode": "361128",
      "name": "鄱阳县"
    }, {
      "adcode": "361129",
      "name": "万年县"
    }, {
      "adcode": "361130",
      "name": "婺源县"
    }, {
      "adcode": "361181",
      "name": "德兴市"
    }]
  }]
}, {
  "adcode": "370000",
  "name": "山东省",
  "districts": [{
    "adcode": "370100",
    "name": "济南市",
    "districts": [{
      "adcode": "370102",
      "name": "历下区"
    }, {
      "adcode": "370103",
      "name": "市中区"
    }, {
      "adcode": "370104",
      "name": "槐荫区"
    }, {
      "adcode": "370105",
      "name": "天桥区"
    }, {
      "adcode": "370112",
      "name": "历城区"
    }, {
      "adcode": "370113",
      "name": "长清区"
    }, {
      "adcode": "370114",
      "name": "章丘区"
    }, {
      "adcode": "370115",
      "name": "济阳区"
    }, {
      "adcode": "370116",
      "name": "莱芜区"
    }, {
      "adcode": "370117",
      "name": "钢城区"
    }, {
      "adcode": "370124",
      "name": "平阴县"
    }, {
      "adcode": "370126",
      "name": "商河县"
    }]
  }, {
    "adcode": "370200",
    "name": "青岛市",
    "districts": [{
      "adcode": "370202",
      "name": "市南区"
    }, {
      "adcode": "370203",
      "name": "市北区"
    }, {
      "adcode": "370211",
      "name": "黄岛区"
    }, {
      "adcode": "370212",
      "name": "崂山区"
    }, {
      "adcode": "370213",
      "name": "李沧区"
    }, {
      "adcode": "370214",
      "name": "城阳区"
    }, {
      "adcode": "370215",
      "name": "即墨区"
    }, {
      "adcode": "370281",
      "name": "胶州市"
    }, {
      "adcode": "370283",
      "name": "平度市"
    }, {
      "adcode": "370285",
      "name": "莱西市"
    }]
  }, {
    "adcode": "370300",
    "name": "淄博市",
    "districts": [{
      "adcode": "370302",
      "name": "淄川区"
    }, {
      "adcode": "370303",
      "name": "张店区"
    }, {
      "adcode": "370304",
      "name": "博山区"
    }, {
      "adcode": "370305",
      "name": "临淄区"
    }, {
      "adcode": "370306",
      "name": "周村区"
    }, {
      "adcode": "370321",
      "name": "桓台县"
    }, {
      "adcode": "370322",
      "name": "高青县"
    }, {
      "adcode": "370323",
      "name": "沂源县"
    }]
  }, {
    "adcode": "370400",
    "name": "枣庄市",
    "districts": [{
      "adcode": "370402",
      "name": "市中区"
    }, {
      "adcode": "370403",
      "name": "薛城区"
    }, {
      "adcode": "370404",
      "name": "峄城区"
    }, {
      "adcode": "370405",
      "name": "台儿庄区"
    }, {
      "adcode": "370406",
      "name": "山亭区"
    }, {
      "adcode": "370481",
      "name": "滕州市"
    }]
  }, {
    "adcode": "370500",
    "name": "东营市",
    "districts": [{
      "adcode": "370502",
      "name": "东营区"
    }, {
      "adcode": "370503",
      "name": "河口区"
    }, {
      "adcode": "370505",
      "name": "垦利区"
    }, {
      "adcode": "370522",
      "name": "利津县"
    }, {
      "adcode": "370523",
      "name": "广饶县"
    }]
  }, {
    "adcode": "370600",
    "name": "烟台市",
    "districts": [{
      "adcode": "370602",
      "name": "芝罘区"
    }, {
      "adcode": "370611",
      "name": "福山区"
    }, {
      "adcode": "370612",
      "name": "牟平区"
    }, {
      "adcode": "370613",
      "name": "莱山区"
    }, {
      "adcode": "370634",
      "name": "长岛县"
    }, {
      "adcode": "370681",
      "name": "龙口市"
    }, {
      "adcode": "370682",
      "name": "莱阳市"
    }, {
      "adcode": "370683",
      "name": "莱州市"
    }, {
      "adcode": "370684",
      "name": "蓬莱市"
    }, {
      "adcode": "370685",
      "name": "招远市"
    }, {
      "adcode": "370686",
      "name": "栖霞市"
    }, {
      "adcode": "370687",
      "name": "海阳市"
    }]
  }, {
    "adcode": "370700",
    "name": "潍坊市",
    "districts": [{
      "adcode": "370702",
      "name": "潍城区"
    }, {
      "adcode": "370703",
      "name": "寒亭区"
    }, {
      "adcode": "370704",
      "name": "坊子区"
    }, {
      "adcode": "370705",
      "name": "奎文区"
    }, {
      "adcode": "370724",
      "name": "临朐县"
    }, {
      "adcode": "370725",
      "name": "昌乐县"
    }, {
      "adcode": "370781",
      "name": "青州市"
    }, {
      "adcode": "370782",
      "name": "诸城市"
    }, {
      "adcode": "370783",
      "name": "寿光市"
    }, {
      "adcode": "370784",
      "name": "安丘市"
    }, {
      "adcode": "370785",
      "name": "高密市"
    }, {
      "adcode": "370786",
      "name": "昌邑市"
    }]
  }, {
    "adcode": "370800",
    "name": "济宁市",
    "districts": [{
      "adcode": "370811",
      "name": "任城区"
    }, {
      "adcode": "370812",
      "name": "兖州区"
    }, {
      "adcode": "370826",
      "name": "微山县"
    }, {
      "adcode": "370827",
      "name": "鱼台县"
    }, {
      "adcode": "370828",
      "name": "金乡县"
    }, {
      "adcode": "370829",
      "name": "嘉祥县"
    }, {
      "adcode": "370830",
      "name": "汶上县"
    }, {
      "adcode": "370831",
      "name": "泗水县"
    }, {
      "adcode": "370832",
      "name": "梁山县"
    }, {
      "adcode": "370881",
      "name": "曲阜市"
    }, {
      "adcode": "370883",
      "name": "邹城市"
    }]
  }, {
    "adcode": "370900",
    "name": "泰安市",
    "districts": [{
      "adcode": "370902",
      "name": "泰山区"
    }, {
      "adcode": "370911",
      "name": "岱岳区"
    }, {
      "adcode": "370921",
      "name": "宁阳县"
    }, {
      "adcode": "370923",
      "name": "东平县"
    }, {
      "adcode": "370982",
      "name": "新泰市"
    }, {
      "adcode": "370983",
      "name": "肥城市"
    }]
  }, {
    "adcode": "371000",
    "name": "威海市",
    "districts": [{
      "adcode": "371002",
      "name": "环翠区"
    }, {
      "adcode": "371003",
      "name": "文登区"
    }, {
      "adcode": "371082",
      "name": "荣成市"
    }, {
      "adcode": "371083",
      "name": "乳山市"
    }]
  }, {
    "adcode": "371100",
    "name": "日照市",
    "districts": [{
      "adcode": "371102",
      "name": "东港区"
    }, {
      "adcode": "371103",
      "name": "岚山区"
    }, {
      "adcode": "371121",
      "name": "五莲县"
    }, {
      "adcode": "371122",
      "name": "莒县"
    }]
  }, {
    "adcode": "371300",
    "name": "临沂市",
    "districts": [{
      "adcode": "371302",
      "name": "兰山区"
    }, {
      "adcode": "371311",
      "name": "罗庄区"
    }, {
      "adcode": "371312",
      "name": "河东区"
    }, {
      "adcode": "371321",
      "name": "沂南县"
    }, {
      "adcode": "371322",
      "name": "郯城县"
    }, {
      "adcode": "371323",
      "name": "沂水县"
    }, {
      "adcode": "371324",
      "name": "兰陵县"
    }, {
      "adcode": "371325",
      "name": "费县"
    }, {
      "adcode": "371326",
      "name": "平邑县"
    }, {
      "adcode": "371327",
      "name": "莒南县"
    }, {
      "adcode": "371328",
      "name": "蒙阴县"
    }, {
      "adcode": "371329",
      "name": "临沭县"
    }]
  }, {
    "adcode": "371400",
    "name": "德州市",
    "districts": [{
      "adcode": "371402",
      "name": "德城区"
    }, {
      "adcode": "371403",
      "name": "陵城区"
    }, {
      "adcode": "371422",
      "name": "宁津县"
    }, {
      "adcode": "371423",
      "name": "庆云县"
    }, {
      "adcode": "371424",
      "name": "临邑县"
    }, {
      "adcode": "371425",
      "name": "齐河县"
    }, {
      "adcode": "371426",
      "name": "平原县"
    }, {
      "adcode": "371427",
      "name": "夏津县"
    }, {
      "adcode": "371428",
      "name": "武城县"
    }, {
      "adcode": "371481",
      "name": "乐陵市"
    }, {
      "adcode": "371482",
      "name": "禹城市"
    }]
  }, {
    "adcode": "371500",
    "name": "聊城市",
    "districts": [{
      "adcode": "371502",
      "name": "东昌府区"
    }, {
      "adcode": "371523",
      "name": "茌平区"
    }, {
      "adcode": "371521",
      "name": "阳谷县"
    }, {
      "adcode": "371522",
      "name": "莘县"
    }, {
      "adcode": "371524",
      "name": "东阿县"
    }, {
      "adcode": "371525",
      "name": "冠县"
    }, {
      "adcode": "371526",
      "name": "高唐县"
    }, {
      "adcode": "371581",
      "name": "临清市"
    }]
  }, {
    "adcode": "371600",
    "name": "滨州市",
    "districts": [{
      "adcode": "371602",
      "name": "滨城区"
    }, {
      "adcode": "371603",
      "name": "沾化区"
    }, {
      "adcode": "371621",
      "name": "惠民县"
    }, {
      "adcode": "371622",
      "name": "阳信县"
    }, {
      "adcode": "371623",
      "name": "无棣县"
    }, {
      "adcode": "371625",
      "name": "博兴县"
    }, {
      "adcode": "371681",
      "name": "邹平市"
    }]
  }, {
    "adcode": "371700",
    "name": "菏泽市",
    "districts": [{
      "adcode": "371702",
      "name": "牡丹区"
    }, {
      "adcode": "371703",
      "name": "定陶区"
    }, {
      "adcode": "371721",
      "name": "曹县"
    }, {
      "adcode": "371722",
      "name": "单县"
    }, {
      "adcode": "371723",
      "name": "成武县"
    }, {
      "adcode": "371724",
      "name": "巨野县"
    }, {
      "adcode": "371725",
      "name": "郓城县"
    }, {
      "adcode": "371726",
      "name": "鄄城县"
    }, {
      "adcode": "371728",
      "name": "东明县"
    }]
  }]
}, {
  "adcode": "410000",
  "name": "河南省",
  "districts": [{
    "adcode": "410100",
    "name": "郑州市",
    "districts": [{
      "adcode": "410102",
      "name": "中原区"
    }, {
      "adcode": "410103",
      "name": "二七区"
    }, {
      "adcode": "410104",
      "name": "管城回族区"
    }, {
      "adcode": "410105",
      "name": "金水区"
    }, {
      "adcode": "410106",
      "name": "上街区"
    }, {
      "adcode": "410108",
      "name": "惠济区"
    }, {
      "adcode": "410122",
      "name": "中牟县"
    }, {
      "adcode": "410181",
      "name": "巩义市"
    }, {
      "adcode": "410182",
      "name": "荥阳市"
    }, {
      "adcode": "410183",
      "name": "新密市"
    }, {
      "adcode": "410184",
      "name": "新郑市"
    }, {
      "adcode": "410185",
      "name": "登封市"
    }]
  }, {
    "adcode": "410200",
    "name": "开封市",
    "districts": [{
      "adcode": "410202",
      "name": "龙亭区"
    }, {
      "adcode": "410203",
      "name": "顺河回族区"
    }, {
      "adcode": "410204",
      "name": "鼓楼区"
    }, {
      "adcode": "410205",
      "name": "禹王台区"
    }, {
      "adcode": "410212",
      "name": "祥符区"
    }, {
      "adcode": "410221",
      "name": "杞县"
    }, {
      "adcode": "410222",
      "name": "通许县"
    }, {
      "adcode": "410223",
      "name": "尉氏县"
    }, {
      "adcode": "410225",
      "name": "兰考县"
    }]
  }, {
    "adcode": "410300",
    "name": "洛阳市",
    "districts": [{
      "adcode": "410302",
      "name": "老城区"
    }, {
      "adcode": "410303",
      "name": "西工区"
    }, {
      "adcode": "410304",
      "name": "瀍河回族区"
    }, {
      "adcode": "410305",
      "name": "涧西区"
    }, {
      "adcode": "410306",
      "name": "吉利区"
    }, {
      "adcode": "410311",
      "name": "洛龙区"
    }, {
      "adcode": "410322",
      "name": "孟津县"
    }, {
      "adcode": "410323",
      "name": "新安县"
    }, {
      "adcode": "410324",
      "name": "栾川县"
    }, {
      "adcode": "410325",
      "name": "嵩县"
    }, {
      "adcode": "410326",
      "name": "汝阳县"
    }, {
      "adcode": "410327",
      "name": "宜阳县"
    }, {
      "adcode": "410328",
      "name": "洛宁县"
    }, {
      "adcode": "410329",
      "name": "伊川县"
    }, {
      "adcode": "410381",
      "name": "偃师市"
    }]
  }, {
    "adcode": "410400",
    "name": "平顶山市",
    "districts": [{
      "adcode": "410402",
      "name": "新华区"
    }, {
      "adcode": "410403",
      "name": "卫东区"
    }, {
      "adcode": "410404",
      "name": "石龙区"
    }, {
      "adcode": "410411",
      "name": "湛河区"
    }, {
      "adcode": "410421",
      "name": "宝丰县"
    }, {
      "adcode": "410422",
      "name": "叶县"
    }, {
      "adcode": "410423",
      "name": "鲁山县"
    }, {
      "adcode": "410425",
      "name": "郏县"
    }, {
      "adcode": "410481",
      "name": "舞钢市"
    }, {
      "adcode": "410482",
      "name": "汝州市"
    }]
  }, {
    "adcode": "410500",
    "name": "安阳市",
    "districts": [{
      "adcode": "410502",
      "name": "文峰区"
    }, {
      "adcode": "410503",
      "name": "北关区"
    }, {
      "adcode": "410505",
      "name": "殷都区"
    }, {
      "adcode": "410506",
      "name": "龙安区"
    }, {
      "adcode": "410522",
      "name": "安阳县"
    }, {
      "adcode": "410523",
      "name": "汤阴县"
    }, {
      "adcode": "410526",
      "name": "滑县"
    }, {
      "adcode": "410527",
      "name": "内黄县"
    }, {
      "adcode": "410581",
      "name": "林州市"
    }]
  }, {
    "adcode": "410600",
    "name": "鹤壁市",
    "districts": [{
      "adcode": "410602",
      "name": "鹤山区"
    }, {
      "adcode": "410603",
      "name": "山城区"
    }, {
      "adcode": "410611",
      "name": "淇滨区"
    }, {
      "adcode": "410621",
      "name": "浚县"
    }, {
      "adcode": "410622",
      "name": "淇县"
    }]
  }, {
    "adcode": "410700",
    "name": "新乡市",
    "districts": [{
      "adcode": "410702",
      "name": "红旗区"
    }, {
      "adcode": "410703",
      "name": "卫滨区"
    }, {
      "adcode": "410704",
      "name": "凤泉区"
    }, {
      "adcode": "410711",
      "name": "牧野区"
    }, {
      "adcode": "410721",
      "name": "新乡县"
    }, {
      "adcode": "410724",
      "name": "获嘉县"
    }, {
      "adcode": "410725",
      "name": "原阳县"
    }, {
      "adcode": "410726",
      "name": "延津县"
    }, {
      "adcode": "410727",
      "name": "封丘县"
    }, {
      "adcode": "410781",
      "name": "卫辉市"
    }, {
      "adcode": "410782",
      "name": "辉县市"
    }, {
      "adcode": "410783",
      "name": "长垣市"
    }]
  }, {
    "adcode": "410800",
    "name": "焦作市",
    "districts": [{
      "adcode": "410802",
      "name": "解放区"
    }, {
      "adcode": "410803",
      "name": "中站区"
    }, {
      "adcode": "410804",
      "name": "马村区"
    }, {
      "adcode": "410811",
      "name": "山阳区"
    }, {
      "adcode": "410821",
      "name": "修武县"
    }, {
      "adcode": "410822",
      "name": "博爱县"
    }, {
      "adcode": "410823",
      "name": "武陟县"
    }, {
      "adcode": "410825",
      "name": "温县"
    }, {
      "adcode": "410882",
      "name": "沁阳市"
    }, {
      "adcode": "410883",
      "name": "孟州市"
    }]
  }, {
    "adcode": "410900",
    "name": "濮阳市",
    "districts": [{
      "adcode": "410902",
      "name": "华龙区"
    }, {
      "adcode": "410922",
      "name": "清丰县"
    }, {
      "adcode": "410923",
      "name": "南乐县"
    }, {
      "adcode": "410926",
      "name": "范县"
    }, {
      "adcode": "410927",
      "name": "台前县"
    }, {
      "adcode": "410928",
      "name": "濮阳县"
    }]
  }, {
    "adcode": "411000",
    "name": "许昌市",
    "districts": [{
      "adcode": "411002",
      "name": "魏都区"
    }, {
      "adcode": "411003",
      "name": "建安区"
    }, {
      "adcode": "411024",
      "name": "鄢陵县"
    }, {
      "adcode": "411025",
      "name": "襄城县"
    }, {
      "adcode": "411081",
      "name": "禹州市"
    }, {
      "adcode": "411082",
      "name": "长葛市"
    }]
  }, {
    "adcode": "411100",
    "name": "漯河市",
    "districts": [{
      "adcode": "411102",
      "name": "源汇区"
    }, {
      "adcode": "411103",
      "name": "郾城区"
    }, {
      "adcode": "411104",
      "name": "召陵区"
    }, {
      "adcode": "411121",
      "name": "舞阳县"
    }, {
      "adcode": "411122",
      "name": "临颍县"
    }]
  }, {
    "adcode": "411200",
    "name": "三门峡市",
    "districts": [{
      "adcode": "411202",
      "name": "湖滨区"
    }, {
      "adcode": "411203",
      "name": "陕州区"
    }, {
      "adcode": "411221",
      "name": "渑池县"
    }, {
      "adcode": "411224",
      "name": "卢氏县"
    }, {
      "adcode": "411281",
      "name": "义马市"
    }, {
      "adcode": "411282",
      "name": "灵宝市"
    }]
  }, {
    "adcode": "411300",
    "name": "南阳市",
    "districts": [{
      "adcode": "411302",
      "name": "宛城区"
    }, {
      "adcode": "411303",
      "name": "卧龙区"
    }, {
      "adcode": "411321",
      "name": "南召县"
    }, {
      "adcode": "411322",
      "name": "方城县"
    }, {
      "adcode": "411323",
      "name": "西峡县"
    }, {
      "adcode": "411324",
      "name": "镇平县"
    }, {
      "adcode": "411325",
      "name": "内乡县"
    }, {
      "adcode": "411326",
      "name": "淅川县"
    }, {
      "adcode": "411327",
      "name": "社旗县"
    }, {
      "adcode": "411328",
      "name": "唐河县"
    }, {
      "adcode": "411329",
      "name": "新野县"
    }, {
      "adcode": "411330",
      "name": "桐柏县"
    }, {
      "adcode": "411381",
      "name": "邓州市"
    }]
  }, {
    "adcode": "411400",
    "name": "商丘市",
    "districts": [{
      "adcode": "411402",
      "name": "梁园区"
    }, {
      "adcode": "411403",
      "name": "睢阳区"
    }, {
      "adcode": "411421",
      "name": "民权县"
    }, {
      "adcode": "411422",
      "name": "睢县"
    }, {
      "adcode": "411423",
      "name": "宁陵县"
    }, {
      "adcode": "411424",
      "name": "柘城县"
    }, {
      "adcode": "411425",
      "name": "虞城县"
    }, {
      "adcode": "411426",
      "name": "夏邑县"
    }, {
      "adcode": "411481",
      "name": "永城市"
    }]
  }, {
    "adcode": "411500",
    "name": "信阳市",
    "districts": [{
      "adcode": "411502",
      "name": "浉河区"
    }, {
      "adcode": "411503",
      "name": "平桥区"
    }, {
      "adcode": "411521",
      "name": "罗山县"
    }, {
      "adcode": "411522",
      "name": "光山县"
    }, {
      "adcode": "411523",
      "name": "新县"
    }, {
      "adcode": "411524",
      "name": "商城县"
    }, {
      "adcode": "411525",
      "name": "固始县"
    }, {
      "adcode": "411526",
      "name": "潢川县"
    }, {
      "adcode": "411527",
      "name": "淮滨县"
    }, {
      "adcode": "411528",
      "name": "息县"
    }]
  }, {
    "adcode": "411600",
    "name": "周口市",
    "districts": [{
      "adcode": "411602",
      "name": "川汇区"
    }, {
      "adcode": "411603",
      "name": "淮阳区"
    }, {
      "adcode": "411621",
      "name": "扶沟县"
    }, {
      "adcode": "411622",
      "name": "西华县"
    }, {
      "adcode": "411623",
      "name": "商水县"
    }, {
      "adcode": "411624",
      "name": "沈丘县"
    }, {
      "adcode": "411625",
      "name": "郸城县"
    }, {
      "adcode": "411627",
      "name": "太康县"
    }, {
      "adcode": "411628",
      "name": "鹿邑县"
    }, {
      "adcode": "411681",
      "name": "项城市"
    }]
  }, {
    "adcode": "411700",
    "name": "驻马店市",
    "districts": [{
      "adcode": "411702",
      "name": "驿城区"
    }, {
      "adcode": "411721",
      "name": "西平县"
    }, {
      "adcode": "411722",
      "name": "上蔡县"
    }, {
      "adcode": "411723",
      "name": "平舆县"
    }, {
      "adcode": "411724",
      "name": "正阳县"
    }, {
      "adcode": "411725",
      "name": "确山县"
    }, {
      "adcode": "411726",
      "name": "泌阳县"
    }, {
      "adcode": "411727",
      "name": "汝南县"
    }, {
      "adcode": "411728",
      "name": "遂平县"
    }, {
      "adcode": "411729",
      "name": "新蔡县"
    }]
  }, {
    "adcode": "419000",
    "name": "省直辖县级行政区划",
    "districts": [{
      "adcode": "419001",
      "name": "济源市"
    }]
  }]
}, {
  "adcode": "420000",
  "name": "湖北省",
  "districts": [{
    "adcode": "420100",
    "name": "武汉市",
    "districts": [{
      "adcode": "420102",
      "name": "江岸区"
    }, {
      "adcode": "420103",
      "name": "江汉区"
    }, {
      "adcode": "420104",
      "name": "硚口区"
    }, {
      "adcode": "420105",
      "name": "汉阳区"
    }, {
      "adcode": "420106",
      "name": "武昌区"
    }, {
      "adcode": "420107",
      "name": "青山区"
    }, {
      "adcode": "420111",
      "name": "洪山区"
    }, {
      "adcode": "420112",
      "name": "东西湖区"
    }, {
      "adcode": "420113",
      "name": "汉南区"
    }, {
      "adcode": "420114",
      "name": "蔡甸区"
    }, {
      "adcode": "420115",
      "name": "江夏区"
    }, {
      "adcode": "420116",
      "name": "黄陂区"
    }, {
      "adcode": "420117",
      "name": "新洲区"
    }]
  }, {
    "adcode": "420200",
    "name": "黄石市",
    "districts": [{
      "adcode": "420202",
      "name": "黄石港区"
    }, {
      "adcode": "420203",
      "name": "西塞山区"
    }, {
      "adcode": "420204",
      "name": "下陆区"
    }, {
      "adcode": "420205",
      "name": "铁山区"
    }, {
      "adcode": "420222",
      "name": "阳新县"
    }, {
      "adcode": "420281",
      "name": "大冶市"
    }]
  }, {
    "adcode": "420300",
    "name": "十堰市",
    "districts": [{
      "adcode": "420302",
      "name": "茅箭区"
    }, {
      "adcode": "420303",
      "name": "张湾区"
    }, {
      "adcode": "420304",
      "name": "郧阳区"
    }, {
      "adcode": "420322",
      "name": "郧西县"
    }, {
      "adcode": "420323",
      "name": "竹山县"
    }, {
      "adcode": "420324",
      "name": "竹溪县"
    }, {
      "adcode": "420325",
      "name": "房县"
    }, {
      "adcode": "420381",
      "name": "丹江口市"
    }]
  }, {
    "adcode": "420500",
    "name": "宜昌市",
    "districts": [{
      "adcode": "420502",
      "name": "西陵区"
    }, {
      "adcode": "420503",
      "name": "伍家岗区"
    }, {
      "adcode": "420504",
      "name": "点军区"
    }, {
      "adcode": "420505",
      "name": "猇亭区"
    }, {
      "adcode": "420506",
      "name": "夷陵区"
    }, {
      "adcode": "420525",
      "name": "远安县"
    }, {
      "adcode": "420526",
      "name": "兴山县"
    }, {
      "adcode": "420527",
      "name": "秭归县"
    }, {
      "adcode": "420528",
      "name": "长阳土家族自治县"
    }, {
      "adcode": "420529",
      "name": "五峰土家族自治县"
    }, {
      "adcode": "420581",
      "name": "宜都市"
    }, {
      "adcode": "420582",
      "name": "当阳市"
    }, {
      "adcode": "420583",
      "name": "枝江市"
    }]
  }, {
    "adcode": "420600",
    "name": "襄阳市",
    "districts": [{
      "adcode": "420602",
      "name": "襄城区"
    }, {
      "adcode": "420606",
      "name": "樊城区"
    }, {
      "adcode": "420607",
      "name": "襄州区"
    }, {
      "adcode": "420624",
      "name": "南漳县"
    }, {
      "adcode": "420625",
      "name": "谷城县"
    }, {
      "adcode": "420626",
      "name": "保康县"
    }, {
      "adcode": "420682",
      "name": "老河口市"
    }, {
      "adcode": "420683",
      "name": "枣阳市"
    }, {
      "adcode": "420684",
      "name": "宜城市"
    }]
  }, {
    "adcode": "420700",
    "name": "鄂州市",
    "districts": [{
      "adcode": "420702",
      "name": "梁子湖区"
    }, {
      "adcode": "420703",
      "name": "华容区"
    }, {
      "adcode": "420704",
      "name": "鄂城区"
    }]
  }, {
    "adcode": "420800",
    "name": "荆门市",
    "districts": [{
      "adcode": "420802",
      "name": "东宝区"
    }, {
      "adcode": "420804",
      "name": "掇刀区"
    }, {
      "adcode": "420822",
      "name": "沙洋县"
    }, {
      "adcode": "420881",
      "name": "钟祥市"
    }, {
      "adcode": "420882",
      "name": "京山市"
    }]
  }, {
    "adcode": "420900",
    "name": "孝感市",
    "districts": [{
      "adcode": "420902",
      "name": "孝南区"
    }, {
      "adcode": "420921",
      "name": "孝昌县"
    }, {
      "adcode": "420922",
      "name": "大悟县"
    }, {
      "adcode": "420923",
      "name": "云梦县"
    }, {
      "adcode": "420981",
      "name": "应城市"
    }, {
      "adcode": "420982",
      "name": "安陆市"
    }, {
      "adcode": "420984",
      "name": "汉川市"
    }]
  }, {
    "adcode": "421000",
    "name": "荆州市",
    "districts": [{
      "adcode": "421002",
      "name": "沙市区"
    }, {
      "adcode": "421003",
      "name": "荆州区"
    }, {
      "adcode": "421022",
      "name": "公安县"
    }, {
      "adcode": "421023",
      "name": "监利县"
    }, {
      "adcode": "421024",
      "name": "江陵县"
    }, {
      "adcode": "421081",
      "name": "石首市"
    }, {
      "adcode": "421083",
      "name": "洪湖市"
    }, {
      "adcode": "421087",
      "name": "松滋市"
    }]
  }, {
    "adcode": "421100",
    "name": "黄冈市",
    "districts": [{
      "adcode": "421102",
      "name": "黄州区"
    }, {
      "adcode": "421121",
      "name": "团风县"
    }, {
      "adcode": "421122",
      "name": "红安县"
    }, {
      "adcode": "421123",
      "name": "罗田县"
    }, {
      "adcode": "421124",
      "name": "英山县"
    }, {
      "adcode": "421125",
      "name": "浠水县"
    }, {
      "adcode": "421126",
      "name": "蕲春县"
    }, {
      "adcode": "421127",
      "name": "黄梅县"
    }, {
      "adcode": "421181",
      "name": "麻城市"
    }, {
      "adcode": "421182",
      "name": "武穴市"
    }]
  }, {
    "adcode": "421200",
    "name": "咸宁市",
    "districts": [{
      "adcode": "421202",
      "name": "咸安区"
    }, {
      "adcode": "421221",
      "name": "嘉鱼县"
    }, {
      "adcode": "421222",
      "name": "通城县"
    }, {
      "adcode": "421223",
      "name": "崇阳县"
    }, {
      "adcode": "421224",
      "name": "通山县"
    }, {
      "adcode": "421281",
      "name": "赤壁市"
    }]
  }, {
    "adcode": "421300",
    "name": "随州市",
    "districts": [{
      "adcode": "421303",
      "name": "曾都区"
    }, {
      "adcode": "421321",
      "name": "随县"
    }, {
      "adcode": "421381",
      "name": "广水市"
    }]
  }, {
    "adcode": "422800",
    "name": "恩施土家族苗族自治州",
    "districts": [{
      "adcode": "422801",
      "name": "恩施市"
    }, {
      "adcode": "422802",
      "name": "利川市"
    }, {
      "adcode": "422822",
      "name": "建始县"
    }, {
      "adcode": "422823",
      "name": "巴东县"
    }, {
      "adcode": "422825",
      "name": "宣恩县"
    }, {
      "adcode": "422826",
      "name": "咸丰县"
    }, {
      "adcode": "422827",
      "name": "来凤县"
    }, {
      "adcode": "422828",
      "name": "鹤峰县"
    }]
  }, {
    "adcode": "429000",
    "name": "省直辖县级行政区划",
    "districts": [{
      "adcode": "429004",
      "name": "仙桃市"
    }, {
      "adcode": "429005",
      "name": "潜江市"
    }, {
      "adcode": "429006",
      "name": "天门市"
    }, {
      "adcode": "429021",
      "name": "神农架林区"
    }]
  }]
}, {
  "adcode": "430000",
  "name": "湖南省",
  "districts": [{
    "adcode": "430100",
    "name": "长沙市",
    "districts": [{
      "adcode": "430102",
      "name": "芙蓉区"
    }, {
      "adcode": "430103",
      "name": "天心区"
    }, {
      "adcode": "430104",
      "name": "岳麓区"
    }, {
      "adcode": "430105",
      "name": "开福区"
    }, {
      "adcode": "430111",
      "name": "雨花区"
    }, {
      "adcode": "430112",
      "name": "望城区"
    }, {
      "adcode": "430121",
      "name": "长沙县"
    }, {
      "adcode": "430181",
      "name": "浏阳市"
    }, {
      "adcode": "430182",
      "name": "宁乡市"
    }]
  }, {
    "adcode": "430200",
    "name": "株洲市",
    "districts": [{
      "adcode": "430202",
      "name": "荷塘区"
    }, {
      "adcode": "430203",
      "name": "芦淞区"
    }, {
      "adcode": "430204",
      "name": "石峰区"
    }, {
      "adcode": "430211",
      "name": "天元区"
    }, {
      "adcode": "430212",
      "name": "渌口区"
    }, {
      "adcode": "430223",
      "name": "攸县"
    }, {
      "adcode": "430224",
      "name": "茶陵县"
    }, {
      "adcode": "430225",
      "name": "炎陵县"
    }, {
      "adcode": "430281",
      "name": "醴陵市"
    }]
  }, {
    "adcode": "430300",
    "name": "湘潭市",
    "districts": [{
      "adcode": "430302",
      "name": "雨湖区"
    }, {
      "adcode": "430304",
      "name": "岳塘区"
    }, {
      "adcode": "430321",
      "name": "湘潭县"
    }, {
      "adcode": "430381",
      "name": "湘乡市"
    }, {
      "adcode": "430382",
      "name": "韶山市"
    }]
  }, {
    "adcode": "430400",
    "name": "衡阳市",
    "districts": [{
      "adcode": "430405",
      "name": "珠晖区"
    }, {
      "adcode": "430406",
      "name": "雁峰区"
    }, {
      "adcode": "430407",
      "name": "石鼓区"
    }, {
      "adcode": "430408",
      "name": "蒸湘区"
    }, {
      "adcode": "430412",
      "name": "南岳区"
    }, {
      "adcode": "430421",
      "name": "衡阳县"
    }, {
      "adcode": "430422",
      "name": "衡南县"
    }, {
      "adcode": "430423",
      "name": "衡山县"
    }, {
      "adcode": "430424",
      "name": "衡东县"
    }, {
      "adcode": "430426",
      "name": "祁东县"
    }, {
      "adcode": "430481",
      "name": "耒阳市"
    }, {
      "adcode": "430482",
      "name": "常宁市"
    }]
  }, {
    "adcode": "430500",
    "name": "邵阳市",
    "districts": [{
      "adcode": "430502",
      "name": "双清区"
    }, {
      "adcode": "430503",
      "name": "大祥区"
    }, {
      "adcode": "430511",
      "name": "北塔区"
    }, {
      "adcode": "430522",
      "name": "新邵县"
    }, {
      "adcode": "430523",
      "name": "邵阳县"
    }, {
      "adcode": "430524",
      "name": "隆回县"
    }, {
      "adcode": "430525",
      "name": "洞口县"
    }, {
      "adcode": "430527",
      "name": "绥宁县"
    }, {
      "adcode": "430528",
      "name": "新宁县"
    }, {
      "adcode": "430529",
      "name": "城步苗族自治县"
    }, {
      "adcode": "430581",
      "name": "武冈市"
    }, {
      "adcode": "430582",
      "name": "邵东市"
    }]
  }, {
    "adcode": "430600",
    "name": "岳阳市",
    "districts": [{
      "adcode": "430602",
      "name": "岳阳楼区"
    }, {
      "adcode": "430603",
      "name": "云溪区"
    }, {
      "adcode": "430611",
      "name": "君山区"
    }, {
      "adcode": "430621",
      "name": "岳阳县"
    }, {
      "adcode": "430623",
      "name": "华容县"
    }, {
      "adcode": "430624",
      "name": "湘阴县"
    }, {
      "adcode": "430626",
      "name": "平江县"
    }, {
      "adcode": "430681",
      "name": "汨罗市"
    }, {
      "adcode": "430682",
      "name": "临湘市"
    }]
  }, {
    "adcode": "430700",
    "name": "常德市",
    "districts": [{
      "adcode": "430702",
      "name": "武陵区"
    }, {
      "adcode": "430703",
      "name": "鼎城区"
    }, {
      "adcode": "430721",
      "name": "安乡县"
    }, {
      "adcode": "430722",
      "name": "汉寿县"
    }, {
      "adcode": "430723",
      "name": "澧县"
    }, {
      "adcode": "430724",
      "name": "临澧县"
    }, {
      "adcode": "430725",
      "name": "桃源县"
    }, {
      "adcode": "430726",
      "name": "石门县"
    }, {
      "adcode": "430781",
      "name": "津市市"
    }]
  }, {
    "adcode": "430800",
    "name": "张家界市",
    "districts": [{
      "adcode": "430802",
      "name": "永定区"
    }, {
      "adcode": "430811",
      "name": "武陵源区"
    }, {
      "adcode": "430821",
      "name": "慈利县"
    }, {
      "adcode": "430822",
      "name": "桑植县"
    }]
  }, {
    "adcode": "430900",
    "name": "益阳市",
    "districts": [{
      "adcode": "430902",
      "name": "资阳区"
    }, {
      "adcode": "430903",
      "name": "赫山区"
    }, {
      "adcode": "430921",
      "name": "南县"
    }, {
      "adcode": "430922",
      "name": "桃江县"
    }, {
      "adcode": "430923",
      "name": "安化县"
    }, {
      "adcode": "430981",
      "name": "沅江市"
    }]
  }, {
    "adcode": "431000",
    "name": "郴州市",
    "districts": [{
      "adcode": "431002",
      "name": "北湖区"
    }, {
      "adcode": "431003",
      "name": "苏仙区"
    }, {
      "adcode": "431021",
      "name": "桂阳县"
    }, {
      "adcode": "431022",
      "name": "宜章县"
    }, {
      "adcode": "431023",
      "name": "永兴县"
    }, {
      "adcode": "431024",
      "name": "嘉禾县"
    }, {
      "adcode": "431025",
      "name": "临武县"
    }, {
      "adcode": "431026",
      "name": "汝城县"
    }, {
      "adcode": "431027",
      "name": "桂东县"
    }, {
      "adcode": "431028",
      "name": "安仁县"
    }, {
      "adcode": "431081",
      "name": "资兴市"
    }]
  }, {
    "adcode": "431100",
    "name": "永州市",
    "districts": [{
      "adcode": "431102",
      "name": "零陵区"
    }, {
      "adcode": "431103",
      "name": "冷水滩区"
    }, {
      "adcode": "431121",
      "name": "祁阳县"
    }, {
      "adcode": "431122",
      "name": "东安县"
    }, {
      "adcode": "431123",
      "name": "双牌县"
    }, {
      "adcode": "431124",
      "name": "道县"
    }, {
      "adcode": "431125",
      "name": "江永县"
    }, {
      "adcode": "431126",
      "name": "宁远县"
    }, {
      "adcode": "431127",
      "name": "蓝山县"
    }, {
      "adcode": "431128",
      "name": "新田县"
    }, {
      "adcode": "431129",
      "name": "江华瑶族自治县"
    }]
  }, {
    "adcode": "431200",
    "name": "怀化市",
    "districts": [{
      "adcode": "431202",
      "name": "鹤城区"
    }, {
      "adcode": "431221",
      "name": "中方县"
    }, {
      "adcode": "431222",
      "name": "沅陵县"
    }, {
      "adcode": "431223",
      "name": "辰溪县"
    }, {
      "adcode": "431224",
      "name": "溆浦县"
    }, {
      "adcode": "431225",
      "name": "会同县"
    }, {
      "adcode": "431226",
      "name": "麻阳苗族自治县"
    }, {
      "adcode": "431227",
      "name": "新晃侗族自治县"
    }, {
      "adcode": "431228",
      "name": "芷江侗族自治县"
    }, {
      "adcode": "431229",
      "name": "靖州苗族侗族自治县"
    }, {
      "adcode": "431230",
      "name": "通道侗族自治县"
    }, {
      "adcode": "431281",
      "name": "洪江市"
    }]
  }, {
    "adcode": "431300",
    "name": "娄底市",
    "districts": [{
      "adcode": "431302",
      "name": "娄星区"
    }, {
      "adcode": "431321",
      "name": "双峰县"
    }, {
      "adcode": "431322",
      "name": "新化县"
    }, {
      "adcode": "431381",
      "name": "冷水江市"
    }, {
      "adcode": "431382",
      "name": "涟源市"
    }]
  }, {
    "adcode": "433100",
    "name": "湘西土家族苗族自治州",
    "districts": [{
      "adcode": "433101",
      "name": "吉首市"
    }, {
      "adcode": "433122",
      "name": "泸溪县"
    }, {
      "adcode": "433123",
      "name": "凤凰县"
    }, {
      "adcode": "433124",
      "name": "花垣县"
    }, {
      "adcode": "433125",
      "name": "保靖县"
    }, {
      "adcode": "433126",
      "name": "古丈县"
    }, {
      "adcode": "433127",
      "name": "永顺县"
    }, {
      "adcode": "433130",
      "name": "龙山县"
    }]
  }]
}, {
  "adcode": "440000",
  "name": "广东省",
  "districts": [{
    "adcode": "440100",
    "name": "广州市",
    "districts": [{
      "adcode": "440103",
      "name": "荔湾区"
    }, {
      "adcode": "440104",
      "name": "越秀区"
    }, {
      "adcode": "440105",
      "name": "海珠区"
    }, {
      "adcode": "440106",
      "name": "天河区"
    }, {
      "adcode": "440111",
      "name": "白云区"
    }, {
      "adcode": "440112",
      "name": "黄埔区"
    }, {
      "adcode": "440113",
      "name": "番禺区"
    }, {
      "adcode": "440114",
      "name": "花都区"
    }, {
      "adcode": "440115",
      "name": "南沙区"
    }, {
      "adcode": "440117",
      "name": "从化区"
    }, {
      "adcode": "440118",
      "name": "增城区"
    }]
  }, {
    "adcode": "440200",
    "name": "韶关市",
    "districts": [{
      "adcode": "440203",
      "name": "武江区"
    }, {
      "adcode": "440204",
      "name": "浈江区"
    }, {
      "adcode": "440205",
      "name": "曲江区"
    }, {
      "adcode": "440222",
      "name": "始兴县"
    }, {
      "adcode": "440224",
      "name": "仁化县"
    }, {
      "adcode": "440229",
      "name": "翁源县"
    }, {
      "adcode": "440232",
      "name": "乳源瑶族自治县"
    }, {
      "adcode": "440233",
      "name": "新丰县"
    }, {
      "adcode": "440281",
      "name": "乐昌市"
    }, {
      "adcode": "440282",
      "name": "南雄市"
    }]
  }, {
    "adcode": "440300",
    "name": "深圳市",
    "districts": [{
      "adcode": "440303",
      "name": "罗湖区"
    }, {
      "adcode": "440304",
      "name": "福田区"
    }, {
      "adcode": "440305",
      "name": "南山区"
    }, {
      "adcode": "440306",
      "name": "宝安区"
    }, {
      "adcode": "440307",
      "name": "龙岗区"
    }, {
      "adcode": "440308",
      "name": "盐田区"
    }, {
      "adcode": "440309",
      "name": "龙华区"
    }, {
      "adcode": "440310",
      "name": "坪山区"
    }, {
      "adcode": "440311",
      "name": "光明区"
    }]
  }, {
    "adcode": "440400",
    "name": "珠海市",
    "districts": [{
      "adcode": "440402",
      "name": "香洲区"
    }, {
      "adcode": "440403",
      "name": "斗门区"
    }, {
      "adcode": "440404",
      "name": "金湾区"
    }]
  }, {
    "adcode": "440500",
    "name": "汕头市",
    "districts": [{
      "adcode": "440507",
      "name": "龙湖区"
    }, {
      "adcode": "440511",
      "name": "金平区"
    }, {
      "adcode": "440512",
      "name": "濠江区"
    }, {
      "adcode": "440513",
      "name": "潮阳区"
    }, {
      "adcode": "440514",
      "name": "潮南区"
    }, {
      "adcode": "440515",
      "name": "澄海区"
    }, {
      "adcode": "440523",
      "name": "南澳县"
    }]
  }, {
    "adcode": "440600",
    "name": "佛山市",
    "districts": [{
      "adcode": "440604",
      "name": "禅城区"
    }, {
      "adcode": "440605",
      "name": "南海区"
    }, {
      "adcode": "440606",
      "name": "顺德区"
    }, {
      "adcode": "440607",
      "name": "三水区"
    }, {
      "adcode": "440608",
      "name": "高明区"
    }]
  }, {
    "adcode": "440700",
    "name": "江门市",
    "districts": [{
      "adcode": "440703",
      "name": "蓬江区"
    }, {
      "adcode": "440704",
      "name": "江海区"
    }, {
      "adcode": "440705",
      "name": "新会区"
    }, {
      "adcode": "440781",
      "name": "台山市"
    }, {
      "adcode": "440783",
      "name": "开平市"
    }, {
      "adcode": "440784",
      "name": "鹤山市"
    }, {
      "adcode": "440785",
      "name": "恩平市"
    }]
  }, {
    "adcode": "440800",
    "name": "湛江市",
    "districts": [{
      "adcode": "440802",
      "name": "赤坎区"
    }, {
      "adcode": "440803",
      "name": "霞山区"
    }, {
      "adcode": "440804",
      "name": "坡头区"
    }, {
      "adcode": "440811",
      "name": "麻章区"
    }, {
      "adcode": "440823",
      "name": "遂溪县"
    }, {
      "adcode": "440825",
      "name": "徐闻县"
    }, {
      "adcode": "440881",
      "name": "廉江市"
    }, {
      "adcode": "440882",
      "name": "雷州市"
    }, {
      "adcode": "440883",
      "name": "吴川市"
    }]
  }, {
    "adcode": "440900",
    "name": "茂名市",
    "districts": [{
      "adcode": "440902",
      "name": "茂南区"
    }, {
      "adcode": "440904",
      "name": "电白区"
    }, {
      "adcode": "440981",
      "name": "高州市"
    }, {
      "adcode": "440982",
      "name": "化州市"
    }, {
      "adcode": "440983",
      "name": "信宜市"
    }]
  }, {
    "adcode": "441200",
    "name": "肇庆市",
    "districts": [{
      "adcode": "441202",
      "name": "端州区"
    }, {
      "adcode": "441203",
      "name": "鼎湖区"
    }, {
      "adcode": "441204",
      "name": "高要区"
    }, {
      "adcode": "441223",
      "name": "广宁县"
    }, {
      "adcode": "441224",
      "name": "怀集县"
    }, {
      "adcode": "441225",
      "name": "封开县"
    }, {
      "adcode": "441226",
      "name": "德庆县"
    }, {
      "adcode": "441284",
      "name": "四会市"
    }]
  }, {
    "adcode": "441300",
    "name": "惠州市",
    "districts": [{
      "adcode": "441302",
      "name": "惠城区"
    }, {
      "adcode": "441303",
      "name": "惠阳区"
    }, {
      "adcode": "441322",
      "name": "博罗县"
    }, {
      "adcode": "441323",
      "name": "惠东县"
    }, {
      "adcode": "441324",
      "name": "龙门县"
    }]
  }, {
    "adcode": "441400",
    "name": "梅州市",
    "districts": [{
      "adcode": "441402",
      "name": "梅江区"
    }, {
      "adcode": "441403",
      "name": "梅县区"
    }, {
      "adcode": "441422",
      "name": "大埔县"
    }, {
      "adcode": "441423",
      "name": "丰顺县"
    }, {
      "adcode": "441424",
      "name": "五华县"
    }, {
      "adcode": "441426",
      "name": "平远县"
    }, {
      "adcode": "441427",
      "name": "蕉岭县"
    }, {
      "adcode": "441481",
      "name": "兴宁市"
    }]
  }, {
    "adcode": "441500",
    "name": "汕尾市",
    "districts": [{
      "adcode": "441502",
      "name": "城区"
    }, {
      "adcode": "441521",
      "name": "海丰县"
    }, {
      "adcode": "441523",
      "name": "陆河县"
    }, {
      "adcode": "441581",
      "name": "陆丰市"
    }]
  }, {
    "adcode": "441600",
    "name": "河源市",
    "districts": [{
      "adcode": "441602",
      "name": "源城区"
    }, {
      "adcode": "441621",
      "name": "紫金县"
    }, {
      "adcode": "441622",
      "name": "龙川县"
    }, {
      "adcode": "441623",
      "name": "连平县"
    }, {
      "adcode": "441624",
      "name": "和平县"
    }, {
      "adcode": "441625",
      "name": "东源县"
    }]
  }, {
    "adcode": "441700",
    "name": "阳江市",
    "districts": [{
      "adcode": "441702",
      "name": "江城区"
    }, {
      "adcode": "441704",
      "name": "阳东区"
    }, {
      "adcode": "441721",
      "name": "阳西县"
    }, {
      "adcode": "441781",
      "name": "阳春市"
    }]
  }, {
    "adcode": "441800",
    "name": "清远市",
    "districts": [{
      "adcode": "441802",
      "name": "清城区"
    }, {
      "adcode": "441803",
      "name": "清新区"
    }, {
      "adcode": "441821",
      "name": "佛冈县"
    }, {
      "adcode": "441823",
      "name": "阳山县"
    }, {
      "adcode": "441825",
      "name": "连山壮族瑶族自治县"
    }, {
      "adcode": "441826",
      "name": "连南瑶族自治县"
    }, {
      "adcode": "441881",
      "name": "英德市"
    }, {
      "adcode": "441882",
      "name": "连州市"
    }]
  }, {
    "adcode": "441900",
    "name": "东莞市",
    "districts": [{
      "adcode": "441901",
      "name": "东城街道"
    }, {
      "adcode": "441902",
      "name": "南城街道"
    }, {
      "adcode": "441903",
      "name": "万江街道"
    }, {
      "adcode": "441904",
      "name": "莞城街道"
    }, {
      "adcode": "441905",
      "name": "石碣镇"
    }, {
      "adcode": "441906",
      "name": "石龙镇"
    }, {
      "adcode": "441907",
      "name": "茶山镇"
    }, {
      "adcode": "441908",
      "name": "石排镇"
    }, {
      "adcode": "441909",
      "name": "企石镇"
    }, {
      "adcode": "441910",
      "name": "横沥镇"
    }, {
      "adcode": "441911",
      "name": "桥头镇"
    }, {
      "adcode": "441912",
      "name": "谢岗镇"
    }, {
      "adcode": "441913",
      "name": "东坑镇"
    }, {
      "adcode": "441914",
      "name": "常平镇"
    }, {
      "adcode": "441915",
      "name": "寮步镇"
    }, {
      "adcode": "441916",
      "name": "樟木头镇"
    }, {
      "adcode": "441917",
      "name": "大朗镇"
    }, {
      "adcode": "441918",
      "name": "黄江镇"
    }, {
      "adcode": "441919",
      "name": "清溪镇"
    }, {
      "adcode": "441920",
      "name": "塘厦镇"
    }, {
      "adcode": "441921",
      "name": "凤岗镇"
    }, {
      "adcode": "441922",
      "name": "大岭山镇"
    }, {
      "adcode": "441923",
      "name": "长安镇"
    }, {
      "adcode": "441924",
      "name": "虎门镇"
    }, {
      "adcode": "441925",
      "name": "厚街镇"
    }, {
      "adcode": "441926",
      "name": "沙田镇"
    }, {
      "adcode": "441927",
      "name": "道滘镇"
    }, {
      "adcode": "441928",
      "name": "洪梅镇"
    }, {
      "adcode": "441929",
      "name": "麻涌镇"
    }, {
      "adcode": "441930",
      "name": "望牛墩镇"
    }, {
      "adcode": "441931",
      "name": "中堂镇"
    }, {
      "adcode": "441932",
      "name": "高埗镇"
    }, {
      "adcode": "441933",
      "name": "松山湖管委会"
    }, {
      "adcode": "441934",
      "name": "虎门港管委会"
    }, {
      "adcode": "441935",
      "name": "东莞生态园"
    }]
  }, {
    "adcode": "442000",
    "name": "中山市",
    "districts": [{
      "adcode": "442001",
      "name": "石岐街道"
    }, {
      "adcode": "442002",
      "name": "东区街道"
    }, {
      "adcode": "442003",
      "name": "中山港街道"
    }, {
      "adcode": "442004",
      "name": "西区街道"
    }, {
      "adcode": "442005",
      "name": "南区街道"
    }, {
      "adcode": "442006",
      "name": "五桂山街道"
    }, {
      "adcode": "442007",
      "name": "小榄镇"
    }, {
      "adcode": "442008",
      "name": "黄圃镇"
    }, {
      "adcode": "442009",
      "name": "民众镇"
    }, {
      "adcode": "442010",
      "name": "东凤镇"
    }, {
      "adcode": "442011",
      "name": "东升镇"
    }, {
      "adcode": "442012",
      "name": "古镇镇"
    }, {
      "adcode": "442013",
      "name": "沙溪镇"
    }, {
      "adcode": "442014",
      "name": "坦洲镇"
    }, {
      "adcode": "442015",
      "name": "港口镇"
    }, {
      "adcode": "442016",
      "name": "三角镇"
    }, {
      "adcode": "442017",
      "name": "横栏镇"
    }, {
      "adcode": "442018",
      "name": "南头镇"
    }, {
      "adcode": "442019",
      "name": "阜沙镇"
    }, {
      "adcode": "442020",
      "name": "南朗镇"
    }, {
      "adcode": "442021",
      "name": "三乡镇"
    }, {
      "adcode": "442022",
      "name": "板芙镇"
    }, {
      "adcode": "442023",
      "name": "大涌镇"
    }, {
      "adcode": "442024",
      "name": "神湾镇"
    }]
  }, {
    "adcode": "445100",
    "name": "潮州市",
    "districts": [{
      "adcode": "445102",
      "name": "湘桥区"
    }, {
      "adcode": "445103",
      "name": "潮安区"
    }, {
      "adcode": "445122",
      "name": "饶平县"
    }]
  }, {
    "adcode": "445200",
    "name": "揭阳市",
    "districts": [{
      "adcode": "445202",
      "name": "榕城区"
    }, {
      "adcode": "445203",
      "name": "揭东区"
    }, {
      "adcode": "445222",
      "name": "揭西县"
    }, {
      "adcode": "445224",
      "name": "惠来县"
    }, {
      "adcode": "445281",
      "name": "普宁市"
    }]
  }, {
    "adcode": "445300",
    "name": "云浮市",
    "districts": [{
      "adcode": "445302",
      "name": "云城区"
    }, {
      "adcode": "445303",
      "name": "云安区"
    }, {
      "adcode": "445321",
      "name": "新兴县"
    }, {
      "adcode": "445322",
      "name": "郁南县"
    }, {
      "adcode": "445381",
      "name": "罗定市"
    }]
  }]
}, {
  "adcode": "450000",
  "name": "广西壮族自治区",
  "districts": [{
    "adcode": "450100",
    "name": "南宁市",
    "districts": [{
      "adcode": "450102",
      "name": "兴宁区"
    }, {
      "adcode": "450103",
      "name": "青秀区"
    }, {
      "adcode": "450105",
      "name": "江南区"
    }, {
      "adcode": "450107",
      "name": "西乡塘区"
    }, {
      "adcode": "450108",
      "name": "良庆区"
    }, {
      "adcode": "450109",
      "name": "邕宁区"
    }, {
      "adcode": "450110",
      "name": "武鸣区"
    }, {
      "adcode": "450123",
      "name": "隆安县"
    }, {
      "adcode": "450124",
      "name": "马山县"
    }, {
      "adcode": "450125",
      "name": "上林县"
    }, {
      "adcode": "450126",
      "name": "宾阳县"
    }, {
      "adcode": "450127",
      "name": "横县"
    }]
  }, {
    "adcode": "450200",
    "name": "柳州市",
    "districts": [{
      "adcode": "450202",
      "name": "城中区"
    }, {
      "adcode": "450203",
      "name": "鱼峰区"
    }, {
      "adcode": "450204",
      "name": "柳南区"
    }, {
      "adcode": "450205",
      "name": "柳北区"
    }, {
      "adcode": "450206",
      "name": "柳江区"
    }, {
      "adcode": "450222",
      "name": "柳城县"
    }, {
      "adcode": "450223",
      "name": "鹿寨县"
    }, {
      "adcode": "450224",
      "name": "融安县"
    }, {
      "adcode": "450225",
      "name": "融水苗族自治县"
    }, {
      "adcode": "450226",
      "name": "三江侗族自治县"
    }]
  }, {
    "adcode": "450300",
    "name": "桂林市",
    "districts": [{
      "adcode": "450302",
      "name": "秀峰区"
    }, {
      "adcode": "450303",
      "name": "叠彩区"
    }, {
      "adcode": "450304",
      "name": "象山区"
    }, {
      "adcode": "450305",
      "name": "七星区"
    }, {
      "adcode": "450311",
      "name": "雁山区"
    }, {
      "adcode": "450312",
      "name": "临桂区"
    }, {
      "adcode": "450321",
      "name": "阳朔县"
    }, {
      "adcode": "450323",
      "name": "灵川县"
    }, {
      "adcode": "450324",
      "name": "全州县"
    }, {
      "adcode": "450325",
      "name": "兴安县"
    }, {
      "adcode": "450326",
      "name": "永福县"
    }, {
      "adcode": "450327",
      "name": "灌阳县"
    }, {
      "adcode": "450328",
      "name": "龙胜各族自治县"
    }, {
      "adcode": "450329",
      "name": "资源县"
    }, {
      "adcode": "450330",
      "name": "平乐县"
    }, {
      "adcode": "450332",
      "name": "恭城瑶族自治县"
    }, {
      "adcode": "450381",
      "name": "荔浦市"
    }]
  }, {
    "adcode": "450400",
    "name": "梧州市",
    "districts": [{
      "adcode": "450403",
      "name": "万秀区"
    }, {
      "adcode": "450405",
      "name": "长洲区"
    }, {
      "adcode": "450406",
      "name": "龙圩区"
    }, {
      "adcode": "450421",
      "name": "苍梧县"
    }, {
      "adcode": "450422",
      "name": "藤县"
    }, {
      "adcode": "450423",
      "name": "蒙山县"
    }, {
      "adcode": "450481",
      "name": "岑溪市"
    }]
  }, {
    "adcode": "450500",
    "name": "北海市",
    "districts": [{
      "adcode": "450502",
      "name": "海城区"
    }, {
      "adcode": "450503",
      "name": "银海区"
    }, {
      "adcode": "450512",
      "name": "铁山港区"
    }, {
      "adcode": "450521",
      "name": "合浦县"
    }]
  }, {
    "adcode": "450600",
    "name": "防城港市",
    "districts": [{
      "adcode": "450602",
      "name": "港口区"
    }, {
      "adcode": "450603",
      "name": "防城区"
    }, {
      "adcode": "450621",
      "name": "上思县"
    }, {
      "adcode": "450681",
      "name": "东兴市"
    }]
  }, {
    "adcode": "450700",
    "name": "钦州市",
    "districts": [{
      "adcode": "450702",
      "name": "钦南区"
    }, {
      "adcode": "450703",
      "name": "钦北区"
    }, {
      "adcode": "450721",
      "name": "灵山县"
    }, {
      "adcode": "450722",
      "name": "浦北县"
    }]
  }, {
    "adcode": "450800",
    "name": "贵港市",
    "districts": [{
      "adcode": "450802",
      "name": "港北区"
    }, {
      "adcode": "450803",
      "name": "港南区"
    }, {
      "adcode": "450804",
      "name": "覃塘区"
    }, {
      "adcode": "450821",
      "name": "平南县"
    }, {
      "adcode": "450881",
      "name": "桂平市"
    }]
  }, {
    "adcode": "450900",
    "name": "玉林市",
    "districts": [{
      "adcode": "450902",
      "name": "玉州区"
    }, {
      "adcode": "450903",
      "name": "福绵区"
    }, {
      "adcode": "450921",
      "name": "容县"
    }, {
      "adcode": "450922",
      "name": "陆川县"
    }, {
      "adcode": "450923",
      "name": "博白县"
    }, {
      "adcode": "450924",
      "name": "兴业县"
    }, {
      "adcode": "450981",
      "name": "北流市"
    }]
  }, {
    "adcode": "451000",
    "name": "百色市",
    "districts": [{
      "adcode": "451002",
      "name": "右江区"
    }, {
      "adcode": "451003",
      "name": "田阳区"
    }, {
      "adcode": "451022",
      "name": "田东县"
    }, {
      "adcode": "451024",
      "name": "德保县"
    }, {
      "adcode": "451026",
      "name": "那坡县"
    }, {
      "adcode": "451027",
      "name": "凌云县"
    }, {
      "adcode": "451028",
      "name": "乐业县"
    }, {
      "adcode": "451029",
      "name": "田林县"
    }, {
      "adcode": "451030",
      "name": "西林县"
    }, {
      "adcode": "451031",
      "name": "隆林各族自治县"
    }, {
      "adcode": "451081",
      "name": "靖西市"
    }, {
      "adcode": "451082",
      "name": "平果市"
    }]
  }, {
    "adcode": "451100",
    "name": "贺州市",
    "districts": [{
      "adcode": "451102",
      "name": "八步区"
    }, {
      "adcode": "451103",
      "name": "平桂区"
    }, {
      "adcode": "451121",
      "name": "昭平县"
    }, {
      "adcode": "451122",
      "name": "钟山县"
    }, {
      "adcode": "451123",
      "name": "富川瑶族自治县"
    }]
  }, {
    "adcode": "451200",
    "name": "河池市",
    "districts": [{
      "adcode": "451202",
      "name": "金城江区"
    }, {
      "adcode": "451203",
      "name": "宜州区"
    }, {
      "adcode": "451221",
      "name": "南丹县"
    }, {
      "adcode": "451222",
      "name": "天峨县"
    }, {
      "adcode": "451223",
      "name": "凤山县"
    }, {
      "adcode": "451224",
      "name": "东兰县"
    }, {
      "adcode": "451225",
      "name": "罗城仫佬族自治县"
    }, {
      "adcode": "451226",
      "name": "环江毛南族自治县"
    }, {
      "adcode": "451227",
      "name": "巴马瑶族自治县"
    }, {
      "adcode": "451228",
      "name": "都安瑶族自治县"
    }, {
      "adcode": "451229",
      "name": "大化瑶族自治县"
    }]
  }, {
    "adcode": "451300",
    "name": "来宾市",
    "districts": [{
      "adcode": "451302",
      "name": "兴宾区"
    }, {
      "adcode": "451321",
      "name": "忻城县"
    }, {
      "adcode": "451322",
      "name": "象州县"
    }, {
      "adcode": "451323",
      "name": "武宣县"
    }, {
      "adcode": "451324",
      "name": "金秀瑶族自治县"
    }, {
      "adcode": "451381",
      "name": "合山市"
    }]
  }, {
    "adcode": "451400",
    "name": "崇左市",
    "districts": [{
      "adcode": "451402",
      "name": "江州区"
    }, {
      "adcode": "451421",
      "name": "扶绥县"
    }, {
      "adcode": "451422",
      "name": "宁明县"
    }, {
      "adcode": "451423",
      "name": "龙州县"
    }, {
      "adcode": "451424",
      "name": "大新县"
    }, {
      "adcode": "451425",
      "name": "天等县"
    }, {
      "adcode": "451481",
      "name": "凭祥市"
    }]
  }]
}, {
  "adcode": "460000",
  "name": "海南省",
  "districts": [{
    "adcode": "460100",
    "name": "海口市",
    "districts": [{
      "adcode": "460105",
      "name": "秀英区"
    }, {
      "adcode": "460106",
      "name": "龙华区"
    }, {
      "adcode": "460107",
      "name": "琼山区"
    }, {
      "adcode": "460108",
      "name": "美兰区"
    }]
  }, {
    "adcode": "460200",
    "name": "三亚市",
    "districts": [{
      "adcode": "460202",
      "name": "海棠区"
    }, {
      "adcode": "460203",
      "name": "吉阳区"
    }, {
      "adcode": "460204",
      "name": "天涯区"
    }, {
      "adcode": "460205",
      "name": "崖州区"
    }]
  }, {
    "adcode": "460300",
    "name": "三沙市",
    "districts": [{
      "adcode": "460321",
      "name": "西沙群岛"
    }, {
      "adcode": "460322",
      "name": "南沙群岛"
    }, {
      "adcode": "460323",
      "name": "中沙群岛的岛礁及其海域"
    }]
  }, {
    "adcode": "460400",
    "name": "儋州市",
    "districts": [{
      "adcode": "460401",
      "name": "那大镇"
    }, {
      "adcode": "460402",
      "name": "和庆镇"
    }, {
      "adcode": "460403",
      "name": "南丰镇"
    }, {
      "adcode": "460404",
      "name": "大成镇"
    }, {
      "adcode": "460405",
      "name": "雅星镇"
    }, {
      "adcode": "460406",
      "name": "兰洋镇"
    }, {
      "adcode": "460407",
      "name": "光村镇"
    }, {
      "adcode": "460408",
      "name": "木棠镇"
    }, {
      "adcode": "460409",
      "name": "海头镇"
    }, {
      "adcode": "460410",
      "name": "峨蔓镇"
    }, {
      "adcode": "460412",
      "name": "王五镇"
    }, {
      "adcode": "460413",
      "name": "白马井镇"
    }, {
      "adcode": "460414",
      "name": "中和镇"
    }, {
      "adcode": "460415",
      "name": "排浦镇"
    }, {
      "adcode": "460416",
      "name": "东成镇"
    }, {
      "adcode": "460417",
      "name": "新州镇"
    }, {
      "adcode": "460422",
      "name": "洋浦经济开发区"
    }, {
      "adcode": "460423",
      "name": "华南热作学院"
    }]
  }, {
    "adcode": "469000",
    "name": "省直辖县级行政区划",
    "districts": [{
      "adcode": "469001",
      "name": "五指山市"
    }, {
      "adcode": "469002",
      "name": "琼海市"
    }, {
      "adcode": "469005",
      "name": "文昌市"
    }, {
      "adcode": "469006",
      "name": "万宁市"
    }, {
      "adcode": "469007",
      "name": "东方市"
    }, {
      "adcode": "469021",
      "name": "定安县"
    }, {
      "adcode": "469022",
      "name": "屯昌县"
    }, {
      "adcode": "469023",
      "name": "澄迈县"
    }, {
      "adcode": "469024",
      "name": "临高县"
    }, {
      "adcode": "469025",
      "name": "白沙黎族自治县"
    }, {
      "adcode": "469026",
      "name": "昌江黎族自治县"
    }, {
      "adcode": "469027",
      "name": "乐东黎族自治县"
    }, {
      "adcode": "469028",
      "name": "陵水黎族自治县"
    }, {
      "adcode": "469029",
      "name": "保亭黎族苗族自治县"
    }, {
      "adcode": "469030",
      "name": "琼中黎族苗族自治县"
    }]
  }]
}, {
  "adcode": "500000",
  "name": "重庆市",
  "districts": [{
    "adcode": "500100",
    "name": "重庆市",
    "districts": [{
      "adcode": "500101",
      "name": "万州区"
    }, {
      "adcode": "500102",
      "name": "涪陵区"
    }, {
      "adcode": "500103",
      "name": "渝中区"
    }, {
      "adcode": "500104",
      "name": "大渡口区"
    }, {
      "adcode": "500105",
      "name": "江北区"
    }, {
      "adcode": "500106",
      "name": "沙坪坝区"
    }, {
      "adcode": "500107",
      "name": "九龙坡区"
    }, {
      "adcode": "500108",
      "name": "南岸区"
    }, {
      "adcode": "500109",
      "name": "北碚区"
    }, {
      "adcode": "500110",
      "name": "綦江区"
    }, {
      "adcode": "500111",
      "name": "大足区"
    }, {
      "adcode": "500112",
      "name": "渝北区"
    }, {
      "adcode": "500113",
      "name": "巴南区"
    }, {
      "adcode": "500114",
      "name": "黔江区"
    }, {
      "adcode": "500115",
      "name": "长寿区"
    }, {
      "adcode": "500116",
      "name": "江津区"
    }, {
      "adcode": "500117",
      "name": "合川区"
    }, {
      "adcode": "500118",
      "name": "永川区"
    }, {
      "adcode": "500119",
      "name": "南川区"
    }, {
      "adcode": "500120",
      "name": "璧山区"
    }, {
      "adcode": "500151",
      "name": "铜梁区"
    }, {
      "adcode": "500152",
      "name": "潼南区"
    }, {
      "adcode": "500153",
      "name": "荣昌区"
    }, {
      "adcode": "500154",
      "name": "开州区"
    }, {
      "adcode": "500155",
      "name": "梁平区"
    }, {
      "adcode": "500156",
      "name": "武隆区"
    }]
  }, {
    "adcode": "500200",
    "name": "县",
    "districts": [{
      "adcode": "500229",
      "name": "城口县"
    }, {
      "adcode": "500230",
      "name": "丰都县"
    }, {
      "adcode": "500231",
      "name": "垫江县"
    }, {
      "adcode": "500233",
      "name": "忠县"
    }, {
      "adcode": "500235",
      "name": "云阳县"
    }, {
      "adcode": "500236",
      "name": "奉节县"
    }, {
      "adcode": "500237",
      "name": "巫山县"
    }, {
      "adcode": "500238",
      "name": "巫溪县"
    }, {
      "adcode": "500240",
      "name": "石柱土家族自治县"
    }, {
      "adcode": "500241",
      "name": "秀山土家族苗族自治县"
    }, {
      "adcode": "500242",
      "name": "酉阳土家族苗族自治县"
    }, {
      "adcode": "500243",
      "name": "彭水苗族土家族自治县"
    }]
  }]
}, {
  "adcode": "510000",
  "name": "四川省",
  "districts": [{
    "adcode": "510100",
    "name": "成都市",
    "districts": [{
      "adcode": "510104",
      "name": "锦江区"
    }, {
      "adcode": "510105",
      "name": "青羊区"
    }, {
      "adcode": "510106",
      "name": "金牛区"
    }, {
      "adcode": "510107",
      "name": "武侯区"
    }, {
      "adcode": "510108",
      "name": "成华区"
    }, {
      "adcode": "510112",
      "name": "龙泉驿区"
    }, {
      "adcode": "510113",
      "name": "青白江区"
    }, {
      "adcode": "510114",
      "name": "新都区"
    }, {
      "adcode": "510115",
      "name": "温江区"
    }, {
      "adcode": "510116",
      "name": "双流区"
    }, {
      "adcode": "510117",
      "name": "郫都区"
    }, {
      "adcode": "510121",
      "name": "金堂县"
    }, {
      "adcode": "510129",
      "name": "大邑县"
    }, {
      "adcode": "510131",
      "name": "蒲江县"
    }, {
      "adcode": "510132",
      "name": "新津县"
    }, {
      "adcode": "510181",
      "name": "都江堰市"
    }, {
      "adcode": "510182",
      "name": "彭州市"
    }, {
      "adcode": "510183",
      "name": "邛崃市"
    }, {
      "adcode": "510184",
      "name": "崇州市"
    }, {
      "adcode": "510185",
      "name": "简阳市"
    }]
  }, {
    "adcode": "510300",
    "name": "自贡市",
    "districts": [{
      "adcode": "510302",
      "name": "自流井区"
    }, {
      "adcode": "510303",
      "name": "贡井区"
    }, {
      "adcode": "510304",
      "name": "大安区"
    }, {
      "adcode": "510311",
      "name": "沿滩区"
    }, {
      "adcode": "510321",
      "name": "荣县"
    }, {
      "adcode": "510322",
      "name": "富顺县"
    }]
  }, {
    "adcode": "510400",
    "name": "攀枝花市",
    "districts": [{
      "adcode": "510402",
      "name": "东区"
    }, {
      "adcode": "510403",
      "name": "西区"
    }, {
      "adcode": "510411",
      "name": "仁和区"
    }, {
      "adcode": "510421",
      "name": "米易县"
    }, {
      "adcode": "510422",
      "name": "盐边县"
    }]
  }, {
    "adcode": "510500",
    "name": "泸州市",
    "districts": [{
      "adcode": "510502",
      "name": "江阳区"
    }, {
      "adcode": "510503",
      "name": "纳溪区"
    }, {
      "adcode": "510504",
      "name": "龙马潭区"
    }, {
      "adcode": "510521",
      "name": "泸县"
    }, {
      "adcode": "510522",
      "name": "合江县"
    }, {
      "adcode": "510524",
      "name": "叙永县"
    }, {
      "adcode": "510525",
      "name": "古蔺县"
    }]
  }, {
    "adcode": "510600",
    "name": "德阳市",
    "districts": [{
      "adcode": "510603",
      "name": "旌阳区"
    }, {
      "adcode": "510604",
      "name": "罗江区"
    }, {
      "adcode": "510623",
      "name": "中江县"
    }, {
      "adcode": "510681",
      "name": "广汉市"
    }, {
      "adcode": "510682",
      "name": "什邡市"
    }, {
      "adcode": "510683",
      "name": "绵竹市"
    }]
  }, {
    "adcode": "510700",
    "name": "绵阳市",
    "districts": [{
      "adcode": "510703",
      "name": "涪城区"
    }, {
      "adcode": "510704",
      "name": "游仙区"
    }, {
      "adcode": "510705",
      "name": "安州区"
    }, {
      "adcode": "510722",
      "name": "三台县"
    }, {
      "adcode": "510723",
      "name": "盐亭县"
    }, {
      "adcode": "510725",
      "name": "梓潼县"
    }, {
      "adcode": "510726",
      "name": "北川羌族自治县"
    }, {
      "adcode": "510727",
      "name": "平武县"
    }, {
      "adcode": "510781",
      "name": "江油市"
    }]
  }, {
    "adcode": "510800",
    "name": "广元市",
    "districts": [{
      "adcode": "510802",
      "name": "利州区"
    }, {
      "adcode": "510811",
      "name": "昭化区"
    }, {
      "adcode": "510812",
      "name": "朝天区"
    }, {
      "adcode": "510821",
      "name": "旺苍县"
    }, {
      "adcode": "510822",
      "name": "青川县"
    }, {
      "adcode": "510823",
      "name": "剑阁县"
    }, {
      "adcode": "510824",
      "name": "苍溪县"
    }]
  }, {
    "adcode": "510900",
    "name": "遂宁市",
    "districts": [{
      "adcode": "510903",
      "name": "船山区"
    }, {
      "adcode": "510904",
      "name": "安居区"
    }, {
      "adcode": "510921",
      "name": "蓬溪县"
    }, {
      "adcode": "510923",
      "name": "大英县"
    }, {
      "adcode": "510981",
      "name": "射洪市"
    }]
  }, {
    "adcode": "511000",
    "name": "内江市",
    "districts": [{
      "adcode": "511002",
      "name": "市中区"
    }, {
      "adcode": "511011",
      "name": "东兴区"
    }, {
      "adcode": "511024",
      "name": "威远县"
    }, {
      "adcode": "511025",
      "name": "资中县"
    }, {
      "adcode": "511083",
      "name": "隆昌市"
    }]
  }, {
    "adcode": "511100",
    "name": "乐山市",
    "districts": [{
      "adcode": "511102",
      "name": "市中区"
    }, {
      "adcode": "511111",
      "name": "沙湾区"
    }, {
      "adcode": "511112",
      "name": "五通桥区"
    }, {
      "adcode": "511113",
      "name": "金口河区"
    }, {
      "adcode": "511123",
      "name": "犍为县"
    }, {
      "adcode": "511124",
      "name": "井研县"
    }, {
      "adcode": "511126",
      "name": "夹江县"
    }, {
      "adcode": "511129",
      "name": "沐川县"
    }, {
      "adcode": "511132",
      "name": "峨边彝族自治县"
    }, {
      "adcode": "511133",
      "name": "马边彝族自治县"
    }, {
      "adcode": "511181",
      "name": "峨眉山市"
    }]
  }, {
    "adcode": "511300",
    "name": "南充市",
    "districts": [{
      "adcode": "511302",
      "name": "顺庆区"
    }, {
      "adcode": "511303",
      "name": "高坪区"
    }, {
      "adcode": "511304",
      "name": "嘉陵区"
    }, {
      "adcode": "511321",
      "name": "南部县"
    }, {
      "adcode": "511322",
      "name": "营山县"
    }, {
      "adcode": "511323",
      "name": "蓬安县"
    }, {
      "adcode": "511324",
      "name": "仪陇县"
    }, {
      "adcode": "511325",
      "name": "西充县"
    }, {
      "adcode": "511381",
      "name": "阆中市"
    }]
  }, {
    "adcode": "511400",
    "name": "眉山市",
    "districts": [{
      "adcode": "511402",
      "name": "东坡区"
    }, {
      "adcode": "511403",
      "name": "彭山区"
    }, {
      "adcode": "511421",
      "name": "仁寿县"
    }, {
      "adcode": "511423",
      "name": "洪雅县"
    }, {
      "adcode": "511424",
      "name": "丹棱县"
    }, {
      "adcode": "511425",
      "name": "青神县"
    }]
  }, {
    "adcode": "511500",
    "name": "宜宾市",
    "districts": [{
      "adcode": "511502",
      "name": "翠屏区"
    }, {
      "adcode": "511503",
      "name": "南溪区"
    }, {
      "adcode": "511504",
      "name": "叙州区"
    }, {
      "adcode": "511523",
      "name": "江安县"
    }, {
      "adcode": "511524",
      "name": "长宁县"
    }, {
      "adcode": "511525",
      "name": "高县"
    }, {
      "adcode": "511526",
      "name": "珙县"
    }, {
      "adcode": "511527",
      "name": "筠连县"
    }, {
      "adcode": "511528",
      "name": "兴文县"
    }, {
      "adcode": "511529",
      "name": "屏山县"
    }]
  }, {
    "adcode": "511600",
    "name": "广安市",
    "districts": [{
      "adcode": "511602",
      "name": "广安区"
    }, {
      "adcode": "511603",
      "name": "前锋区"
    }, {
      "adcode": "511621",
      "name": "岳池县"
    }, {
      "adcode": "511622",
      "name": "武胜县"
    }, {
      "adcode": "511623",
      "name": "邻水县"
    }, {
      "adcode": "511681",
      "name": "华蓥市"
    }]
  }, {
    "adcode": "511700",
    "name": "达州市",
    "districts": [{
      "adcode": "511702",
      "name": "通川区"
    }, {
      "adcode": "511703",
      "name": "达川区"
    }, {
      "adcode": "511722",
      "name": "宣汉县"
    }, {
      "adcode": "511723",
      "name": "开江县"
    }, {
      "adcode": "511724",
      "name": "大竹县"
    }, {
      "adcode": "511725",
      "name": "渠县"
    }, {
      "adcode": "511781",
      "name": "万源市"
    }]
  }, {
    "adcode": "511800",
    "name": "雅安市",
    "districts": [{
      "adcode": "511802",
      "name": "雨城区"
    }, {
      "adcode": "511803",
      "name": "名山区"
    }, {
      "adcode": "511822",
      "name": "荥经县"
    }, {
      "adcode": "511823",
      "name": "汉源县"
    }, {
      "adcode": "511824",
      "name": "石棉县"
    }, {
      "adcode": "511825",
      "name": "天全县"
    }, {
      "adcode": "511826",
      "name": "芦山县"
    }, {
      "adcode": "511827",
      "name": "宝兴县"
    }]
  }, {
    "adcode": "511900",
    "name": "巴中市",
    "districts": [{
      "adcode": "511902",
      "name": "巴州区"
    }, {
      "adcode": "511903",
      "name": "恩阳区"
    }, {
      "adcode": "511921",
      "name": "通江县"
    }, {
      "adcode": "511922",
      "name": "南江县"
    }, {
      "adcode": "511923",
      "name": "平昌县"
    }]
  }, {
    "adcode": "512000",
    "name": "资阳市",
    "districts": [{
      "adcode": "512002",
      "name": "雁江区"
    }, {
      "adcode": "512021",
      "name": "安岳县"
    }, {
      "adcode": "512022",
      "name": "乐至县"
    }]
  }, {
    "adcode": "513200",
    "name": "阿坝藏族羌族自治州",
    "districts": [{
      "adcode": "513201",
      "name": "马尔康市"
    }, {
      "adcode": "513221",
      "name": "汶川县"
    }, {
      "adcode": "513222",
      "name": "理县"
    }, {
      "adcode": "513223",
      "name": "茂县"
    }, {
      "adcode": "513224",
      "name": "松潘县"
    }, {
      "adcode": "513225",
      "name": "九寨沟县"
    }, {
      "adcode": "513226",
      "name": "金川县"
    }, {
      "adcode": "513227",
      "name": "小金县"
    }, {
      "adcode": "513228",
      "name": "黑水县"
    }, {
      "adcode": "513230",
      "name": "壤塘县"
    }, {
      "adcode": "513231",
      "name": "阿坝县"
    }, {
      "adcode": "513232",
      "name": "若尔盖县"
    }, {
      "adcode": "513233",
      "name": "红原县"
    }]
  }, {
    "adcode": "513300",
    "name": "甘孜藏族自治州",
    "districts": [{
      "adcode": "513301",
      "name": "康定市"
    }, {
      "adcode": "513322",
      "name": "泸定县"
    }, {
      "adcode": "513323",
      "name": "丹巴县"
    }, {
      "adcode": "513324",
      "name": "九龙县"
    }, {
      "adcode": "513325",
      "name": "雅江县"
    }, {
      "adcode": "513326",
      "name": "道孚县"
    }, {
      "adcode": "513327",
      "name": "炉霍县"
    }, {
      "adcode": "513328",
      "name": "甘孜县"
    }, {
      "adcode": "513329",
      "name": "新龙县"
    }, {
      "adcode": "513330",
      "name": "德格县"
    }, {
      "adcode": "513331",
      "name": "白玉县"
    }, {
      "adcode": "513332",
      "name": "石渠县"
    }, {
      "adcode": "513333",
      "name": "色达县"
    }, {
      "adcode": "513334",
      "name": "理塘县"
    }, {
      "adcode": "513335",
      "name": "巴塘县"
    }, {
      "adcode": "513336",
      "name": "乡城县"
    }, {
      "adcode": "513337",
      "name": "稻城县"
    }, {
      "adcode": "513338",
      "name": "得荣县"
    }]
  }, {
    "adcode": "513400",
    "name": "凉山彝族自治州",
    "districts": [{
      "adcode": "513401",
      "name": "西昌市"
    }, {
      "adcode": "513422",
      "name": "木里藏族自治县"
    }, {
      "adcode": "513423",
      "name": "盐源县"
    }, {
      "adcode": "513424",
      "name": "德昌县"
    }, {
      "adcode": "513425",
      "name": "会理县"
    }, {
      "adcode": "513426",
      "name": "会东县"
    }, {
      "adcode": "513427",
      "name": "宁南县"
    }, {
      "adcode": "513428",
      "name": "普格县"
    }, {
      "adcode": "513429",
      "name": "布拖县"
    }, {
      "adcode": "513430",
      "name": "金阳县"
    }, {
      "adcode": "513431",
      "name": "昭觉县"
    }, {
      "adcode": "513432",
      "name": "喜德县"
    }, {
      "adcode": "513433",
      "name": "冕宁县"
    }, {
      "adcode": "513434",
      "name": "越西县"
    }, {
      "adcode": "513435",
      "name": "甘洛县"
    }, {
      "adcode": "513436",
      "name": "美姑县"
    }, {
      "adcode": "513437",
      "name": "雷波县"
    }]
  }]
}, {
  "adcode": "520000",
  "name": "贵州省",
  "districts": [{
    "adcode": "520100",
    "name": "贵阳市",
    "districts": [{
      "adcode": "520102",
      "name": "南明区"
    }, {
      "adcode": "520103",
      "name": "云岩区"
    }, {
      "adcode": "520111",
      "name": "花溪区"
    }, {
      "adcode": "520112",
      "name": "乌当区"
    }, {
      "adcode": "520113",
      "name": "白云区"
    }, {
      "adcode": "520115",
      "name": "观山湖区"
    }, {
      "adcode": "520121",
      "name": "开阳县"
    }, {
      "adcode": "520122",
      "name": "息烽县"
    }, {
      "adcode": "520123",
      "name": "修文县"
    }, {
      "adcode": "520181",
      "name": "清镇市"
    }]
  }, {
    "adcode": "520200",
    "name": "六盘水市",
    "districts": [{
      "adcode": "520201",
      "name": "钟山区"
    }, {
      "adcode": "520203",
      "name": "六枝特区"
    }, {
      "adcode": "520221",
      "name": "水城县"
    }, {
      "adcode": "520281",
      "name": "盘州市"
    }]
  }, {
    "adcode": "520300",
    "name": "遵义市",
    "districts": [{
      "adcode": "520302",
      "name": "红花岗区"
    }, {
      "adcode": "520303",
      "name": "汇川区"
    }, {
      "adcode": "520304",
      "name": "播州区"
    }, {
      "adcode": "520322",
      "name": "桐梓县"
    }, {
      "adcode": "520323",
      "name": "绥阳县"
    }, {
      "adcode": "520324",
      "name": "正安县"
    }, {
      "adcode": "520325",
      "name": "道真仡佬族苗族自治县"
    }, {
      "adcode": "520326",
      "name": "务川仡佬族苗族自治县"
    }, {
      "adcode": "520327",
      "name": "凤冈县"
    }, {
      "adcode": "520328",
      "name": "湄潭县"
    }, {
      "adcode": "520329",
      "name": "余庆县"
    }, {
      "adcode": "520330",
      "name": "习水县"
    }, {
      "adcode": "520381",
      "name": "赤水市"
    }, {
      "adcode": "520382",
      "name": "仁怀市"
    }]
  }, {
    "adcode": "520400",
    "name": "安顺市",
    "districts": [{
      "adcode": "520402",
      "name": "西秀区"
    }, {
      "adcode": "520403",
      "name": "平坝区"
    }, {
      "adcode": "520422",
      "name": "普定县"
    }, {
      "adcode": "520423",
      "name": "镇宁布依族苗族自治县"
    }, {
      "adcode": "520424",
      "name": "关岭布依族苗族自治县"
    }, {
      "adcode": "520425",
      "name": "紫云苗族布依族自治县"
    }]
  }, {
    "adcode": "520500",
    "name": "毕节市",
    "districts": [{
      "adcode": "520502",
      "name": "七星关区"
    }, {
      "adcode": "520521",
      "name": "大方县"
    }, {
      "adcode": "520522",
      "name": "黔西县"
    }, {
      "adcode": "520523",
      "name": "金沙县"
    }, {
      "adcode": "520524",
      "name": "织金县"
    }, {
      "adcode": "520525",
      "name": "纳雍县"
    }, {
      "adcode": "520526",
      "name": "威宁彝族回族苗族自治县"
    }, {
      "adcode": "520527",
      "name": "赫章县"
    }]
  }, {
    "adcode": "520600",
    "name": "铜仁市",
    "districts": [{
      "adcode": "520602",
      "name": "碧江区"
    }, {
      "adcode": "520603",
      "name": "万山区"
    }, {
      "adcode": "520621",
      "name": "江口县"
    }, {
      "adcode": "520622",
      "name": "玉屏侗族自治县"
    }, {
      "adcode": "520623",
      "name": "石阡县"
    }, {
      "adcode": "520624",
      "name": "思南县"
    }, {
      "adcode": "520625",
      "name": "印江土家族苗族自治县"
    }, {
      "adcode": "520626",
      "name": "德江县"
    }, {
      "adcode": "520627",
      "name": "沿河土家族自治县"
    }, {
      "adcode": "520628",
      "name": "松桃苗族自治县"
    }]
  }, {
    "adcode": "522300",
    "name": "黔西南布依族苗族自治州",
    "districts": [{
      "adcode": "522301",
      "name": "兴义市"
    }, {
      "adcode": "522302",
      "name": "兴仁市"
    }, {
      "adcode": "522323",
      "name": "普安县"
    }, {
      "adcode": "522324",
      "name": "晴隆县"
    }, {
      "adcode": "522325",
      "name": "贞丰县"
    }, {
      "adcode": "522326",
      "name": "望谟县"
    }, {
      "adcode": "522327",
      "name": "册亨县"
    }, {
      "adcode": "522328",
      "name": "安龙县"
    }]
  }, {
    "adcode": "522600",
    "name": "黔东南苗族侗族自治州",
    "districts": [{
      "adcode": "522601",
      "name": "凯里市"
    }, {
      "adcode": "522622",
      "name": "黄平县"
    }, {
      "adcode": "522623",
      "name": "施秉县"
    }, {
      "adcode": "522624",
      "name": "三穗县"
    }, {
      "adcode": "522625",
      "name": "镇远县"
    }, {
      "adcode": "522626",
      "name": "岑巩县"
    }, {
      "adcode": "522627",
      "name": "天柱县"
    }, {
      "adcode": "522628",
      "name": "锦屏县"
    }, {
      "adcode": "522629",
      "name": "剑河县"
    }, {
      "adcode": "522630",
      "name": "台江县"
    }, {
      "adcode": "522631",
      "name": "黎平县"
    }, {
      "adcode": "522632",
      "name": "榕江县"
    }, {
      "adcode": "522633",
      "name": "从江县"
    }, {
      "adcode": "522634",
      "name": "雷山县"
    }, {
      "adcode": "522635",
      "name": "麻江县"
    }, {
      "adcode": "522636",
      "name": "丹寨县"
    }]
  }, {
    "adcode": "522700",
    "name": "黔南布依族苗族自治州",
    "districts": [{
      "adcode": "522701",
      "name": "都匀市"
    }, {
      "adcode": "522702",
      "name": "福泉市"
    }, {
      "adcode": "522722",
      "name": "荔波县"
    }, {
      "adcode": "522723",
      "name": "贵定县"
    }, {
      "adcode": "522725",
      "name": "瓮安县"
    }, {
      "adcode": "522726",
      "name": "独山县"
    }, {
      "adcode": "522727",
      "name": "平塘县"
    }, {
      "adcode": "522728",
      "name": "罗甸县"
    }, {
      "adcode": "522729",
      "name": "长顺县"
    }, {
      "adcode": "522730",
      "name": "龙里县"
    }, {
      "adcode": "522731",
      "name": "惠水县"
    }, {
      "adcode": "522732",
      "name": "三都水族自治县"
    }]
  }]
}, {
  "adcode": "530000",
  "name": "云南省",
  "districts": [{
    "adcode": "530100",
    "name": "昆明市",
    "districts": [{
      "adcode": "530102",
      "name": "五华区"
    }, {
      "adcode": "530103",
      "name": "盘龙区"
    }, {
      "adcode": "530111",
      "name": "官渡区"
    }, {
      "adcode": "530112",
      "name": "西山区"
    }, {
      "adcode": "530113",
      "name": "东川区"
    }, {
      "adcode": "530114",
      "name": "呈贡区"
    }, {
      "adcode": "530115",
      "name": "晋宁区"
    }, {
      "adcode": "530124",
      "name": "富民县"
    }, {
      "adcode": "530125",
      "name": "宜良县"
    }, {
      "adcode": "530126",
      "name": "石林彝族自治县"
    }, {
      "adcode": "530127",
      "name": "嵩明县"
    }, {
      "adcode": "530128",
      "name": "禄劝彝族苗族自治县"
    }, {
      "adcode": "530129",
      "name": "寻甸回族彝族自治县"
    }, {
      "adcode": "530181",
      "name": "安宁市"
    }]
  }, {
    "adcode": "530300",
    "name": "曲靖市",
    "districts": [{
      "adcode": "530302",
      "name": "麒麟区"
    }, {
      "adcode": "530303",
      "name": "沾益区"
    }, {
      "adcode": "530304",
      "name": "马龙区"
    }, {
      "adcode": "530322",
      "name": "陆良县"
    }, {
      "adcode": "530323",
      "name": "师宗县"
    }, {
      "adcode": "530324",
      "name": "罗平县"
    }, {
      "adcode": "530325",
      "name": "富源县"
    }, {
      "adcode": "530326",
      "name": "会泽县"
    }, {
      "adcode": "530381",
      "name": "宣威市"
    }]
  }, {
    "adcode": "530400",
    "name": "玉溪市",
    "districts": [{
      "adcode": "530402",
      "name": "红塔区"
    }, {
      "adcode": "530403",
      "name": "江川区"
    }, {
      "adcode": "530423",
      "name": "通海县"
    }, {
      "adcode": "530424",
      "name": "华宁县"
    }, {
      "adcode": "530425",
      "name": "易门县"
    }, {
      "adcode": "530426",
      "name": "峨山彝族自治县"
    }, {
      "adcode": "530427",
      "name": "新平彝族傣族自治县"
    }, {
      "adcode": "530428",
      "name": "元江哈尼族彝族傣族自治县"
    }, {
      "adcode": "530481",
      "name": "澄江市"
    }]
  }, {
    "adcode": "530500",
    "name": "保山市",
    "districts": [{
      "adcode": "530502",
      "name": "隆阳区"
    }, {
      "adcode": "530521",
      "name": "施甸县"
    }, {
      "adcode": "530523",
      "name": "龙陵县"
    }, {
      "adcode": "530524",
      "name": "昌宁县"
    }, {
      "adcode": "530581",
      "name": "腾冲市"
    }]
  }, {
    "adcode": "530600",
    "name": "昭通市",
    "districts": [{
      "adcode": "530602",
      "name": "昭阳区"
    }, {
      "adcode": "530621",
      "name": "鲁甸县"
    }, {
      "adcode": "530622",
      "name": "巧家县"
    }, {
      "adcode": "530623",
      "name": "盐津县"
    }, {
      "adcode": "530624",
      "name": "大关县"
    }, {
      "adcode": "530625",
      "name": "永善县"
    }, {
      "adcode": "530626",
      "name": "绥江县"
    }, {
      "adcode": "530627",
      "name": "镇雄县"
    }, {
      "adcode": "530628",
      "name": "彝良县"
    }, {
      "adcode": "530629",
      "name": "威信县"
    }, {
      "adcode": "530681",
      "name": "水富市"
    }]
  }, {
    "adcode": "530700",
    "name": "丽江市",
    "districts": [{
      "adcode": "530702",
      "name": "古城区"
    }, {
      "adcode": "530721",
      "name": "玉龙纳西族自治县"
    }, {
      "adcode": "530722",
      "name": "永胜县"
    }, {
      "adcode": "530723",
      "name": "华坪县"
    }, {
      "adcode": "530724",
      "name": "宁蒗彝族自治县"
    }]
  }, {
    "adcode": "530800",
    "name": "普洱市",
    "districts": [{
      "adcode": "530802",
      "name": "思茅区"
    }, {
      "adcode": "530821",
      "name": "宁洱哈尼族彝族自治县"
    }, {
      "adcode": "530822",
      "name": "墨江哈尼族自治县"
    }, {
      "adcode": "530823",
      "name": "景东彝族自治县"
    }, {
      "adcode": "530824",
      "name": "景谷傣族彝族自治县"
    }, {
      "adcode": "530825",
      "name": "镇沅彝族哈尼族拉祜族自治县"
    }, {
      "adcode": "530826",
      "name": "江城哈尼族彝族自治县"
    }, {
      "adcode": "530827",
      "name": "孟连傣族拉祜族佤族自治县"
    }, {
      "adcode": "530828",
      "name": "澜沧拉祜族自治县"
    }, {
      "adcode": "530829",
      "name": "西盟佤族自治县"
    }]
  }, {
    "adcode": "530900",
    "name": "临沧市",
    "districts": [{
      "adcode": "530902",
      "name": "临翔区"
    }, {
      "adcode": "530921",
      "name": "凤庆县"
    }, {
      "adcode": "530922",
      "name": "云县"
    }, {
      "adcode": "530923",
      "name": "永德县"
    }, {
      "adcode": "530924",
      "name": "镇康县"
    }, {
      "adcode": "530925",
      "name": "双江拉祜族佤族布朗族傣族自治县"
    }, {
      "adcode": "530926",
      "name": "耿马傣族佤族自治县"
    }, {
      "adcode": "530927",
      "name": "沧源佤族自治县"
    }]
  }, {
    "adcode": "532300",
    "name": "楚雄彝族自治州",
    "districts": [{
      "adcode": "532301",
      "name": "楚雄市"
    }, {
      "adcode": "532322",
      "name": "双柏县"
    }, {
      "adcode": "532323",
      "name": "牟定县"
    }, {
      "adcode": "532324",
      "name": "南华县"
    }, {
      "adcode": "532325",
      "name": "姚安县"
    }, {
      "adcode": "532326",
      "name": "大姚县"
    }, {
      "adcode": "532327",
      "name": "永仁县"
    }, {
      "adcode": "532328",
      "name": "元谋县"
    }, {
      "adcode": "532329",
      "name": "武定县"
    }, {
      "adcode": "532331",
      "name": "禄丰县"
    }]
  }, {
    "adcode": "532500",
    "name": "红河哈尼族彝族自治州",
    "districts": [{
      "adcode": "532501",
      "name": "个旧市"
    }, {
      "adcode": "532502",
      "name": "开远市"
    }, {
      "adcode": "532503",
      "name": "蒙自市"
    }, {
      "adcode": "532504",
      "name": "弥勒市"
    }, {
      "adcode": "532523",
      "name": "屏边苗族自治县"
    }, {
      "adcode": "532524",
      "name": "建水县"
    }, {
      "adcode": "532525",
      "name": "石屏县"
    }, {
      "adcode": "532527",
      "name": "泸西县"
    }, {
      "adcode": "532528",
      "name": "元阳县"
    }, {
      "adcode": "532529",
      "name": "红河县"
    }, {
      "adcode": "532530",
      "name": "金平苗族瑶族傣族自治县"
    }, {
      "adcode": "532531",
      "name": "绿春县"
    }, {
      "adcode": "532532",
      "name": "河口瑶族自治县"
    }]
  }, {
    "adcode": "532600",
    "name": "文山壮族苗族自治州",
    "districts": [{
      "adcode": "532601",
      "name": "文山市"
    }, {
      "adcode": "532622",
      "name": "砚山县"
    }, {
      "adcode": "532623",
      "name": "西畴县"
    }, {
      "adcode": "532624",
      "name": "麻栗坡县"
    }, {
      "adcode": "532625",
      "name": "马关县"
    }, {
      "adcode": "532626",
      "name": "丘北县"
    }, {
      "adcode": "532627",
      "name": "广南县"
    }, {
      "adcode": "532628",
      "name": "富宁县"
    }]
  }, {
    "adcode": "532800",
    "name": "西双版纳傣族自治州",
    "districts": [{
      "adcode": "532801",
      "name": "景洪市"
    }, {
      "adcode": "532822",
      "name": "勐海县"
    }, {
      "adcode": "532823",
      "name": "勐腊县"
    }]
  }, {
    "adcode": "532900",
    "name": "大理白族自治州",
    "districts": [{
      "adcode": "532901",
      "name": "大理市"
    }, {
      "adcode": "532922",
      "name": "漾濞彝族自治县"
    }, {
      "adcode": "532923",
      "name": "祥云县"
    }, {
      "adcode": "532924",
      "name": "宾川县"
    }, {
      "adcode": "532925",
      "name": "弥渡县"
    }, {
      "adcode": "532926",
      "name": "南涧彝族自治县"
    }, {
      "adcode": "532927",
      "name": "巍山彝族回族自治县"
    }, {
      "adcode": "532928",
      "name": "永平县"
    }, {
      "adcode": "532929",
      "name": "云龙县"
    }, {
      "adcode": "532930",
      "name": "洱源县"
    }, {
      "adcode": "532931",
      "name": "剑川县"
    }, {
      "adcode": "532932",
      "name": "鹤庆县"
    }]
  }, {
    "adcode": "533100",
    "name": "德宏傣族景颇族自治州",
    "districts": [{
      "adcode": "533102",
      "name": "瑞丽市"
    }, {
      "adcode": "533103",
      "name": "芒市"
    }, {
      "adcode": "533122",
      "name": "梁河县"
    }, {
      "adcode": "533123",
      "name": "盈江县"
    }, {
      "adcode": "533124",
      "name": "陇川县"
    }]
  }, {
    "adcode": "533300",
    "name": "怒江傈僳族自治州",
    "districts": [{
      "adcode": "533301",
      "name": "泸水市"
    }, {
      "adcode": "533323",
      "name": "福贡县"
    }, {
      "adcode": "533324",
      "name": "贡山独龙族怒族自治县"
    }, {
      "adcode": "533325",
      "name": "兰坪白族普米族自治县"
    }]
  }, {
    "adcode": "533400",
    "name": "迪庆藏族自治州",
    "districts": [{
      "adcode": "533401",
      "name": "香格里拉市"
    }, {
      "adcode": "533422",
      "name": "德钦县"
    }, {
      "adcode": "533423",
      "name": "维西傈僳族自治县"
    }]
  }]
}, {
  "adcode": "540000",
  "name": "西藏自治区",
  "districts": [{
    "adcode": "540100",
    "name": "拉萨市",
    "districts": [{
      "adcode": "540102",
      "name": "城关区"
    }, {
      "adcode": "540103",
      "name": "堆龙德庆区"
    }, {
      "adcode": "540104",
      "name": "达孜区"
    }, {
      "adcode": "540121",
      "name": "林周县"
    }, {
      "adcode": "540122",
      "name": "当雄县"
    }, {
      "adcode": "540123",
      "name": "尼木县"
    }, {
      "adcode": "540124",
      "name": "曲水县"
    }, {
      "adcode": "540127",
      "name": "墨竹工卡县"
    }]
  }, {
    "adcode": "540200",
    "name": "日喀则市",
    "districts": [{
      "adcode": "540202",
      "name": "桑珠孜区"
    }, {
      "adcode": "540221",
      "name": "南木林县"
    }, {
      "adcode": "540222",
      "name": "江孜县"
    }, {
      "adcode": "540223",
      "name": "定日县"
    }, {
      "adcode": "540224",
      "name": "萨迦县"
    }, {
      "adcode": "540225",
      "name": "拉孜县"
    }, {
      "adcode": "540226",
      "name": "昂仁县"
    }, {
      "adcode": "540227",
      "name": "谢通门县"
    }, {
      "adcode": "540228",
      "name": "白朗县"
    }, {
      "adcode": "540229",
      "name": "仁布县"
    }, {
      "adcode": "540230",
      "name": "康马县"
    }, {
      "adcode": "540231",
      "name": "定结县"
    }, {
      "adcode": "540232",
      "name": "仲巴县"
    }, {
      "adcode": "540233",
      "name": "亚东县"
    }, {
      "adcode": "540234",
      "name": "吉隆县"
    }, {
      "adcode": "540235",
      "name": "聂拉木县"
    }, {
      "adcode": "540236",
      "name": "萨嘎县"
    }, {
      "adcode": "540237",
      "name": "岗巴县"
    }]
  }, {
    "adcode": "540300",
    "name": "昌都市",
    "districts": [{
      "adcode": "540302",
      "name": "卡若区"
    }, {
      "adcode": "540321",
      "name": "江达县"
    }, {
      "adcode": "540322",
      "name": "贡觉县"
    }, {
      "adcode": "540323",
      "name": "类乌齐县"
    }, {
      "adcode": "540324",
      "name": "丁青县"
    }, {
      "adcode": "540325",
      "name": "察雅县"
    }, {
      "adcode": "540326",
      "name": "八宿县"
    }, {
      "adcode": "540327",
      "name": "左贡县"
    }, {
      "adcode": "540328",
      "name": "芒康县"
    }, {
      "adcode": "540329",
      "name": "洛隆县"
    }, {
      "adcode": "540330",
      "name": "边坝县"
    }]
  }, {
    "adcode": "540400",
    "name": "林芝市",
    "districts": [{
      "adcode": "540402",
      "name": "巴宜区"
    }, {
      "adcode": "540421",
      "name": "工布江达县"
    }, {
      "adcode": "540422",
      "name": "米林县"
    }, {
      "adcode": "540423",
      "name": "墨脱县"
    }, {
      "adcode": "540424",
      "name": "波密县"
    }, {
      "adcode": "540425",
      "name": "察隅县"
    }, {
      "adcode": "540426",
      "name": "朗县"
    }]
  }, {
    "adcode": "540500",
    "name": "山南市",
    "districts": [{
      "adcode": "540502",
      "name": "乃东区"
    }, {
      "adcode": "540521",
      "name": "扎囊县"
    }, {
      "adcode": "540522",
      "name": "贡嘎县"
    }, {
      "adcode": "540523",
      "name": "桑日县"
    }, {
      "adcode": "540524",
      "name": "琼结县"
    }, {
      "adcode": "540525",
      "name": "曲松县"
    }, {
      "adcode": "540526",
      "name": "措美县"
    }, {
      "adcode": "540527",
      "name": "洛扎县"
    }, {
      "adcode": "540528",
      "name": "加查县"
    }, {
      "adcode": "540529",
      "name": "隆子县"
    }, {
      "adcode": "540530",
      "name": "错那县"
    }, {
      "adcode": "540531",
      "name": "浪卡子县"
    }]
  }, {
    "adcode": "540600",
    "name": "那曲市",
    "districts": [{
      "adcode": "540602",
      "name": "色尼区"
    }, {
      "adcode": "540621",
      "name": "嘉黎县"
    }, {
      "adcode": "540622",
      "name": "比如县"
    }, {
      "adcode": "540623",
      "name": "聂荣县"
    }, {
      "adcode": "540624",
      "name": "安多县"
    }, {
      "adcode": "540625",
      "name": "申扎县"
    }, {
      "adcode": "540626",
      "name": "索县"
    }, {
      "adcode": "540627",
      "name": "班戈县"
    }, {
      "adcode": "540628",
      "name": "巴青县"
    }, {
      "adcode": "540629",
      "name": "尼玛县"
    }, {
      "adcode": "540630",
      "name": "双湖县"
    }]
  }, {
    "adcode": "542500",
    "name": "阿里地区",
    "districts": [{
      "adcode": "542521",
      "name": "普兰县"
    }, {
      "adcode": "542522",
      "name": "札达县"
    }, {
      "adcode": "542523",
      "name": "噶尔县"
    }, {
      "adcode": "542524",
      "name": "日土县"
    }, {
      "adcode": "542525",
      "name": "革吉县"
    }, {
      "adcode": "542526",
      "name": "改则县"
    }, {
      "adcode": "542527",
      "name": "措勤县"
    }]
  }]
}, {
  "adcode": "610000",
  "name": "陕西省",
  "districts": [{
    "adcode": "610100",
    "name": "西安市",
    "districts": [{
      "adcode": "610102",
      "name": "新城区"
    }, {
      "adcode": "610103",
      "name": "碑林区"
    }, {
      "adcode": "610104",
      "name": "莲湖区"
    }, {
      "adcode": "610111",
      "name": "灞桥区"
    }, {
      "adcode": "610112",
      "name": "未央区"
    }, {
      "adcode": "610113",
      "name": "雁塔区"
    }, {
      "adcode": "610114",
      "name": "阎良区"
    }, {
      "adcode": "610115",
      "name": "临潼区"
    }, {
      "adcode": "610116",
      "name": "长安区"
    }, {
      "adcode": "610117",
      "name": "高陵区"
    }, {
      "adcode": "610118",
      "name": "鄠邑区"
    }, {
      "adcode": "610122",
      "name": "蓝田县"
    }, {
      "adcode": "610124",
      "name": "周至县"
    }]
  }, {
    "adcode": "610200",
    "name": "铜川市",
    "districts": [{
      "adcode": "610202",
      "name": "王益区"
    }, {
      "adcode": "610203",
      "name": "印台区"
    }, {
      "adcode": "610204",
      "name": "耀州区"
    }, {
      "adcode": "610222",
      "name": "宜君县"
    }]
  }, {
    "adcode": "610300",
    "name": "宝鸡市",
    "districts": [{
      "adcode": "610302",
      "name": "渭滨区"
    }, {
      "adcode": "610303",
      "name": "金台区"
    }, {
      "adcode": "610304",
      "name": "陈仓区"
    }, {
      "adcode": "610322",
      "name": "凤翔县"
    }, {
      "adcode": "610323",
      "name": "岐山县"
    }, {
      "adcode": "610324",
      "name": "扶风县"
    }, {
      "adcode": "610326",
      "name": "眉县"
    }, {
      "adcode": "610327",
      "name": "陇县"
    }, {
      "adcode": "610328",
      "name": "千阳县"
    }, {
      "adcode": "610329",
      "name": "麟游县"
    }, {
      "adcode": "610330",
      "name": "凤县"
    }, {
      "adcode": "610331",
      "name": "太白县"
    }]
  }, {
    "adcode": "610400",
    "name": "咸阳市",
    "districts": [{
      "adcode": "610402",
      "name": "秦都区"
    }, {
      "adcode": "610403",
      "name": "杨陵区"
    }, {
      "adcode": "610404",
      "name": "渭城区"
    }, {
      "adcode": "610422",
      "name": "三原县"
    }, {
      "adcode": "610423",
      "name": "泾阳县"
    }, {
      "adcode": "610424",
      "name": "乾县"
    }, {
      "adcode": "610425",
      "name": "礼泉县"
    }, {
      "adcode": "610426",
      "name": "永寿县"
    }, {
      "adcode": "610482",
      "name": "彬州市"
    }, {
      "adcode": "610428",
      "name": "长武县"
    }, {
      "adcode": "610429",
      "name": "旬邑县"
    }, {
      "adcode": "610430",
      "name": "淳化县"
    }, {
      "adcode": "610431",
      "name": "武功县"
    }, {
      "adcode": "610481",
      "name": "兴平市"
    }]
  }, {
    "adcode": "610500",
    "name": "渭南市",
    "districts": [{
      "adcode": "610502",
      "name": "临渭区"
    }, {
      "adcode": "610503",
      "name": "华州区"
    }, {
      "adcode": "610522",
      "name": "潼关县"
    }, {
      "adcode": "610523",
      "name": "大荔县"
    }, {
      "adcode": "610524",
      "name": "合阳县"
    }, {
      "adcode": "610525",
      "name": "澄城县"
    }, {
      "adcode": "610526",
      "name": "蒲城县"
    }, {
      "adcode": "610527",
      "name": "白水县"
    }, {
      "adcode": "610528",
      "name": "富平县"
    }, {
      "adcode": "610581",
      "name": "韩城市"
    }, {
      "adcode": "610582",
      "name": "华阴市"
    }]
  }, {
    "adcode": "610600",
    "name": "延安市",
    "districts": [{
      "adcode": "610602",
      "name": "宝塔区"
    }, {
      "adcode": "610603",
      "name": "安塞区"
    }, {
      "adcode": "610621",
      "name": "延长县"
    }, {
      "adcode": "610622",
      "name": "延川县"
    }, {
      "adcode": "610625",
      "name": "志丹县"
    }, {
      "adcode": "610626",
      "name": "吴起县"
    }, {
      "adcode": "610627",
      "name": "甘泉县"
    }, {
      "adcode": "610628",
      "name": "富县"
    }, {
      "adcode": "610629",
      "name": "洛川县"
    }, {
      "adcode": "610630",
      "name": "宜川县"
    }, {
      "adcode": "610631",
      "name": "黄龙县"
    }, {
      "adcode": "610632",
      "name": "黄陵县"
    }, {
      "adcode": "610681",
      "name": "子长市"
    }]
  }, {
    "adcode": "610700",
    "name": "汉中市",
    "districts": [{
      "adcode": "610702",
      "name": "汉台区"
    }, {
      "adcode": "610703",
      "name": "南郑区"
    }, {
      "adcode": "610722",
      "name": "城固县"
    }, {
      "adcode": "610723",
      "name": "洋县"
    }, {
      "adcode": "610724",
      "name": "西乡县"
    }, {
      "adcode": "610725",
      "name": "勉县"
    }, {
      "adcode": "610726",
      "name": "宁强县"
    }, {
      "adcode": "610727",
      "name": "略阳县"
    }, {
      "adcode": "610728",
      "name": "镇巴县"
    }, {
      "adcode": "610729",
      "name": "留坝县"
    }, {
      "adcode": "610730",
      "name": "佛坪县"
    }]
  }, {
    "adcode": "610800",
    "name": "榆林市",
    "districts": [{
      "adcode": "610802",
      "name": "榆阳区"
    }, {
      "adcode": "610803",
      "name": "横山区"
    }, {
      "adcode": "610822",
      "name": "府谷县"
    }, {
      "adcode": "610824",
      "name": "靖边县"
    }, {
      "adcode": "610825",
      "name": "定边县"
    }, {
      "adcode": "610826",
      "name": "绥德县"
    }, {
      "adcode": "610827",
      "name": "米脂县"
    }, {
      "adcode": "610828",
      "name": "佳县"
    }, {
      "adcode": "610829",
      "name": "吴堡县"
    }, {
      "adcode": "610830",
      "name": "清涧县"
    }, {
      "adcode": "610831",
      "name": "子洲县"
    }, {
      "adcode": "610881",
      "name": "神木市"
    }]
  }, {
    "adcode": "610900",
    "name": "安康市",
    "districts": [{
      "adcode": "610902",
      "name": "汉滨区"
    }, {
      "adcode": "610921",
      "name": "汉阴县"
    }, {
      "adcode": "610922",
      "name": "石泉县"
    }, {
      "adcode": "610923",
      "name": "宁陕县"
    }, {
      "adcode": "610924",
      "name": "紫阳县"
    }, {
      "adcode": "610925",
      "name": "岚皋县"
    }, {
      "adcode": "610926",
      "name": "平利县"
    }, {
      "adcode": "610927",
      "name": "镇坪县"
    }, {
      "adcode": "610928",
      "name": "旬阳县"
    }, {
      "adcode": "610929",
      "name": "白河县"
    }]
  }, {
    "adcode": "611000",
    "name": "商洛市",
    "districts": [{
      "adcode": "611002",
      "name": "商州区"
    }, {
      "adcode": "611021",
      "name": "洛南县"
    }, {
      "adcode": "611022",
      "name": "丹凤县"
    }, {
      "adcode": "611023",
      "name": "商南县"
    }, {
      "adcode": "611024",
      "name": "山阳县"
    }, {
      "adcode": "611025",
      "name": "镇安县"
    }, {
      "adcode": "611026",
      "name": "柞水县"
    }]
  }]
}, {
  "adcode": "620000",
  "name": "甘肃省",
  "districts": [{
    "adcode": "620100",
    "name": "兰州市",
    "districts": [{
      "adcode": "620102",
      "name": "城关区"
    }, {
      "adcode": "620103",
      "name": "七里河区"
    }, {
      "adcode": "620104",
      "name": "西固区"
    }, {
      "adcode": "620105",
      "name": "安宁区"
    }, {
      "adcode": "620111",
      "name": "红古区"
    }, {
      "adcode": "620121",
      "name": "永登县"
    }, {
      "adcode": "620122",
      "name": "皋兰县"
    }, {
      "adcode": "620123",
      "name": "榆中县"
    }]
  }, {
    "adcode": "620200",
    "name": "嘉峪关市",
    "districts": [{
      "adcode": "620201",
      "name": "雄关区"
    }, {
      "adcode": "620202",
      "name": "镜铁区"
    }, {
      "adcode": "620203",
      "name": "长城区"
    }, {
      "adcode": "620204",
      "name": "新城镇"
    }, {
      "adcode": "620205",
      "name": "峪泉镇"
    }, {
      "adcode": "620206",
      "name": "文殊镇"
    }]
  }, {
    "adcode": "620300",
    "name": "金昌市",
    "districts": [{
      "adcode": "620302",
      "name": "金川区"
    }, {
      "adcode": "620321",
      "name": "永昌县"
    }]
  }, {
    "adcode": "620400",
    "name": "白银市",
    "districts": [{
      "adcode": "620402",
      "name": "白银区"
    }, {
      "adcode": "620403",
      "name": "平川区"
    }, {
      "adcode": "620421",
      "name": "靖远县"
    }, {
      "adcode": "620422",
      "name": "会宁县"
    }, {
      "adcode": "620423",
      "name": "景泰县"
    }]
  }, {
    "adcode": "620500",
    "name": "天水市",
    "districts": [{
      "adcode": "620502",
      "name": "秦州区"
    }, {
      "adcode": "620503",
      "name": "麦积区"
    }, {
      "adcode": "620521",
      "name": "清水县"
    }, {
      "adcode": "620522",
      "name": "秦安县"
    }, {
      "adcode": "620523",
      "name": "甘谷县"
    }, {
      "adcode": "620524",
      "name": "武山县"
    }, {
      "adcode": "620525",
      "name": "张家川回族自治县"
    }]
  }, {
    "adcode": "620600",
    "name": "武威市",
    "districts": [{
      "adcode": "620602",
      "name": "凉州区"
    }, {
      "adcode": "620621",
      "name": "民勤县"
    }, {
      "adcode": "620622",
      "name": "古浪县"
    }, {
      "adcode": "620623",
      "name": "天祝藏族自治县"
    }]
  }, {
    "adcode": "620700",
    "name": "张掖市",
    "districts": [{
      "adcode": "620702",
      "name": "甘州区"
    }, {
      "adcode": "620721",
      "name": "肃南裕固族自治县"
    }, {
      "adcode": "620722",
      "name": "民乐县"
    }, {
      "adcode": "620723",
      "name": "临泽县"
    }, {
      "adcode": "620724",
      "name": "高台县"
    }, {
      "adcode": "620725",
      "name": "山丹县"
    }]
  }, {
    "adcode": "620800",
    "name": "平凉市",
    "districts": [{
      "adcode": "620802",
      "name": "崆峒区"
    }, {
      "adcode": "620821",
      "name": "泾川县"
    }, {
      "adcode": "620822",
      "name": "灵台县"
    }, {
      "adcode": "620823",
      "name": "崇信县"
    }, {
      "adcode": "620825",
      "name": "庄浪县"
    }, {
      "adcode": "620826",
      "name": "静宁县"
    }, {
      "adcode": "620881",
      "name": "华亭市"
    }]
  }, {
    "adcode": "620900",
    "name": "酒泉市",
    "districts": [{
      "adcode": "620902",
      "name": "肃州区"
    }, {
      "adcode": "620921",
      "name": "金塔县"
    }, {
      "adcode": "620922",
      "name": "瓜州县"
    }, {
      "adcode": "620923",
      "name": "肃北蒙古族自治县"
    }, {
      "adcode": "620924",
      "name": "阿克塞哈萨克族自治县"
    }, {
      "adcode": "620981",
      "name": "玉门市"
    }, {
      "adcode": "620982",
      "name": "敦煌市"
    }]
  }, {
    "adcode": "621000",
    "name": "庆阳市",
    "districts": [{
      "adcode": "621002",
      "name": "西峰区"
    }, {
      "adcode": "621021",
      "name": "庆城县"
    }, {
      "adcode": "621022",
      "name": "环县"
    }, {
      "adcode": "621023",
      "name": "华池县"
    }, {
      "adcode": "621024",
      "name": "合水县"
    }, {
      "adcode": "621025",
      "name": "正宁县"
    }, {
      "adcode": "621026",
      "name": "宁县"
    }, {
      "adcode": "621027",
      "name": "镇原县"
    }]
  }, {
    "adcode": "621100",
    "name": "定西市",
    "districts": [{
      "adcode": "621102",
      "name": "安定区"
    }, {
      "adcode": "621121",
      "name": "通渭县"
    }, {
      "adcode": "621122",
      "name": "陇西县"
    }, {
      "adcode": "621123",
      "name": "渭源县"
    }, {
      "adcode": "621124",
      "name": "临洮县"
    }, {
      "adcode": "621125",
      "name": "漳县"
    }, {
      "adcode": "621126",
      "name": "岷县"
    }]
  }, {
    "adcode": "621200",
    "name": "陇南市",
    "districts": [{
      "adcode": "621202",
      "name": "武都区"
    }, {
      "adcode": "621221",
      "name": "成县"
    }, {
      "adcode": "621222",
      "name": "文县"
    }, {
      "adcode": "621223",
      "name": "宕昌县"
    }, {
      "adcode": "621224",
      "name": "康县"
    }, {
      "adcode": "621225",
      "name": "西和县"
    }, {
      "adcode": "621226",
      "name": "礼县"
    }, {
      "adcode": "621227",
      "name": "徽县"
    }, {
      "adcode": "621228",
      "name": "两当县"
    }]
  }, {
    "adcode": "622900",
    "name": "临夏回族自治州",
    "districts": [{
      "adcode": "622901",
      "name": "临夏市"
    }, {
      "adcode": "622921",
      "name": "临夏县"
    }, {
      "adcode": "622922",
      "name": "康乐县"
    }, {
      "adcode": "622923",
      "name": "永靖县"
    }, {
      "adcode": "622924",
      "name": "广河县"
    }, {
      "adcode": "622925",
      "name": "和政县"
    }, {
      "adcode": "622926",
      "name": "东乡族自治县"
    }, {
      "adcode": "622927",
      "name": "积石山保安族东乡族撒拉族自治县"
    }]
  }, {
    "adcode": "623000",
    "name": "甘南藏族自治州",
    "districts": [{
      "adcode": "623001",
      "name": "合作市"
    }, {
      "adcode": "623021",
      "name": "临潭县"
    }, {
      "adcode": "623022",
      "name": "卓尼县"
    }, {
      "adcode": "623023",
      "name": "舟曲县"
    }, {
      "adcode": "623024",
      "name": "迭部县"
    }, {
      "adcode": "623025",
      "name": "玛曲县"
    }, {
      "adcode": "623026",
      "name": "碌曲县"
    }, {
      "adcode": "623027",
      "name": "夏河县"
    }]
  }]
}, {
  "adcode": "630000",
  "name": "青海省",
  "districts": [{
    "adcode": "630100",
    "name": "西宁市",
    "districts": [{
      "adcode": "630102",
      "name": "城东区"
    }, {
      "adcode": "630103",
      "name": "城中区"
    }, {
      "adcode": "630104",
      "name": "城西区"
    }, {
      "adcode": "630105",
      "name": "城北区"
    }, {
      "adcode": "630106",
      "name": "湟中区"
    }, {
      "adcode": "630121",
      "name": "大通回族土族自治县"
    }, {
      "adcode": "630123",
      "name": "湟源县"
    }]
  }, {
    "adcode": "630200",
    "name": "海东市",
    "districts": [{
      "adcode": "630202",
      "name": "乐都区"
    }, {
      "adcode": "630203",
      "name": "平安区"
    }, {
      "adcode": "630222",
      "name": "民和回族土族自治县"
    }, {
      "adcode": "630223",
      "name": "互助土族自治县"
    }, {
      "adcode": "630224",
      "name": "化隆回族自治县"
    }, {
      "adcode": "630225",
      "name": "循化撒拉族自治县"
    }]
  }, {
    "adcode": "632200",
    "name": "海北藏族自治州",
    "districts": [{
      "adcode": "632221",
      "name": "门源回族自治县"
    }, {
      "adcode": "632222",
      "name": "祁连县"
    }, {
      "adcode": "632223",
      "name": "海晏县"
    }, {
      "adcode": "632224",
      "name": "刚察县"
    }]
  }, {
    "adcode": "632300",
    "name": "黄南藏族自治州",
    "districts": [{
      "adcode": "632321",
      "name": "同仁县"
    }, {
      "adcode": "632322",
      "name": "尖扎县"
    }, {
      "adcode": "632323",
      "name": "泽库县"
    }, {
      "adcode": "632324",
      "name": "河南蒙古族自治县"
    }]
  }, {
    "adcode": "632500",
    "name": "海南藏族自治州",
    "districts": [{
      "adcode": "632521",
      "name": "共和县"
    }, {
      "adcode": "632522",
      "name": "同德县"
    }, {
      "adcode": "632523",
      "name": "贵德县"
    }, {
      "adcode": "632524",
      "name": "兴海县"
    }, {
      "adcode": "632525",
      "name": "贵南县"
    }]
  }, {
    "adcode": "632600",
    "name": "果洛藏族自治州",
    "districts": [{
      "adcode": "632621",
      "name": "玛沁县"
    }, {
      "adcode": "632622",
      "name": "班玛县"
    }, {
      "adcode": "632623",
      "name": "甘德县"
    }, {
      "adcode": "632624",
      "name": "达日县"
    }, {
      "adcode": "632625",
      "name": "久治县"
    }, {
      "adcode": "632626",
      "name": "玛多县"
    }]
  }, {
    "adcode": "632700",
    "name": "玉树藏族自治州",
    "districts": [{
      "adcode": "632701",
      "name": "玉树市"
    }, {
      "adcode": "632722",
      "name": "杂多县"
    }, {
      "adcode": "632723",
      "name": "称多县"
    }, {
      "adcode": "632724",
      "name": "治多县"
    }, {
      "adcode": "632725",
      "name": "囊谦县"
    }, {
      "adcode": "632726",
      "name": "曲麻莱县"
    }]
  }, {
    "adcode": "632800",
    "name": "海西蒙古族藏族自治州",
    "districts": [{
      "adcode": "632801",
      "name": "格尔木市"
    }, {
      "adcode": "632802",
      "name": "德令哈市"
    }, {
      "adcode": "632803",
      "name": "茫崖市"
    }, {
      "adcode": "632821",
      "name": "乌兰县"
    }, {
      "adcode": "632822",
      "name": "都兰县"
    }, {
      "adcode": "632823",
      "name": "天峻县"
    }, {
      "adcode": "632825",
      "name": "大柴旦行政委员会"
    }]
  }]
}, {
  "adcode": "640000",
  "name": "宁夏回族自治区",
  "districts": [{
    "adcode": "640100",
    "name": "银川市",
    "districts": [{
      "adcode": "640104",
      "name": "兴庆区"
    }, {
      "adcode": "640105",
      "name": "西夏区"
    }, {
      "adcode": "640106",
      "name": "金凤区"
    }, {
      "adcode": "640121",
      "name": "永宁县"
    }, {
      "adcode": "640122",
      "name": "贺兰县"
    }, {
      "adcode": "640181",
      "name": "灵武市"
    }]
  }, {
    "adcode": "640200",
    "name": "石嘴山市",
    "districts": [{
      "adcode": "640202",
      "name": "大武口区"
    }, {
      "adcode": "640205",
      "name": "惠农区"
    }, {
      "adcode": "640221",
      "name": "平罗县"
    }]
  }, {
    "adcode": "640300",
    "name": "吴忠市",
    "districts": [{
      "adcode": "640302",
      "name": "利通区"
    }, {
      "adcode": "640303",
      "name": "红寺堡区"
    }, {
      "adcode": "640323",
      "name": "盐池县"
    }, {
      "adcode": "640324",
      "name": "同心县"
    }, {
      "adcode": "640381",
      "name": "青铜峡市"
    }]
  }, {
    "adcode": "640400",
    "name": "固原市",
    "districts": [{
      "adcode": "640402",
      "name": "原州区"
    }, {
      "adcode": "640422",
      "name": "西吉县"
    }, {
      "adcode": "640423",
      "name": "隆德县"
    }, {
      "adcode": "640424",
      "name": "泾源县"
    }, {
      "adcode": "640425",
      "name": "彭阳县"
    }]
  }, {
    "adcode": "640500",
    "name": "中卫市",
    "districts": [{
      "adcode": "640502",
      "name": "沙坡头区"
    }, {
      "adcode": "640521",
      "name": "中宁县"
    }, {
      "adcode": "640522",
      "name": "海原县"
    }]
  }]
}, {
  "adcode": "650000",
  "name": "新疆维吾尔自治区",
  "districts": [{
    "adcode": "650100",
    "name": "乌鲁木齐市",
    "districts": [{
      "adcode": "650102",
      "name": "天山区"
    }, {
      "adcode": "650103",
      "name": "沙依巴克区"
    }, {
      "adcode": "650104",
      "name": "新市区"
    }, {
      "adcode": "650105",
      "name": "水磨沟区"
    }, {
      "adcode": "650106",
      "name": "头屯河区"
    }, {
      "adcode": "650107",
      "name": "达坂城区"
    }, {
      "adcode": "650109",
      "name": "米东区"
    }, {
      "adcode": "650121",
      "name": "乌鲁木齐县"
    }]
  }, {
    "adcode": "650200",
    "name": "克拉玛依市",
    "districts": [{
      "adcode": "650202",
      "name": "独山子区"
    }, {
      "adcode": "650203",
      "name": "克拉玛依区"
    }, {
      "adcode": "650204",
      "name": "白碱滩区"
    }, {
      "adcode": "650205",
      "name": "乌尔禾区"
    }]
  }, {
    "adcode": "650400",
    "name": "吐鲁番市",
    "districts": [{
      "adcode": "650402",
      "name": "高昌区"
    }, {
      "adcode": "650421",
      "name": "鄯善县"
    }, {
      "adcode": "650422",
      "name": "托克逊县"
    }]
  }, {
    "adcode": "650500",
    "name": "哈密市",
    "districts": [{
      "adcode": "650502",
      "name": "伊州区"
    }, {
      "adcode": "650521",
      "name": "巴里坤哈萨克自治县"
    }, {
      "adcode": "650522",
      "name": "伊吾县"
    }]
  }, {
    "adcode": "652300",
    "name": "昌吉回族自治州",
    "districts": [{
      "adcode": "652301",
      "name": "昌吉市"
    }, {
      "adcode": "652302",
      "name": "阜康市"
    }, {
      "adcode": "652323",
      "name": "呼图壁县"
    }, {
      "adcode": "652324",
      "name": "玛纳斯县"
    }, {
      "adcode": "652325",
      "name": "奇台县"
    }, {
      "adcode": "652327",
      "name": "吉木萨尔县"
    }, {
      "adcode": "652328",
      "name": "木垒哈萨克自治县"
    }]
  }, {
    "adcode": "652700",
    "name": "博尔塔拉蒙古自治州",
    "districts": [{
      "adcode": "652701",
      "name": "博乐市"
    }, {
      "adcode": "652702",
      "name": "阿拉山口市"
    }, {
      "adcode": "652722",
      "name": "精河县"
    }, {
      "adcode": "652723",
      "name": "温泉县"
    }]
  }, {
    "adcode": "652800",
    "name": "巴音郭楞蒙古自治州",
    "districts": [{
      "adcode": "652801",
      "name": "库尔勒市"
    }, {
      "adcode": "652822",
      "name": "轮台县"
    }, {
      "adcode": "652823",
      "name": "尉犁县"
    }, {
      "adcode": "652824",
      "name": "若羌县"
    }, {
      "adcode": "652825",
      "name": "且末县"
    }, {
      "adcode": "652826",
      "name": "焉耆回族自治县"
    }, {
      "adcode": "652827",
      "name": "和静县"
    }, {
      "adcode": "652828",
      "name": "和硕县"
    }, {
      "adcode": "652829",
      "name": "博湖县"
    }]
  }, {
    "adcode": "652900",
    "name": "阿克苏地区",
    "districts": [{
      "adcode": "652901",
      "name": "阿克苏市"
    }, {
      "adcode": "652902",
      "name": "库车市"
    }, {
      "adcode": "652922",
      "name": "温宿县"
    }, {
      "adcode": "652924",
      "name": "沙雅县"
    }, {
      "adcode": "652925",
      "name": "新和县"
    }, {
      "adcode": "652926",
      "name": "拜城县"
    }, {
      "adcode": "652927",
      "name": "乌什县"
    }, {
      "adcode": "652928",
      "name": "阿瓦提县"
    }, {
      "adcode": "652929",
      "name": "柯坪县"
    }]
  }, {
    "adcode": "653000",
    "name": "克孜勒苏柯尔克孜自治州",
    "districts": [{
      "adcode": "653001",
      "name": "阿图什市"
    }, {
      "adcode": "653022",
      "name": "阿克陶县"
    }, {
      "adcode": "653023",
      "name": "阿合奇县"
    }, {
      "adcode": "653024",
      "name": "乌恰县"
    }]
  }, {
    "adcode": "653100",
    "name": "喀什地区",
    "districts": [{
      "adcode": "653101",
      "name": "喀什市"
    }, {
      "adcode": "653121",
      "name": "疏附县"
    }, {
      "adcode": "653122",
      "name": "疏勒县"
    }, {
      "adcode": "653123",
      "name": "英吉沙县"
    }, {
      "adcode": "653124",
      "name": "泽普县"
    }, {
      "adcode": "653125",
      "name": "莎车县"
    }, {
      "adcode": "653126",
      "name": "叶城县"
    }, {
      "adcode": "653127",
      "name": "麦盖提县"
    }, {
      "adcode": "653128",
      "name": "岳普湖县"
    }, {
      "adcode": "653129",
      "name": "伽师县"
    }, {
      "adcode": "653130",
      "name": "巴楚县"
    }, {
      "adcode": "653131",
      "name": "塔什库尔干塔吉克自治县"
    }]
  }, {
    "adcode": "653200",
    "name": "和田地区",
    "districts": [{
      "adcode": "653201",
      "name": "和田市"
    }, {
      "adcode": "653221",
      "name": "和田县"
    }, {
      "adcode": "653222",
      "name": "墨玉县"
    }, {
      "adcode": "653223",
      "name": "皮山县"
    }, {
      "adcode": "653224",
      "name": "洛浦县"
    }, {
      "adcode": "653225",
      "name": "策勒县"
    }, {
      "adcode": "653226",
      "name": "于田县"
    }, {
      "adcode": "653227",
      "name": "民丰县"
    }]
  }, {
    "adcode": "654000",
    "name": "伊犁哈萨克自治州",
    "districts": [{
      "adcode": "654002",
      "name": "伊宁市"
    }, {
      "adcode": "654003",
      "name": "奎屯市"
    }, {
      "adcode": "654004",
      "name": "霍尔果斯市"
    }, {
      "adcode": "654021",
      "name": "伊宁县"
    }, {
      "adcode": "654022",
      "name": "察布查尔锡伯自治县"
    }, {
      "adcode": "654023",
      "name": "霍城县"
    }, {
      "adcode": "654024",
      "name": "巩留县"
    }, {
      "adcode": "654025",
      "name": "新源县"
    }, {
      "adcode": "654026",
      "name": "昭苏县"
    }, {
      "adcode": "654027",
      "name": "特克斯县"
    }, {
      "adcode": "654028",
      "name": "尼勒克县"
    }]
  }, {
    "adcode": "654200",
    "name": "塔城地区",
    "districts": [{
      "adcode": "654201",
      "name": "塔城市"
    }, {
      "adcode": "654202",
      "name": "乌苏市"
    }, {
      "adcode": "654221",
      "name": "额敏县"
    }, {
      "adcode": "654223",
      "name": "沙湾县"
    }, {
      "adcode": "654224",
      "name": "托里县"
    }, {
      "adcode": "654225",
      "name": "裕民县"
    }, {
      "adcode": "654226",
      "name": "和布克赛尔蒙古自治县"
    }]
  }, {
    "adcode": "654300",
    "name": "阿勒泰地区",
    "districts": [{
      "adcode": "654301",
      "name": "阿勒泰市"
    }, {
      "adcode": "654321",
      "name": "布尔津县"
    }, {
      "adcode": "654322",
      "name": "富蕴县"
    }, {
      "adcode": "654323",
      "name": "福海县"
    }, {
      "adcode": "654324",
      "name": "哈巴河县"
    }, {
      "adcode": "654325",
      "name": "青河县"
    }, {
      "adcode": "654326",
      "name": "吉木乃县"
    }]
  }, {
    "adcode": "659000",
    "name": "自治区直辖县级行政区划",
    "districts": [{
      "adcode": "659001",
      "name": "石河子市"
    }, {
      "adcode": "659002",
      "name": "阿拉尔市"
    }, {
      "adcode": "659003",
      "name": "图木舒克市"
    }, {
      "adcode": "659004",
      "name": "五家渠市"
    }, {
      "adcode": "659005",
      "name": "北屯市"
    }, {
      "adcode": "659006",
      "name": "铁门关市"
    }, {
      "adcode": "659007",
      "name": "双河市"
    }, {
      "adcode": "659008",
      "name": "可克达拉市"
    }, {
      "adcode": "659009",
      "name": "昆玉市"
    }, {
      "adcode": "659010",
      "name": "胡杨河市"
    }]
  }]
}, {
  "adcode": "710000",
  "name": "台湾省",
  "districts": [{
    "adcode": "710100",
    "name": "台北市",
    "districts": [{
      "adcode": "710101",
      "name": "中正区"
    }, {
      "adcode": "710102",
      "name": "大同区"
    }, {
      "adcode": "710103",
      "name": "中山区"
    }, {
      "adcode": "710104",
      "name": "松山区"
    }, {
      "adcode": "710105",
      "name": "大安区"
    }, {
      "adcode": "710106",
      "name": "万华区"
    }, {
      "adcode": "710107",
      "name": "信义区"
    }, {
      "adcode": "710108",
      "name": "士林区"
    }, {
      "adcode": "710109",
      "name": "北投区"
    }, {
      "adcode": "710110",
      "name": "内湖区"
    }, {
      "adcode": "710111",
      "name": "南港区"
    }, {
      "adcode": "710112",
      "name": "文山区"
    }]
  }, {
    "adcode": "710200",
    "name": "高雄市",
    "districts": [{
      "adcode": "710201",
      "name": "新兴区"
    }, {
      "adcode": "710202",
      "name": "前金区"
    }, {
      "adcode": "710203",
      "name": "苓雅区"
    }, {
      "adcode": "710204",
      "name": "盐埕区"
    }, {
      "adcode": "710205",
      "name": "鼓山区"
    }, {
      "adcode": "710206",
      "name": "旗津区"
    }, {
      "adcode": "710207",
      "name": "前镇区"
    }, {
      "adcode": "710208",
      "name": "三民区"
    }, {
      "adcode": "710209",
      "name": "左营区"
    }, {
      "adcode": "710210",
      "name": "楠梓区"
    }, {
      "adcode": "710211",
      "name": "小港区"
    }, {
      "adcode": "710242",
      "name": "仁武区"
    }, {
      "adcode": "710243",
      "name": "大社区"
    }, {
      "adcode": "710244",
      "name": "冈山区"
    }, {
      "adcode": "710245",
      "name": "路竹区"
    }, {
      "adcode": "710246",
      "name": "阿莲区"
    }, {
      "adcode": "710247",
      "name": "田寮区"
    }, {
      "adcode": "710248",
      "name": "燕巢区"
    }, {
      "adcode": "710249",
      "name": "桥头区"
    }, {
      "adcode": "710250",
      "name": "梓官区"
    }, {
      "adcode": "710251",
      "name": "弥陀区"
    }, {
      "adcode": "710252",
      "name": "永安区"
    }, {
      "adcode": "710253",
      "name": "湖内区"
    }, {
      "adcode": "710254",
      "name": "凤山区"
    }, {
      "adcode": "710255",
      "name": "大寮区"
    }, {
      "adcode": "710256",
      "name": "林园区"
    }, {
      "adcode": "710257",
      "name": "鸟松区"
    }, {
      "adcode": "710258",
      "name": "大树区"
    }, {
      "adcode": "710259",
      "name": "旗山区"
    }, {
      "adcode": "710260",
      "name": "美浓区"
    }, {
      "adcode": "710261",
      "name": "六龟区"
    }, {
      "adcode": "710262",
      "name": "内门区"
    }, {
      "adcode": "710263",
      "name": "杉林区"
    }, {
      "adcode": "710264",
      "name": "甲仙区"
    }, {
      "adcode": "710265",
      "name": "桃源区"
    }, {
      "adcode": "710266",
      "name": "那玛夏区"
    }, {
      "adcode": "710267",
      "name": "茂林区"
    }, {
      "adcode": "710268",
      "name": "茄萣区"
    }]
  }, {
    "adcode": "710300",
    "name": "台南市",
    "districts": [{
      "adcode": "710301",
      "name": "中西区"
    }, {
      "adcode": "710302",
      "name": "东区"
    }, {
      "adcode": "710303",
      "name": "南区"
    }, {
      "adcode": "710304",
      "name": "北区"
    }, {
      "adcode": "710305",
      "name": "安平区"
    }, {
      "adcode": "710306",
      "name": "安南区"
    }, {
      "adcode": "710339",
      "name": "永康区"
    }, {
      "adcode": "710340",
      "name": "归仁区"
    }, {
      "adcode": "710341",
      "name": "新化区"
    }, {
      "adcode": "710342",
      "name": "左镇区"
    }, {
      "adcode": "710343",
      "name": "玉井区"
    }, {
      "adcode": "710344",
      "name": "楠西区"
    }, {
      "adcode": "710345",
      "name": "南化区"
    }, {
      "adcode": "710346",
      "name": "仁德区"
    }, {
      "adcode": "710347",
      "name": "关庙区"
    }, {
      "adcode": "710348",
      "name": "龙崎区"
    }, {
      "adcode": "710349",
      "name": "官田区"
    }, {
      "adcode": "710350",
      "name": "麻豆区"
    }, {
      "adcode": "710351",
      "name": "佳里区"
    }, {
      "adcode": "710352",
      "name": "西港区"
    }, {
      "adcode": "710353",
      "name": "七股区"
    }, {
      "adcode": "710354",
      "name": "将军区"
    }, {
      "adcode": "710355",
      "name": "学甲区"
    }, {
      "adcode": "710356",
      "name": "北门区"
    }, {
      "adcode": "710357",
      "name": "新营区"
    }, {
      "adcode": "710358",
      "name": "后壁区"
    }, {
      "adcode": "710359",
      "name": "白河区"
    }, {
      "adcode": "710360",
      "name": "东山区"
    }, {
      "adcode": "710361",
      "name": "六甲区"
    }, {
      "adcode": "710362",
      "name": "下营区"
    }, {
      "adcode": "710363",
      "name": "柳营区"
    }, {
      "adcode": "710364",
      "name": "盐水区"
    }, {
      "adcode": "710365",
      "name": "善化区"
    }, {
      "adcode": "710366",
      "name": "大内区"
    }, {
      "adcode": "710367",
      "name": "山上区"
    }, {
      "adcode": "710368",
      "name": "新市区"
    }, {
      "adcode": "710369",
      "name": "安定区"
    }]
  }, {
    "adcode": "710400",
    "name": "台中市",
    "districts": [{
      "adcode": "710401",
      "name": "中区"
    }, {
      "adcode": "710402",
      "name": "东区"
    }, {
      "adcode": "710403",
      "name": "南区"
    }, {
      "adcode": "710404",
      "name": "西区"
    }, {
      "adcode": "710405",
      "name": "北区"
    }, {
      "adcode": "710406",
      "name": "北屯区"
    }, {
      "adcode": "710407",
      "name": "西屯区"
    }, {
      "adcode": "710408",
      "name": "南屯区"
    }, {
      "adcode": "710431",
      "name": "太平区"
    }, {
      "adcode": "710432",
      "name": "大里区"
    }, {
      "adcode": "710433",
      "name": "雾峰区"
    }, {
      "adcode": "710434",
      "name": "乌日区"
    }, {
      "adcode": "710435",
      "name": "丰原区"
    }, {
      "adcode": "710436",
      "name": "后里区"
    }, {
      "adcode": "710437",
      "name": "石冈区"
    }, {
      "adcode": "710438",
      "name": "东势区"
    }, {
      "adcode": "710439",
      "name": "和平区"
    }, {
      "adcode": "710440",
      "name": "新社区"
    }, {
      "adcode": "710441",
      "name": "潭子区"
    }, {
      "adcode": "710442",
      "name": "大雅区"
    }, {
      "adcode": "710443",
      "name": "神冈区"
    }, {
      "adcode": "710444",
      "name": "大肚区"
    }, {
      "adcode": "710445",
      "name": "沙鹿区"
    }, {
      "adcode": "710446",
      "name": "龙井区"
    }, {
      "adcode": "710447",
      "name": "梧栖区"
    }, {
      "adcode": "710448",
      "name": "清水区"
    }, {
      "adcode": "710449",
      "name": "大甲区"
    }, {
      "adcode": "710450",
      "name": "外埔区"
    }, {
      "adcode": "710451",
      "name": "大安区"
    }]
  }, {
    "adcode": "710600",
    "name": "南投县",
    "districts": [{
      "adcode": "710614",
      "name": "南投市"
    }, {
      "adcode": "710615",
      "name": "中寮乡"
    }, {
      "adcode": "710616",
      "name": "草屯镇"
    }, {
      "adcode": "710617",
      "name": "国姓乡"
    }, {
      "adcode": "710618",
      "name": "埔里镇"
    }, {
      "adcode": "710619",
      "name": "仁爱乡"
    }, {
      "adcode": "710620",
      "name": "名间乡"
    }, {
      "adcode": "710621",
      "name": "集集镇"
    }, {
      "adcode": "710622",
      "name": "水里乡"
    }, {
      "adcode": "710623",
      "name": "鱼池乡"
    }, {
      "adcode": "710624",
      "name": "信义乡"
    }, {
      "adcode": "710625",
      "name": "竹山镇"
    }, {
      "adcode": "710626",
      "name": "鹿谷乡"
    }]
  }, {
    "adcode": "710700",
    "name": "基隆市",
    "districts": [{
      "adcode": "710701",
      "name": "仁爱区"
    }, {
      "adcode": "710702",
      "name": "信义区"
    }, {
      "adcode": "710703",
      "name": "中正区"
    }, {
      "adcode": "710704",
      "name": "中山区"
    }, {
      "adcode": "710705",
      "name": "安乐区"
    }, {
      "adcode": "710706",
      "name": "暖暖区"
    }, {
      "adcode": "710707",
      "name": "七堵区"
    }]
  }, {
    "adcode": "710800",
    "name": "新竹市",
    "districts": [{
      "adcode": "710801",
      "name": "东区"
    }, {
      "adcode": "710802",
      "name": "北区"
    }, {
      "adcode": "710803",
      "name": "香山区"
    }]
  }, {
    "adcode": "710900",
    "name": "嘉义市",
    "districts": [{
      "adcode": "710901",
      "name": "东区"
    }, {
      "adcode": "710902",
      "name": "西区"
    }]
  }, {
    "adcode": "711100",
    "name": "新北市",
    "districts": [{
      "adcode": "711130",
      "name": "万里区"
    }, {
      "adcode": "711131",
      "name": "金山区"
    }, {
      "adcode": "711132",
      "name": "板桥区"
    }, {
      "adcode": "711133",
      "name": "汐止区"
    }, {
      "adcode": "711134",
      "name": "深坑区"
    }, {
      "adcode": "711135",
      "name": "石碇区"
    }, {
      "adcode": "711136",
      "name": "瑞芳区"
    }, {
      "adcode": "711137",
      "name": "平溪区"
    }, {
      "adcode": "711138",
      "name": "双溪区"
    }, {
      "adcode": "711139",
      "name": "贡寮区"
    }, {
      "adcode": "711140",
      "name": "新店区"
    }, {
      "adcode": "711141",
      "name": "坪林区"
    }, {
      "adcode": "711142",
      "name": "乌来区"
    }, {
      "adcode": "711143",
      "name": "永和区"
    }, {
      "adcode": "711144",
      "name": "中和区"
    }, {
      "adcode": "711145",
      "name": "土城区"
    }, {
      "adcode": "711146",
      "name": "三峡区"
    }, {
      "adcode": "711147",
      "name": "树林区"
    }, {
      "adcode": "711148",
      "name": "莺歌区"
    }, {
      "adcode": "711149",
      "name": "三重区"
    }, {
      "adcode": "711150",
      "name": "新庄区"
    }, {
      "adcode": "711151",
      "name": "泰山区"
    }, {
      "adcode": "711152",
      "name": "林口区"
    }, {
      "adcode": "711153",
      "name": "芦洲区"
    }, {
      "adcode": "711154",
      "name": "五股区"
    }, {
      "adcode": "711155",
      "name": "八里区"
    }, {
      "adcode": "711156",
      "name": "淡水区"
    }, {
      "adcode": "711157",
      "name": "三芝区"
    }, {
      "adcode": "711158",
      "name": "石门区"
    }]
  }, {
    "adcode": "711200",
    "name": "宜兰县",
    "districts": [{
      "adcode": "711214",
      "name": "宜兰市"
    }, {
      "adcode": "711215",
      "name": "头城镇"
    }, {
      "adcode": "711216",
      "name": "礁溪乡"
    }, {
      "adcode": "711217",
      "name": "壮围乡"
    }, {
      "adcode": "711218",
      "name": "员山乡"
    }, {
      "adcode": "711219",
      "name": "罗东镇"
    }, {
      "adcode": "711220",
      "name": "三星乡"
    }, {
      "adcode": "711221",
      "name": "大同乡"
    }, {
      "adcode": "711222",
      "name": "五结乡"
    }, {
      "adcode": "711223",
      "name": "冬山乡"
    }, {
      "adcode": "711224",
      "name": "苏澳镇"
    }, {
      "adcode": "711225",
      "name": "南澳乡"
    }]
  }, {
    "adcode": "711300",
    "name": "新竹县",
    "districts": [{
      "adcode": "711314",
      "name": "竹北市"
    }, {
      "adcode": "711315",
      "name": "湖口乡"
    }, {
      "adcode": "711316",
      "name": "新丰乡"
    }, {
      "adcode": "711317",
      "name": "新埔镇"
    }, {
      "adcode": "711318",
      "name": "关西镇"
    }, {
      "adcode": "711319",
      "name": "芎林乡"
    }, {
      "adcode": "711320",
      "name": "宝山乡"
    }, {
      "adcode": "711321",
      "name": "竹东镇"
    }, {
      "adcode": "711322",
      "name": "五峰乡"
    }, {
      "adcode": "711323",
      "name": "横山乡"
    }, {
      "adcode": "711324",
      "name": "尖石乡"
    }, {
      "adcode": "711325",
      "name": "北埔乡"
    }, {
      "adcode": "711326",
      "name": "峨眉乡"
    }]
  }, {
    "adcode": "711400",
    "name": "桃园市",
    "districts": [{
      "adcode": "711414",
      "name": "中坜区"
    }, {
      "adcode": "711415",
      "name": "平镇区"
    }, {
      "adcode": "711416",
      "name": "龙潭区"
    }, {
      "adcode": "711417",
      "name": "杨梅区"
    }, {
      "adcode": "711418",
      "name": "新屋区"
    }, {
      "adcode": "711419",
      "name": "观音区"
    }, {
      "adcode": "711420",
      "name": "桃园区"
    }, {
      "adcode": "711421",
      "name": "龟山区"
    }, {
      "adcode": "711422",
      "name": "八德区"
    }, {
      "adcode": "711423",
      "name": "大溪区"
    }, {
      "adcode": "711424",
      "name": "复兴区"
    }, {
      "adcode": "711425",
      "name": "大园区"
    }, {
      "adcode": "711426",
      "name": "芦竹区"
    }]
  }, {
    "adcode": "711500",
    "name": "苗栗县",
    "districts": [{
      "adcode": "711519",
      "name": "竹南镇"
    }, {
      "adcode": "711520",
      "name": "头份市"
    }, {
      "adcode": "711521",
      "name": "三湾乡"
    }, {
      "adcode": "711522",
      "name": "南庄乡"
    }, {
      "adcode": "711523",
      "name": "狮潭乡"
    }, {
      "adcode": "711524",
      "name": "后龙镇"
    }, {
      "adcode": "711525",
      "name": "通霄镇"
    }, {
      "adcode": "711526",
      "name": "苑里镇"
    }, {
      "adcode": "711527",
      "name": "苗栗市"
    }, {
      "adcode": "711528",
      "name": "造桥乡"
    }, {
      "adcode": "711529",
      "name": "头屋乡"
    }, {
      "adcode": "711530",
      "name": "公馆乡"
    }, {
      "adcode": "711531",
      "name": "大湖乡"
    }, {
      "adcode": "711532",
      "name": "泰安乡"
    }, {
      "adcode": "711533",
      "name": "铜锣乡"
    }, {
      "adcode": "711534",
      "name": "三义乡"
    }, {
      "adcode": "711535",
      "name": "西湖乡"
    }, {
      "adcode": "711536",
      "name": "卓兰镇"
    }]
  }, {
    "adcode": "711700",
    "name": "彰化县",
    "districts": [{
      "adcode": "711727",
      "name": "彰化市"
    }, {
      "adcode": "711728",
      "name": "芬园乡"
    }, {
      "adcode": "711729",
      "name": "花坛乡"
    }, {
      "adcode": "711730",
      "name": "秀水乡"
    }, {
      "adcode": "711731",
      "name": "鹿港镇"
    }, {
      "adcode": "711732",
      "name": "福兴乡"
    }, {
      "adcode": "711733",
      "name": "线西乡"
    }, {
      "adcode": "711734",
      "name": "和美镇"
    }, {
      "adcode": "711735",
      "name": "伸港乡"
    }, {
      "adcode": "711736",
      "name": "员林市"
    }, {
      "adcode": "711737",
      "name": "社头乡"
    }, {
      "adcode": "711738",
      "name": "永靖乡"
    }, {
      "adcode": "711739",
      "name": "埔心乡"
    }, {
      "adcode": "711740",
      "name": "溪湖镇"
    }, {
      "adcode": "711741",
      "name": "大村乡"
    }, {
      "adcode": "711742",
      "name": "埔盐乡"
    }, {
      "adcode": "711743",
      "name": "田中镇"
    }, {
      "adcode": "711744",
      "name": "北斗镇"
    }, {
      "adcode": "711745",
      "name": "田尾乡"
    }, {
      "adcode": "711746",
      "name": "埤头乡"
    }, {
      "adcode": "711747",
      "name": "溪州乡"
    }, {
      "adcode": "711748",
      "name": "竹塘乡"
    }, {
      "adcode": "711749",
      "name": "二林镇"
    }, {
      "adcode": "711750",
      "name": "大城乡"
    }, {
      "adcode": "711751",
      "name": "芳苑乡"
    }, {
      "adcode": "711752",
      "name": "二水乡"
    }]
  }, {
    "adcode": "711900",
    "name": "嘉义县",
    "districts": [{
      "adcode": "711919",
      "name": "番路乡"
    }, {
      "adcode": "711920",
      "name": "梅山乡"
    }, {
      "adcode": "711921",
      "name": "竹崎乡"
    }, {
      "adcode": "711922",
      "name": "阿里山乡"
    }, {
      "adcode": "711923",
      "name": "中埔乡"
    }, {
      "adcode": "711924",
      "name": "大埔乡"
    }, {
      "adcode": "711925",
      "name": "水上乡"
    }, {
      "adcode": "711926",
      "name": "鹿草乡"
    }, {
      "adcode": "711927",
      "name": "太保市"
    }, {
      "adcode": "711928",
      "name": "朴子市"
    }, {
      "adcode": "711929",
      "name": "东石乡"
    }, {
      "adcode": "711930",
      "name": "六脚乡"
    }, {
      "adcode": "711931",
      "name": "新港乡"
    }, {
      "adcode": "711932",
      "name": "民雄乡"
    }, {
      "adcode": "711933",
      "name": "大林镇"
    }, {
      "adcode": "711934",
      "name": "溪口乡"
    }, {
      "adcode": "711935",
      "name": "义竹乡"
    }, {
      "adcode": "711936",
      "name": "布袋镇"
    }]
  }, {
    "adcode": "712100",
    "name": "云林县",
    "districts": [{
      "adcode": "712121",
      "name": "斗南镇"
    }, {
      "adcode": "712122",
      "name": "大埤乡"
    }, {
      "adcode": "712123",
      "name": "虎尾镇"
    }, {
      "adcode": "712124",
      "name": "土库镇"
    }, {
      "adcode": "712125",
      "name": "褒忠乡"
    }, {
      "adcode": "712126",
      "name": "东势乡"
    }, {
      "adcode": "712127",
      "name": "台西乡"
    }, {
      "adcode": "712128",
      "name": "仑背乡"
    }, {
      "adcode": "712129",
      "name": "麦寮乡"
    }, {
      "adcode": "712130",
      "name": "斗六市"
    }, {
      "adcode": "712131",
      "name": "林内乡"
    }, {
      "adcode": "712132",
      "name": "古坑乡"
    }, {
      "adcode": "712133",
      "name": "莿桐乡"
    }, {
      "adcode": "712134",
      "name": "西螺镇"
    }, {
      "adcode": "712135",
      "name": "二仑乡"
    }, {
      "adcode": "712136",
      "name": "北港镇"
    }, {
      "adcode": "712137",
      "name": "水林乡"
    }, {
      "adcode": "712138",
      "name": "口湖乡"
    }, {
      "adcode": "712139",
      "name": "四湖乡"
    }, {
      "adcode": "712140",
      "name": "元长乡"
    }]
  }, {
    "adcode": "712400",
    "name": "屏东县",
    "districts": [{
      "adcode": "712434",
      "name": "屏东市"
    }, {
      "adcode": "712435",
      "name": "三地门乡"
    }, {
      "adcode": "712436",
      "name": "雾台乡"
    }, {
      "adcode": "712437",
      "name": "玛家乡"
    }, {
      "adcode": "712438",
      "name": "九如乡"
    }, {
      "adcode": "712439",
      "name": "里港乡"
    }, {
      "adcode": "712440",
      "name": "高树乡"
    }, {
      "adcode": "712441",
      "name": "盐埔乡"
    }, {
      "adcode": "712442",
      "name": "长治乡"
    }, {
      "adcode": "712443",
      "name": "麟洛乡"
    }, {
      "adcode": "712444",
      "name": "竹田乡"
    }, {
      "adcode": "712445",
      "name": "内埔乡"
    }, {
      "adcode": "712446",
      "name": "万丹乡"
    }, {
      "adcode": "712447",
      "name": "潮州镇"
    }, {
      "adcode": "712448",
      "name": "泰武乡"
    }, {
      "adcode": "712449",
      "name": "来义乡"
    }, {
      "adcode": "712450",
      "name": "万峦乡"
    }, {
      "adcode": "712451",
      "name": "崁顶乡"
    }, {
      "adcode": "712452",
      "name": "新埤乡"
    }, {
      "adcode": "712453",
      "name": "南州乡"
    }, {
      "adcode": "712454",
      "name": "林边乡"
    }, {
      "adcode": "712455",
      "name": "东港镇"
    }, {
      "adcode": "712456",
      "name": "琉球乡"
    }, {
      "adcode": "712457",
      "name": "佳冬乡"
    }, {
      "adcode": "712458",
      "name": "新园乡"
    }, {
      "adcode": "712459",
      "name": "枋寮乡"
    }, {
      "adcode": "712460",
      "name": "枋山乡"
    }, {
      "adcode": "712461",
      "name": "春日乡"
    }, {
      "adcode": "712462",
      "name": "狮子乡"
    }, {
      "adcode": "712463",
      "name": "车城乡"
    }, {
      "adcode": "712464",
      "name": "牡丹乡"
    }, {
      "adcode": "712465",
      "name": "恒春镇"
    }, {
      "adcode": "712466",
      "name": "满州乡"
    }]
  }, {
    "adcode": "712500",
    "name": "台东县",
    "districts": [{
      "adcode": "712517",
      "name": "台东市"
    }, {
      "adcode": "712518",
      "name": "绿岛乡"
    }, {
      "adcode": "712519",
      "name": "兰屿乡"
    }, {
      "adcode": "712520",
      "name": "延平乡"
    }, {
      "adcode": "712521",
      "name": "卑南乡"
    }, {
      "adcode": "712522",
      "name": "鹿野乡"
    }, {
      "adcode": "712523",
      "name": "关山镇"
    }, {
      "adcode": "712524",
      "name": "海端乡"
    }, {
      "adcode": "712525",
      "name": "池上乡"
    }, {
      "adcode": "712526",
      "name": "东河乡"
    }, {
      "adcode": "712527",
      "name": "成功镇"
    }, {
      "adcode": "712528",
      "name": "长滨乡"
    }, {
      "adcode": "712529",
      "name": "金峰乡"
    }, {
      "adcode": "712530",
      "name": "大武乡"
    }, {
      "adcode": "712531",
      "name": "达仁乡"
    }, {
      "adcode": "712532",
      "name": "太麻里乡"
    }]
  }, {
    "adcode": "712600",
    "name": "花莲县",
    "districts": [{
      "adcode": "712615",
      "name": "花莲市"
    }, {
      "adcode": "712616",
      "name": "新城乡"
    }, {
      "adcode": "712618",
      "name": "秀林乡"
    }, {
      "adcode": "712619",
      "name": "吉安乡"
    }, {
      "adcode": "712620",
      "name": "寿丰乡"
    }, {
      "adcode": "712621",
      "name": "凤林镇"
    }, {
      "adcode": "712622",
      "name": "光复乡"
    }, {
      "adcode": "712623",
      "name": "丰滨乡"
    }, {
      "adcode": "712624",
      "name": "瑞穗乡"
    }, {
      "adcode": "712625",
      "name": "万荣乡"
    }, {
      "adcode": "712626",
      "name": "玉里镇"
    }, {
      "adcode": "712627",
      "name": "卓溪乡"
    }, {
      "adcode": "712628",
      "name": "富里乡"
    }]
  }, {
    "adcode": "712700",
    "name": "澎湖县",
    "districts": [{
      "adcode": "712707",
      "name": "马公市"
    }, {
      "adcode": "712708",
      "name": "西屿乡"
    }, {
      "adcode": "712709",
      "name": "望安乡"
    }, {
      "adcode": "712710",
      "name": "七美乡"
    }, {
      "adcode": "712711",
      "name": "白沙乡"
    }, {
      "adcode": "712712",
      "name": "湖西乡"
    }]
  }]
}, {
  "adcode": "810000",
  "name": "香港特别行政区",
  "districts": [{
    "adcode": "810100",
    "name": "香港特别行政区",
    "districts": [{
      "adcode": "810101",
      "name": "中西区"
    }, {
      "adcode": "810102",
      "name": "东区"
    }, {
      "adcode": "810103",
      "name": "九龙城区"
    }, {
      "adcode": "810104",
      "name": "观塘区"
    }, {
      "adcode": "810105",
      "name": "南区"
    }, {
      "adcode": "810106",
      "name": "深水埗区"
    }, {
      "adcode": "810107",
      "name": "湾仔区"
    }, {
      "adcode": "810108",
      "name": "黄大仙区"
    }, {
      "adcode": "810109",
      "name": "油尖旺区"
    }, {
      "adcode": "810110",
      "name": "离岛区"
    }, {
      "adcode": "810111",
      "name": "葵青区"
    }, {
      "adcode": "810112",
      "name": "北区"
    }, {
      "adcode": "810113",
      "name": "西贡区"
    }, {
      "adcode": "810114",
      "name": "沙田区"
    }, {
      "adcode": "810115",
      "name": "屯门区"
    }, {
      "adcode": "810116",
      "name": "大埔区"
    }, {
      "adcode": "810117",
      "name": "荃湾区"
    }, {
      "adcode": "810118",
      "name": "元朗区"
    }]
  }]
}, {
  "adcode": "820000",
  "name": "澳门特别行政区",
  "districts": [{
    "adcode": "820100",
    "name": "澳门特别行政区",
    "districts": [{
      "adcode": "820101",
      "name": "澳门半岛"
    }, {
      "adcode": "820102",
      "name": "凼仔"
    }, {
      "adcode": "820103",
      "name": "路凼城"
    }, {
      "adcode": "820104",
      "name": "路环"
    }]
  }]
}
]